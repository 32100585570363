import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
/* eslint-disable no-param-reassign */

export interface settingsState {
  gettingAllNodes: boolean;
  allNodes: any;
  gettingSingleNode: boolean;
  singleNode: any;
  switchingNode: boolean;
  allCommonErrors: any;
  gettingCommonErrors: boolean;
  disabling2FA: boolean;

  // node
  selectedNode: any;
}

const initialState: settingsState = {
  gettingAllNodes: false,
  allNodes: null,
  gettingSingleNode: false,
  singleNode: null,
  switchingNode: false,
  allCommonErrors: null,
  gettingCommonErrors: false,
  selectedNode: null,
  disabling2FA: false,
};

export const settingsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setGettingAllNodes: (state, action) => {
      state.gettingAllNodes = action.payload;
    },
    setDisabling: (state, action) => {
      state.disabling2FA = action.payload;
    },
    setAllNodes: (state, action) => {
      state.allNodes = action.payload;
    },
    setGettingAllCommonErrors: (state, action) => {
      state.gettingCommonErrors = action.payload;
    },
    setAllCommonErrors: (state, action) => {
      state.allCommonErrors = action.payload;
    },
    setGettingSingleNode: (state, action) => {
      state.gettingSingleNode = action.payload;
    },
    setSingleNode: (state, action) => {
      state.singleNode = action.payload;
    },
    setSwitchingNode: (state, action) => {
      state.switchingNode = action.payload;
    },
    setSelectedNodeDetails: (state, action) => {
      state.selectedNode = action.payload;
    },
  },
});

export const {
  setGettingAllNodes,
  setAllNodes,
  setGettingSingleNode,
  setSingleNode,
  setSwitchingNode,
  setGettingAllCommonErrors,
  setAllCommonErrors,
  setSelectedNodeDetails,
  setDisabling,
} = settingsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectReports = (state: RootState) => state.settings;

export default settingsSlice.reducer;
