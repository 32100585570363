import { removeDuplicates } from "../../Helpers/generals";
import {
  setDepositCurrencyBalance,
  setDepositNetworkBalance,
  setGettingRemittanceTransactions,
  setGettingUnRemittanceTransactions,
  setGettingWalletsTransactions,
  setRemittnceTransactions,
  setUnRemittnceTransactions,
  setWalletsTransactions,
} from "../../Redux/Reducers/WalletReducer";
import {
  setGettingWalletsGenerated,
  setWalletsGenerated,
} from "../../Redux/Reducers/analyticsReducer";
import { filter } from "../../Redux/Reducers/formReducer";
import { store } from "../../Redux/app/store";
import api from "../baseURL";

export const getWalletsGenerated = async (
  parameters?: any
) => {
  const params = parameters;
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  store.dispatch(setGettingWalletsGenerated(true));
  await api
    .get("v2/analytics/wallets-generation-time-series-metrics", {
      params,
    })
    .then((res: any) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setWalletsGenerated(data));
        store.dispatch(setGettingWalletsGenerated(false));
      }
    });
};

export const getWalletsTransactions = async (
  parameters: any
) => {
  const params = parameters;
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  store.dispatch(setGettingWalletsTransactions(true));
  await api
    .get("v2/analytics/transaction-record", {
      params,
    })
    .then((res: any) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setWalletsTransactions(data));
        store.dispatch(setGettingWalletsTransactions(false));
        store.dispatch(filter(false));
        // store.dispatch(clearForm());
      }
    })
    .catch(() => {
      store.dispatch(setGettingWalletsTransactions(false));
      store.dispatch(filter(false));
      // store.dispatch(clearForm());
    });
};
export const getUnRemittanceTransactions = async (
  parameters: any
) => {
  const params = parameters;
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  store.dispatch(setGettingUnRemittanceTransactions(true));
  await api
    .get("v2/Admin/merchant-unremitted-balances", {
      params,
    })
    .then((res: any) => {
      const { data } = res;
      if (data) {
        store.dispatch(setUnRemittnceTransactions(data?.unremittedBalances));
        store.dispatch(setGettingUnRemittanceTransactions(false));
        store.dispatch(filter(false));

        // store.dispatch(clearForm());
      }
    })
    .catch(() => {
      store.dispatch(setGettingUnRemittanceTransactions(false));
      store.dispatch(filter(false));
      // store.dispatch(clearForm());
    });
};
export const getRemittanceTransactions = async (
  parameters: any
) => {
  const params = parameters;
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  store.dispatch(setGettingRemittanceTransactions(true));
  await api
    .get("v2/Admin/merchant-remittance-record", {
      params,
    })
    .then((res: any) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setRemittnceTransactions(data));
        store.dispatch(setGettingRemittanceTransactions(false));
        store.dispatch(filter(false));

        // store.dispatch(clearForm());
      }
    })
    .catch(() => {
      store.dispatch(setGettingRemittanceTransactions(false));
      store.dispatch(filter(false));
      // store.dispatch(clearForm());
    });
};

export const getDepositCurrencyBalance = async (
  parameters?: any
) => {
  const params = parameters;
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`v2/analytics/deposit-time-series-metrics`, {
      params,
    })
    .then((res: any) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setDepositCurrencyBalance(data));
      }
      store.dispatch(filter(false));
    })
    .catch(() => {
      store.dispatch(filter(false));
    });
};
export const getDepositNetworkBalance = async (
  parameters?: any
) => {
  const params = parameters;
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`v2/analytics/deposit-time-series-metrics`, {
      params,
    })
    .then((res: any) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setDepositNetworkBalance(data));
      }
      store.dispatch(filter(false));
    })
    .catch(() => {
      store.dispatch(filter(false));
    });
};

export const getSupportedCurrencies = async (
  setState: any,
  noInitialState?: boolean,
  value?: string
) => {
  await api.get(`v1/merchant/GetActiveCurrencies`).then((res: any) => {
    const result = res?.data?.data;
    const filteredResult = result?.map((item: any) => {
      return {
        label: item?.name,
        value: value === "id" ? item?.id : item?.symbol,
      };
    });

    const removedDuplicateResult = removeDuplicates(filteredResult, "label");
    noInitialState
      ? setState(removedDuplicateResult)
      : setState([{ label: "--", value: "--" }].concat(removedDuplicateResult));
  });
};

export const getSupportedNetworks = async (
  setState: any,
  noInitialState?: boolean,
  setGettingNetworkList?: any
) => {
  setGettingNetworkList && setGettingNetworkList(true);
  await api
    .get(`v1/merchant/SupportedNetworks`, {})
    .then((res: any) => {
      setGettingNetworkList && setGettingNetworkList(false);
      const result = res?.data?.data;
      const filteredResult = result?.map((item: any) => {
        return {
          label: item.name,
          value: item.symbol || item.networkChain,
          vmType: item.vmType,
        };
      });
      noInitialState
        ? setState(filteredResult)
        : setState([{ label: "--", value: "--" }].concat(filteredResult));
    })
    .catch(() => {
      setGettingNetworkList && setGettingNetworkList(false);
    });
};

export const addnetworkChain = async (
  publickey: string | null | undefined,
  networkChain: number | null | undefined
) => {
  const body = { publickey, networkChain };
  await api.post(`v2/merchant/add-network-chain`, body).then((res) => {
    return res;
  });
};
export const checkNetworkChainSupport = async (
  nvmType: number | null | undefined,
  publickey: string | null | undefined,
  networkChain: number | null | undefined
) => {
  const params = { nvmType };
  await api
    .get(`v2/merchant/check-network-chain-support`, { params })
    .then((res) => {
      if (!res?.data?.data.isSupported) {
        addnetworkChain(publickey, networkChain);
      }
    });
};
