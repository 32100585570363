import {
  setActiveMerchants,
  setAllMerchants,
  setGettingActiveMerchants,
  setGettingAllMerchants,
  setGettingInActiveMerchants,
  setGettingTotalRecords,
  setInActiveMerchants,
  setTotalRecords,
} from "../../Redux/Reducers/overviewReducer";
import { store } from "../../Redux/app/store";
import api from "../baseURL";

export const getTotalRecords = async (
  merchantId?: string,
  StartTimeStamp?: number,
  EndTimeStamp?: number
) => {
  const params: any = {
    StartTimeStamp,
    EndTimeStamp,
    merchantId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });

  store.dispatch(setGettingTotalRecords(true));
  let result = null;
  await api
    .get("v2/Admin/records", { params })
    .then((res) => {
      store.dispatch(setGettingTotalRecords(false));
      const { data } = res.data;
      if (data) {
        store.dispatch(setTotalRecords(data));
      }
      if (merchantId) result = data;
    })
    .catch(() => store.dispatch(setGettingTotalRecords(false)));
  return result;
};

export const getActiveMerchants = async () => {
  store.dispatch(setGettingActiveMerchants(true));
  await api
    .get("v2/Admin/activemerchants")
    .then((res) => {
      store.dispatch(setGettingActiveMerchants(false));
      const { data } = res.data;
      if (data) {
        store.dispatch(setActiveMerchants(data));
      }
    })
    .catch(() => store.dispatch(setGettingActiveMerchants(false)));
};

export const getInActiveMerchants = async () => {
  store.dispatch(setGettingInActiveMerchants(true));
  await api
    .get("v2/Admin/inactivemerchants")
    .then((res) => {
      store.dispatch(setGettingInActiveMerchants(false));
      const { data } = res.data;
      if (data) {
        store.dispatch(setInActiveMerchants(data));
      }
    })
    .catch(() => store.dispatch(setGettingInActiveMerchants(false)));
};

export const getAllMerchants = async (
  Page?: any,
  PageSize?: any,
  status?: string
) => {
  const params: any = {
    Page,
    PageSize,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  store.dispatch(setGettingAllMerchants(true));
  await api
    .get(`v2/Admin/${status}`, { params })
    .then((res) => {
      store.dispatch(setGettingAllMerchants(false));
      const { data } = res.data;
      if (data) {
        store.dispatch(setAllMerchants(data));
      }
    })
    .catch(() => store.dispatch(setGettingAllMerchants(false)));
};

export const getOverallMerchants = async (PageSize?: any, setState?: any) => {
  const params: any = {
    PageSize,
  };
  await api.get(`v2/Admin/allmerchants`, { params }).then((res) => {
    const { data } = res.data;
    if (data) {
      setState(data);
      return data;
    }
  });
};

