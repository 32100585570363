import React, { useEffect } from "react";
import { RouteContainer } from "../../../components/Generals/RouteStyles";
import { useAppSelector } from "../../../Redux/app/hooks";
import Deposits from "./Merchants/Deposits";
import Remittances from "./Merchants/Remittances";
import Unremitted from "./Merchants/Unremitted";
import Withdrawals from "./Merchants/Withdrawals";
import { useNavigate } from "react-router-dom";
import { LoggedInCheck2FAEnabled } from "../../../Apis/accountProcesing";
import ResacnBlock from "./Merchants/ResacnBlock";

const index = () => {
  const { currentSubnav } = useAppSelector((state) => state.nav);

  const currentOperator = JSON.parse(
    localStorage.getItem("currentOperator") || "{}"
  );
  const navigate = useNavigate();

  useEffect(() => {
    LoggedInCheck2FAEnabled(currentOperator?.email, navigate);
  }, []);

  return (
    <RouteContainer>
      {currentSubnav === 1 ? (
        <Deposits />
      ) : currentSubnav === 2 ? (
        <Withdrawals />
      ) : currentSubnav === 3 ? (
        <Remittances />
      ) : currentSubnav === 4 ? (
        <Unremitted />
      ) : (
        <ResacnBlock />
      )}
    </RouteContainer>
  );
};

export default index;
