import React from "react";
import {
  FormContainer,
  Input,
  InputContainer,
  Label,
  SelectBar,
  colourStyles,
} from "../../../../components/Generals/LoginStyles";
import Filledbutton from "../../../../components/Reusables/Buttons/Filledbutton";
import { validateemail } from "../../../../Helpers/generals";
import { useAppSelector } from "../../../../Redux/app/hooks";
import {
  errorMessage,
  getFormDetails,
} from "../../../../Redux/Reducers/formReducer";
import { Form } from "../../../../components/Generals/LoginStyles";
import { useAppDispatch } from "../../../../Redux/app/hooks";
import { validatePassword } from "../../../../Helpers/generals";
import { createAdmin } from "../../../../Apis/PrivatesApis/ThirdParty";
import { adminRoles } from "../../../../utils/filterSelect";

const CreateAdmin = () => {
  const {
    adminFirstName,
    adminLastName,
    adminEmailAddress,
    adminPassword,
    adminConfirmPassword,
    adminBusinessName,
    adminRole,
    error,
    loading,
  } = useAppSelector((state) => state.form);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleCreatingMerchant = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (
      !adminEmailAddress ||
      !adminFirstName ||
      !adminLastName ||
      !adminPassword ||
      !adminConfirmPassword
    ) {
      dispatch(errorMessage("Please fill all fields"));
    } else if (adminPassword !== adminConfirmPassword) {
      dispatch(errorMessage("passwords doesn't match"));
    } else if (!validatePassword(adminPassword)) {
      dispatch(
        errorMessage(
          "* min 8 letter password, with at least a symbol, upper and lower case letters and a number"
        )
      );
    } else if (!validateemail(adminEmailAddress)) {
      dispatch(
        errorMessage(
          "Please fill in a valid email address (e.g: example@example.com)"
        )
      );
    } else {
      createAdmin(
        adminFirstName,
        adminLastName,
        adminEmailAddress,
        adminPassword,
        adminConfirmPassword,
        adminRole
      );
    }
  };

  const handleSelectChange = (option: any, name: string) => {
    if (option.value === "--") dispatch(getFormDetails({ name }));
    else {
      dispatch(getFormDetails({ name, value: option.value }));
      // setCurrentStatus({ label: option?.label, value: option?.value });
    }
  };

  return (
    <FormContainer>
      <Form customWidth="80%" style={{ flexWrap: "wrap" }}>
        <InputContainer>
          <Label>Email Address</Label>
          <Input
            placeholder="example@gmail.com"
            type="email"
            value={adminEmailAddress}
            name="adminEmailAddress"
            onChange={handleChange}
          />
        </InputContainer>
        {/* <InputContainer>
          <Label>Business Name</Label>
          <Input
            placeholder="Business Name"
            value={adminBusinessName}
            name="adminBusinessName"
            onChange={handleChange}
          />
        </InputContainer> */}
        <InputContainer>
          <Label>First Name</Label>
          <Input
            placeholder="First Name"
            value={adminFirstName}
            name="adminFirstName"
            onChange={handleChange}
          />
        </InputContainer>
        <InputContainer>
          <Label>Last Name</Label>
          <Input
            placeholder="Last Name"
            value={adminLastName}
            name="adminLastName"
            onChange={handleChange}
          />
        </InputContainer>
        <InputContainer>
          <Label>Role</Label>
          <SelectBar
            // defaultValue={currentStatus}
            onChange={(data: any) => handleSelectChange(data, "adminRole")}
            options={adminRoles}
            styles={colourStyles}
            placeholder="Role"
            data-testid="merchant-filter"
          />
        </InputContainer>
        <InputContainer>
          <Label>Password</Label>
          <Input
            type="password"
            value={adminPassword}
            name="adminPassword"
            onChange={handleChange}
          />
        </InputContainer>
        <InputContainer>
          <Label>Confirm Password</Label>
          <Input
            type="password"
            value={adminConfirmPassword}
            name="adminConfirmPassword"
            onChange={handleChange}
          />
        </InputContainer>
        <Filledbutton
          text="Create"
          handleClick={handleCreatingMerchant}
          loading={loading}
        />
        <div className="text-red">{error}</div>
      </Form>
    </FormContainer>
  );
};

export default CreateAdmin;
