import { FC } from "react";
import CustomModal from "./CustomModal";

import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  DeleteButtonContainer,
  DeleteDesc,
  DeleteModalContainer,
} from "./ModalStyles";
import SqaureFilledbutton from "../Reusables/Buttons/SqaureFilledButton";
import { handleModal } from "../../Redux/Reducers/navReducer";

interface DeleteI {
  handleClick: any;
  loading: boolean;
}

const DeleteNodeModal: FC<DeleteI> = ({ handleClick, loading }) => {
  const dispatch = useAppDispatch();
  return (
    <CustomModal
      id={4}
      content={
        <DeleteModalContainer>
          <DeleteDesc>
            Are you sure to delete this node{" "}
            <span className="text-red">permanently</span> ?
          </DeleteDesc>
          <DeleteButtonContainer>
            <SqaureFilledbutton
              text="Cancel"
              handleClick={() => dispatch(handleModal(0))}
            />
            <SqaureFilledbutton
              text="Delete"
              handleClick={handleClick}
              loading={loading}
            />
          </DeleteButtonContainer>
        </DeleteModalContainer>
      }
    />
  );
};

export default DeleteNodeModal;
