import SqaureFilledbutton from "../../components/Reusables/Buttons/SqaureFilledButton";
import moment from "moment";
import { handleModal } from "../../Redux/Reducers/navReducer";
import LinkButton from "../../components/Reusables/Buttons/LinkButton";
import { deleteReportById } from "../../Apis/PrivatesApis/Reports";
import { setSelectedMerchant } from "../../Redux/Reducers/reportReducer";
import { useAppSelector } from "../../Redux/app/hooks";
import { useNavigate } from "react-router-dom";
import { verifyKybStatus } from "../../Apis/PrivatesApis/settings/KYBStatus";
import { store } from "../../Redux/app/store";

export const getMerchantReportRows = (data: any) => {
  const result = data
    ? data?.map((item: any) => {
        return {
          name: item?.businessName,
          email: item?.businessEmail,
          view: <LinkButton text="View Report" link={`/reports/${item?.id}`} />,
          generate: (
            <SqaureFilledbutton
              text="Generate Report"
              // loading={selectedRow === index && loading}
              handleClick={() => {
                // setSelectedRow(index);
                store.dispatch(handleModal(2));
                store.dispatch(
                  setSelectedMerchant({
                    id: item?.id,
                    name: item?.businessName,
                  })
                );
              }}
            />
          ),
        };
      })
    : [];

  return result;
};
export const getMerchantTransactionReportdRows = (
  data: any,
  loading: boolean,
  selectedRow: number,
  setSelectedRow: any
) => {
  const result = data
    ? data?.map((item: any, index: number) => {
        return {
          name: item?.merchant ? item.merchant.businessName : "Admin Generated",
          startDate: item?.startTimeStamp
            ? moment(item?.startTimeStamp * 1000).format("MMMM Do YYYY")
            : "N/A",
          endDate: item?.endTimeStamp
            ? moment(item?.endTimeStamp * 1000).format("MMMM Do YYYY")
            : "N/A",
          export: (
            <LinkButton
              link={item?.reportUrl}
              text="Export CSV"
              download={true}
            />
          ),
          type:
            item?.reportType === 1
              ? "Deposit"
              : item?.reportType === 2
              ? "Withdrawal"
              : item?.reportType === 3
              ? "Remittance"
              : "Unremitted",
          delete: (
            <SqaureFilledbutton
              text="Delete"
              loading={selectedRow === index && loading}
              handleClick={() => {
                setSelectedRow(index);
                deleteReportById(item?.id);
              }}
            />
          ),
        };
      })
    : [];
  return result;
};

export const getMerchantKYBStatusRows = (
  data: any,
  loading: boolean,
  state: boolean
) => {
  const result = data
    ? data?.map((item: any) => {
        return {
          name: item?.merchantName,
          email: "ekwealorhillary@gmail.com",
          status: (
            <span
              className={`${
                item?.status === "Initiated"
                  ? "text-red"
                  : item?.status === "InProgess"
                  ? "text-yellow"
                  : item?.status === "Approved"
                  ? "text-green"
                  : "text-red"
              }`}
            >
              {item?.status}
            </span>
          ),
          lastUpdated: moment().format("MMMM D0 YYYY"),
          verify: item?.status !== "Approved" && (
            <SqaureFilledbutton
              text="Verify"
              handleClick={() => {
                verifyKybStatus(item?.merchantId, state);
              }}
              loading={loading}
            />
          ),
        };
      })
    : [];
  return result;
};
