import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../theme";
import { RouteContainer } from "./Generals/RouteStyles";
import { authPathname, privatePathname } from "./../utils/routersPathname";

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 50vh;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  color: ${theme.colors.textGrey};

  div {
    font-size: 50px;
  }

  p {
    font-size: 20px;
  }
`;

const PagenotFound = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("currentOperator") || "{}");
  const { pathname } = useLocation();

  useEffect(() => {
    if (user) {
      if (!privatePathname.includes(pathname)) {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    } else {
      if (!authPathname.includes(pathname)) {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    }
  }, []);
  return (
    <RouteContainer>
      <Container>
        <div>404</div>
        <p>Page not Found :(</p>
      </Container>
    </RouteContainer>
  );
};

export default PagenotFound;
