import { FC } from "react";
import styled from "styled-components";
import { media } from "../../../respnsive";

const Container = styled.div`
  padding: 10px;
  width: 500px;
  min-height: 241px;
  background: #ffffff;
  border: 1px solid #e9e9e9;

  ${media.tablet} {
    width: 80%;
    height: 131px;
    gap: 10px;
  }
  ${media.tablet} {
    width: 100%;
    height: 131px;
    gap: 10px;
  }
`;

interface DescI {
  textcolor?: string;
}
const Desc = styled.div<DescI>`
  font-weight: 400;
  font-size: 18px;
  height: 100%;
  color: ${(props) => props.textcolor || "#52575c"};
  width: 100%;
  overflow: hidden;
  overflow-wrap: break-word;
}

  ${media.tablet} {
    font-size: 12px;
  }
`;

interface StylishcardProps {
  desc: string;
  textcolor?: string;
}

const Stylishcard: FC<StylishcardProps> = ({ desc, textcolor }) => (
  <Container>
    <Desc textcolor={textcolor}>{desc}</Desc>
  </Container>
);

export default Stylishcard;
