import React, { useEffect, useState } from "react";
import { RouteContainer } from "../../../../components/Generals/RouteStyles";
import MerchantsTables from "../../../../components/Tables/MerchantsTable";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { merchantsReportTransactionCols } from "../../../../utils/TableCols/reports";
import { getMerchantTransactionReportdRows } from "../../../../utils/TableRows/reports";
import { getAllReports } from "./../../../../Apis/PrivatesApis/Reports";

const TransactionReport = () => {
  const [selectedRow, setSelectedRow] = useState(0);
  const { gettingAllReports, allReports, deletingReport } = useAppSelector(
    (state) => state.reports
  );
  const { Page, PageSize } = useAppSelector((state) => state.form);
  const merchantRows = getMerchantTransactionReportdRows(
    allReports?.records,
    deletingReport,
    selectedRow,
    setSelectedRow
  );

  useEffect(() => {
    getAllReports(Page, PageSize);
  }, [Page, PageSize]);

  return (
    <RouteContainer>
      <MerchantsTables
        merchantRows={merchantRows}
        merchantCol={merchantsReportTransactionCols}
        title="Transaction Reports"
        loading={gettingAllReports}
        pageCount={allReports?.totalPages}
        currentPage={Page}
      />
    </RouteContainer>
  );
};

export default TransactionReport;
