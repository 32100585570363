import { FC, useRef } from "react";
import CustomTable from "../Reusables/Tables/CustomTable";

interface IMerchant {
  merchantCol: object[];
  merchantRows: object[];
  title?: any;
  loading?: Boolean;
  noRecordText?: string;
  page?: any;
  currentPage?: any;
  pageCount?: any;
  filter?: any;
  searchOptions?: any;
}
const MerchantsTables: FC<IMerchant> = ({
  merchantCol,
  merchantRows,
  title,
  loading,
  noRecordText,
  page,
  currentPage,
  pageCount,
  filter,
  searchOptions,
}) => {
  const tableRef = useRef();
  const rows = merchantRows;

  const data = {
    columns: merchantCol,
    rows,
  };

  return (
    <>
      <CustomTable
        data={data}
        loading={loading}
        tableId="transactions-table"
        tableRef={tableRef}
        title={title}
        noRecordText={noRecordText || "Merchants not available"}
        page={page}
        pageCount={pageCount}
        currentPage={currentPage}
        filter={filter}
        searchOptions={searchOptions}
      />
    </>
  );
};

export default MerchantsTables;
