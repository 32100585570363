import React from "react";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { RouteContainer } from "./../../../../components/Generals/RouteStyles";
import EditNode from "./EditNode";
import NodeAdding from "./NodeAdding";
import NodeStatus from "./NodeStatus";
import NodeSwitching from "./NodeSwitching";

const Nodes = () => {
  const { currentMainBodyNav } = useAppSelector((state) => state.nav);

  return (
    <RouteContainer>
      {currentMainBodyNav === 1 ? (
        <NodeStatus />
      ) : currentMainBodyNav === 2 ? (
        <NodeSwitching />
      ) : currentMainBodyNav === 3 ? (
        <NodeAdding />
      ) : (
        <EditNode />
      )}
    </RouteContainer>
  );
};

export default Nodes;
