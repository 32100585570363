import CustomModal from "./CustomModal";
import {
  EditNodeProvider,
  getAllNodes,
} from "../../Apis/PrivatesApis/settings/Node";
import {
  Form,
  InputContainer,
  Label,
  Input,
  SelectBar,
  colourStyles,
} from "../../components/Generals/LoginStyles";
import Filledbutton from "../../components/Reusables/Buttons/Filledbutton";
import { errorMessage, getFormDetails } from "../../Redux/Reducers/formReducer";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { useState, useEffect } from "react";
import { removeDuplicates } from "../../Helpers/generals";

const EditNodeMOdal = () => {
  const dispatch = useAppDispatch();
  const { nodeName, nodeUrl, archiveNodeUrl, loading } = useAppSelector(
    (state) => state.form
  );
  const { selectedNode } = useAppSelector((state) => state.settings);
  const { allNodes } = useAppSelector((state) => state.settings);
  const [blockChainNetworkId, setBlockChainNetworkId] = useState({
    label: selectedNode?.blockChainNetworkId,
    value: selectedNode?.blockChainNetworkId,
  });
  const [nodes, setNodes] = useState([
    {
      label: selectedNode?.blockChainNetworkId,
      value: selectedNode?.blockChainNetworkId,
    },
  ]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSelectChange = (option: any, name: string) => {
    if (option.value === "--") dispatch(getFormDetails({ name }));
    else setBlockChainNetworkId({ label: option.label, value: option.value });
  };

  const handleEditNode = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    EditNodeProvider(
      selectedNode?.id,
      blockChainNetworkId?.value || selectedNode?.blockChainNetworkId,
      nodeName || selectedNode?.name,
      nodeUrl || selectedNode?.nodeUrl,
      archiveNodeUrl || selectedNode?.archiveNodeUrl,
    );
  };

  useEffect(() => {
    getAllNodes(null);
  }, []);

  useEffect(() => {
    const nodesFilter = allNodes?.map((item: any) => {
      const node = item?.nodeProviders?.map((node: any) => {
        return {
          label: node?.blockChainNetworkId,
          value: node?.blockChainNetworkId,
        };
      });

      return node;
    });
    const result = nodesFilter?.reduce(
      (prevItem: any, item: any) => prevItem.concat(item),
      []
    );
    const filteredResult = removeDuplicates(result, "value");
    setNodes(filteredResult);
  }, [allNodes]);


  return (
    <CustomModal
      id={6}
      content={
        <Form customWidth="60%" style={{ flexWrap: "wrap" }}>
          <InputContainer>
            <Label>Node Provider ID</Label>
            <Input
              placeholder="Id"
              type="text"
              value={selectedNode?.id}
              name="nodeProviderId"
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Label>Node Name</Label>
            <Input
              placeholder="Name"
              type="text"
              defaultValue={selectedNode?.name}
              name="nodeName"
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Label>Node Url</Label>
            <Input
              placeholder="Url"
              type="text"
              defaultValue={selectedNode?.nodeUrl}
              name="nodeUrl"
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Label>Archive Node Url</Label>
            <Input
              placeholder="Url"
              type="text"
              defaultValue={selectedNode?.archiveNodeUrl}
              name="archiveNodeUrl"
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Label>Blockchain Network Id</Label>
            <SelectBar
              value={blockChainNetworkId}
              onChange={(data: any) => handleSelectChange(data, "Currency")}
              options={nodes}
              styles={colourStyles}
              placeholder="Currency"
            />
          </InputContainer>
          <Filledbutton
            text="Edit Node"
            handleClick={handleEditNode}
            loading={loading}
          />
        </Form>
      }
    />
  );
};

export default EditNodeMOdal;
