import { useAppSelector } from "../../../../Redux/app/hooks";
import { RouteContainer } from "../../../../components/Generals/RouteStyles";
import Security from "./Security";

const AccountSettings = () => {
  const { currentMainBodyNav } = useAppSelector((state) => state.nav);

  return (
    <RouteContainer>{currentMainBodyNav === 1 && <Security />}</RouteContainer>
  );
};

export default AccountSettings;
