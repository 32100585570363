/* eslint-disable react/jsx-no-useless-fragment */
import { Routes, Route } from "react-router-dom";
import PagenotFound from "../components/PagenotFound";
import ScrollToTop from "../components/ScrollToTop";
import Login from "../pages/Auth/Login";
import Confirm2FAOtp from "../pages/Confirm-2FA-otp";
import FAAuth from "../pages/2FAAuth";

const AuthRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        {/* auth Routes */}
        <Route path="/" element={<FAAuth />} />
        <Route path="/confirm-2FA-otp" element={<Confirm2FAOtp />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<PagenotFound />} />
      </Routes>
    </>
  );
};

export default AuthRoutes;
