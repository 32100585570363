import { toast } from "react-toastify";
import { sending } from "../Redux/Reducers/formReducer";
import { handleModal } from "../Redux/Reducers/navReducer";
import {
  setAllCommonErrors,
  setGettingAllCommonErrors,
} from "../Redux/Reducers/settingsReducer";
import { setIs2FAEnabled, setQrObject } from "../Redux/Reducers/userReducer";
import { store } from "../Redux/app/store";
import { checkNetworkChainSupport } from "./AnalyticsApiCalls/walletApis";
import api from "./baseURL";

export const saveCurrencies = async (
  currenciesToAccept: any[],
  setCurrenciesToAccept: any,
  networkChain?: number,
  publicKey?: string,
  nvmType?: number
) => {
  store.dispatch(sending(true));
  await checkNetworkChainSupport(nvmType, publicKey, networkChain).then(
    (res) => {
      api
        .post(`v1/merchant/Currencies/set`, currenciesToAccept, {})
        .then((res) => {
          store.dispatch(sending(false));
          if (res.data) {
            toast.success(res?.data?.message);
            setCurrenciesToAccept([]);
          }
        })
        .catch((err) => {
          store.dispatch(sending(false));
          if (err?.response) toast.error(err?.response?.data?.message);
          if (err?.message === "Network Error") toast.error(err?.message);
        });
    }
  );
};

export const getAcceptedCoins = async (
  setAcceptedCoins: any,
  setGettingAcceptedCoins?: any
) => {
  setGettingAcceptedCoins(true);
  await api
    .get(`v1/merchant/SupportedCurrencies`)
    .then((res) => {
      setAcceptedCoins(res?.data?.data);
      setGettingAcceptedCoins(false);
    })
    .catch(() => setGettingAcceptedCoins(false));
};
export const getSupportedCoins = async (
  setsupportedCoins: any,
  setGettingsupportedCoins?: any
) => {
  setGettingsupportedCoins(true);
  await api
    .get(`v1/merchant/GetActiveCurrencies`)
    .then((res) => {
      setsupportedCoins(res?.data?.data);
      setGettingsupportedCoins(false);
    })
    .catch(() => setGettingsupportedCoins(false));
};

export const getCurrenciesByNetwork = async (
  network: number,
  setSupportedCoin: any
) => {
  await api
    .get(`v1/merchant/Get/CurrencyByNetwork?networkChain=${network}`)
    .then((res: any) => {
      setSupportedCoin(res?.data?.data);
    });
};

export const Check2FAEnabled = async (email: string) => {
  const params = { email };
  await api
    .get("v2/auth/is2FAEnabled", { params })
    .then((res) => {
      store.dispatch(sending(false));
      if (res?.data?.data?.isEnabled)
        store.dispatch(setIs2FAEnabled(res?.data?.data));
    })
    .catch((err) => {
      store.dispatch(sending(false));
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const Enabled2FA = async (email: string) => {
  store.dispatch(sending(true));
  store.dispatch(store.dispatch(handleModal(2)));
  const params = { email };
  await api
    .post(`v2/auth/Setup/2FA`, null, { params })
    .then((res) => {
      store.dispatch(setQrObject(res?.data?.data));
      store.dispatch(sending(false));
    })
    .catch((err) => {
      store.dispatch(store.dispatch(handleModal(0)));
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const getAllCommonErrors = async (from: string, to: string) => {
  const params = { from, to };
  await api
    .get("v1/issueTracker/commonError", { params })
    .then((res) => {
      const { data } = res;
      if (data) {
        store.dispatch(setAllCommonErrors(data?.data));
        store.dispatch(setGettingAllCommonErrors(false));
      }
    })
    .catch((err) => {
      store.dispatch(setGettingAllCommonErrors(false));
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingAllCommonErrors(false));
    });
};

export const getAllCoins = async (setAcceptedCoins: any) => {
  await api
    .get(`v1/merchant/GetActiveCurrencies`)
    .then((res) => {
      const result = res?.data?.data;
      const filteredResult = result?.map((item: any) => {
        return {
          label: item.name,
          value: item.symbol || item.networkChain,
          vmType: item.vmType,
        };
      });
      setAcceptedCoins(filteredResult);
    })
    .catch(() => {});
};
