import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { getFormDetails } from "../../Redux/Reducers/formReducer";
import { colourStyles, Input, SelectBar } from "../Generals/LoginStyles";
import "antd/dist/antd";
import { DateRangePicker } from "./style";
import {
  getSupportedCurrencies,
  getSupportedNetworks,
} from "./../../Apis/AnalyticsApiCalls/walletApis";
import { transactionTypes } from "../../utils/filterSelect";
import { media } from "../../respnsive";

const SearchbarContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  // flex-wrap: wrap;
  // justify-content: space-around;
  gap: 10px;
  // overflow-x: auto;

  ${media.tablet} {
    flex-wrap: wrap;
  }
`;

interface SearchOptionsI {
  recordDateRange?: any;
  statusFilter?: any;
  page?: string;
}

const SearchOptions: FC<SearchOptionsI> = ({
  recordDateRange,
  statusFilter,
  page,
}) => {
  const {
    Currency,
    NetworkChain,
    Status,
    TransactionHash,
    WalletAddress,
    TransactionType,
  } = useAppSelector((state) => state.form);
  const [currencies, setCurrencies] = useState([{ label: "--", value: "--" }]);
  const [networks, setNetworks] = useState([{ label: "--", value: "--" }]);
  const dispatch = useAppDispatch();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };
  const handleDateChange = (date: any, name: string) => {
    dispatch(getFormDetails({ name: "StartTimeStamp", value: date[0].$d }));
    dispatch(getFormDetails({ name: "EndTimeStamp", value: date[1].$d }));
  };
  const handleSelectChange = (option: any, name: string) => {
    if (option.value === "--") dispatch(getFormDetails({ name }));
    else dispatch(getFormDetails({ name, value: option.value }));
  };

  useEffect(() => {
    getSupportedCurrencies(setCurrencies);
    getSupportedNetworks(setNetworks, false);
  }, []);

  return (
    <SearchbarContainer>
      <Input
        type="text"
        name="TransactionHash"
        onChange={handleInputChange}
        value={TransactionHash === null ? "" : TransactionHash}
        style={{ width: "150px" }}
        placeholder="Transaction Hash"
      />
      <Input
        type="text"
        name="WalletAddress"
        onChange={handleInputChange}
        value={WalletAddress === null ? "" : WalletAddress}
        style={{ width: "150px" }}
        placeholder="Wallet Address"
      />
      <div style={{ width: "100px" }}>
        <SelectBar
          defaultValue={Status}
          onChange={(data: any) => handleSelectChange(data, "Status")}
          options={statusFilter}
          styles={colourStyles}
          placeholder="Status"
        />
      </div>

      <div style={{ width: "100px" }}>
        <SelectBar
          defaultValue={Currency}
          onChange={(data: any) => handleSelectChange(data, "Currency")}
          options={currencies}
          styles={colourStyles}
          placeholder="Currency"
        />
      </div>
      <div style={{ width: "100px" }}>
        <SelectBar
          defaultValue={NetworkChain}
          onChange={(data: any) => handleSelectChange(data, "NetworkChain")}
          options={networks}
          styles={colourStyles}
          placeholder="Network"
        />
      </div>
      <DateRangePicker
        onChange={(values: any) => {
          handleDateChange(values, "");
        }}
      />
    </SearchbarContainer>
  );
};

export default SearchOptions;
