import React from "react";
import { RouteContainer } from "../../../../components/Generals/RouteStyles";
import { useAppSelector } from "../../../../Redux/app/hooks";
import Report from "./Report";
import Deposit from "./Deposit";
import Withdrawals from "./Withdrawals";

const TransactionTracker = () => {
  const { currentMainBodyNav } = useAppSelector((state) => state.nav);

  return (
    <RouteContainer>
      {currentMainBodyNav === 1 ? (
        <Report />
      ) : currentMainBodyNav === 2 ? (
        <Deposit />
      ) : (
        <Withdrawals />
      )}
    </RouteContainer>
  );
};

export default TransactionTracker;
