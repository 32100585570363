import { toast } from "react-toastify";
import { sending } from "../../Redux/Reducers/formReducer";
import {
  setActivatingUser,
  setDeactivatingUser,
} from "../../Redux/Reducers/userReducer";
import api from "../baseURL";
import { store } from "../../Redux/app/store";
import { setDisabling } from "../../Redux/Reducers/settingsReducer";

export const activateMerchant = async (id?: string, email?: string) => {
  store.dispatch(setActivatingUser(true));
  const params: any = {
    id,
    email,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null || "") {
      delete params[key];
    }
  });
  await api
    .get(`v2/Admin/activateUser`, { params })
    .then((res) => {
      const { data } = res;
      toast.success(data?.message);
      store.dispatch(setActivatingUser(false));
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setActivatingUser(false));
    });
};

export const deActivateMerchant = async (id?: string, email?: string) => {
  store.dispatch(setDeactivatingUser(true));
  const params: any = {
    id,
    email,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null || "") {
      delete params[key];
    }
  });
  await api
    .get(`v2/Admin/deactivateUser`, { params })
    .then((res) => {
      const { data } = res;
      toast.success(data?.message);
      store.dispatch(setDeactivatingUser(false));
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setDeactivatingUser(false));
    });
};

export const createMerchant = async (
  firstName: string,
  lastName: string,
  businessName: string,
  emailAddress: string,
  password: string
) => {
  store.dispatch(sending(true));
  const body = {
    firstName,
    lastName,
    businessName,
    emailAddress,
    password,
  };

  await api
    .post("v2/Admin/CreateMerchannt", body)
    .then((res) => {
      const { data } = res;
      toast.success(data?.message);
      store.dispatch(sending(false));
    })
    .catch((err) => {
      if (
        err?.response.data?.message ===
        "Could not resolve merchant wallet for currency"
      )
        toast.success("Account created Successfully");
      else {
        toast.error(err?.response?.data?.message);
      }
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const createAdmin = async (
  firstName: string,
  lastName: string,
  // businessName: string,
  emailAddress: string,
  password: string,
  confirmPassword: string,
  role: number
) => {
  store.dispatch(sending(true));
  const body = {
    firstName,
    lastName,
    // businessName,
    emailAddress,
    password,
    confirmPassword,
    role,
  };

  await api
    .post("v2/auth/admin/register", body)
    .then((res) => {
      const { data } = res;
      toast.success(data?.message || "Account created Successfully");
      store.dispatch(sending(false));
    })
    .catch((err) => {
      if (
        err?.response.data?.message ===
        "Could not resolve merchant wallet for currency"
      )
        toast.success("Account created Successfully");
      else {
        toast.error(err?.response?.data?.message);
      }
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const sendNotification = async (
  MerchantId: string,
  MailSubject: string,
  MailBody: string
) => {
  store.dispatch(sending(true));
  const body = {
    MerchantId,
    MailSubject,
    MailBody,
  };

  await api
    .post("v2/Admin/Notification/Send", body)
    .then((res) => {
      const { data } = res;
      toast.success(data?.message);
      store.dispatch(sending(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const Diable2FA = async (merchantId: string) => {
  store.dispatch(setDisabling(true));
  const params = { merchantId };
  await api
    .get("v2/auth/2FA/Disable", {
      params,
    })
    .then(() => {
      toast.success("2FA Disabled Successfully");
      store.dispatch(setDisabling(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setDisabling(false));
    });
};
