import styled from "styled-components";
import { theme } from "./../../../theme";

export const OverviewContianer = styled.div`
  display: flex;
  // align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 100%;
  padding-top: 50px;
`;

export const LatestContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 30px;
`;

export const LatestTitle = styled.div`
  font-size: 18px;
  color: ${theme.colors.textBlack};
  font-weight: bold;
`;

export const LatestMerchants = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: ${theme.colors.bgWhite};
  min-height: 50vh;
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
`;


export const MerchantName = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: ${theme.colors.textGrey};
  flex: 0.3;
  max-width: 30%;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MerchantEmail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${theme.colors.textGrey};
  flex: 0.4;
`;

export const RegisteredDate = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 14px;
  color: ${theme.colors.textGrey};
  flex: 0.3;
`;

export const LatestTransactions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: ${theme.colors.bgWhite};
  min-height: 50vh;
  flex: 0.5;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.primaryBorder};
  padding: 10px 0;
  gap: 10px;
`;
