import styled from "styled-components";
import { media } from "../../respnsive";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

interface ToggleProp {
  toggleFilter?: boolean;
  toggleGenerate?: boolean;
}
export const FilterContainer = styled.div<ToggleProp>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: ${(props) => (props.toggleFilter ? "0" : "-150%")};
  left: 0;
  right: 0;
  z-index: 100000;
  background: rgba(32, 66, 184, 0.25);
  transition: all 1s ease-in-out;
`;
export const GenerateContainer = styled.div<ToggleProp>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: ${(props) => (props.toggleGenerate ? "0" : "-100%")};
  left: 0;
  right: 0;
  z-index: 100000;
  background: rgba(32, 66, 184, 0.25);
  transition: all 1s ease-in-out;
`;

export const FilterForm = styled.form`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  width: 30%;
  // top: 70px;
  height: 100%;
  background: #ffffff;
  box-shadow: 21px 0px 16px rgba(0, 0, 0, 0.05);
  padding: 30px;
  overflow-y: auto;

  ${media.tablet} {
    width: 60%;
  }
`;

export const Input = styled.input`
  width: 331px;
  height: 48px;
  background: rgba(32, 66, 184, 0.03);
  border: 1px solid rgba(32, 66, 184, 0.11);
  border-radius: 5px;
`;

export const Close = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 150%;
  cursor: pointer;
  color: #25282b;
  ${media.tablet} {
    display: flex;
  }
`;
export const CloseInner = styled.div`
  display: none;
  justify-content: flex-end;
  margin-bottom: 20px;
  font-size: 30px;
  cursor: pointer;
  color: #25282b;
  ${media.tablet} {
    display: flex;
  }
`;

export const Checkbox = styled.input`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 90%;
  margin-bottom: 30px;

  input {
    width: 24px;
    height: 24px;
    background: rgba(32, 66, 184, 0.03);
    border: 1px solid rgba(32, 66, 184, 0.11);

    ${media.phone} {
      width: 18px;
      height: 18px;
    }
  }
`;

interface DateRangePickerI {
  width?: string;
}

export const DateRangePicker = styled(RangePicker)<DateRangePickerI>`
  width: ${(props) => (props.width ? props.width : "250px")};
  height: 40px;
  background: rgba(32, 66, 184, 0.03);
  border: 1px solid rgba(32, 66, 184, 0.11);
  border-radius: 5px;
  font-size: 13px;
`;


export const ChartFilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  padding: 0 10px;
  position: absolute;
  top: 2%;
  left: 12%;
  z-index: 100000;
  background: #fff;
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;