import { useState, useEffect } from "react";
import {
  Form,
  FormContainer,
  Input,
  InputContainer,
  Label,
  SelectBar,
  colourStyles,
} from "../../../../components/Generals/LoginStyles";
import { useAppDispatch, useAppSelector } from "../../../../Redux/app/hooks";
import {
  errorMessage,
  getFormDetails,
} from "../../../../Redux/Reducers/formReducer";
import { getSupportedNetworks } from "../../../../Apis/AnalyticsApiCalls/walletApis";
import Filledbutton from "../../../../components/Reusables/Buttons/Filledbutton";
import { reScanBlock } from "../../../../Apis/PrivatesApis/TransactionsApis";
import { getAllCoins } from "../../../../Apis/settings";
import api from "../../../../Apis/baseURL";

const ResacnBlock = () => {
  const [acceptedCoins, setAcceptedCoins] = useState([]);
  const [networkList, setNetworkList] = useState([]);
  const { network, currency, transactionHash } = useAppSelector(
    (state) => state.form
  );
  const { reScanningBlock } = useAppSelector((state) => state.wallet);

  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSelectChange = async (option: any, name: string) => {
    if (name === "network") {
      await api
        .get(
          `v1/merchant/Get/CurrencyByNetwork?networkChain=${option.value}`,
          {}
        )
        .then((res: any) => {
          const result = res?.data?.data.map((item: any) => {
            return {
              label: item?.name,
              value: item?.symbol,
            };
          });
          setAcceptedCoins(result);
        });
    }
    if (option.value === "--") dispatch(getFormDetails({ name }));
    else
      dispatch(
        getFormDetails({
          name,
          value: option?.value,
        })
      );
  };

  useEffect(() => {
    getSupportedNetworks(setNetworkList);
    // getAllCoins(setAcceptedCoins);
  }, []);

  return (
    <FormContainer style={{ minHeight: "100vh", alignItems: "flex-start" }}>
      <Form customWidth="80%" style={{ flexWrap: "wrap" }}>
        <InputContainer>
          <Label>Transaction Hash</Label>
          <Input
            placeholder="Enter Transaction Hash"
            type="text"
            value={transactionHash}
            name="transactionHash"
            onChange={handleChange}
          />
        </InputContainer>

        <InputContainer>
          <Label>Network</Label>
          <SelectBar
            options={networkList}
            styles={colourStyles}
            placeholder="Select Network"
            // isDisabled={selectAll}
            onChange={(data: any) => handleSelectChange(data, "network")}
          />
        </InputContainer>

        <InputContainer>
          <Label>Currency</Label>
          <SelectBar
            options={acceptedCoins}
            styles={colourStyles}
            placeholder="Select Currency"
            // isDisabled={selectAll}
            onChange={(data: any) => handleSelectChange(data, "currency")}
          />
        </InputContainer>
        <Filledbutton
          text="ReScan"
          handleClick={(e: any) => {
            e.preventDefault();
            reScanBlock(transactionHash, network, currency);
          }}
          loading={reScanningBlock}
        />
      </Form>
    </FormContainer>
  );
};

export default ResacnBlock;
