import api from "../baseURL";
import { toast } from "react-toastify";
import {
  setDeletingReport,
  setGeneratingReports,
  setGettingTotalReports,
  setTotalReports,
} from "../../Redux/Reducers/reportReducer";
import { handleModal } from "../../Redux/Reducers/navReducer";
import { store } from "../../Redux/app/store";

export const getAllReports = async (page: any, pageSize: any) => {
  const params: any = {
    page,
    pageSize,
  };
  store.dispatch(setGettingTotalReports(true));
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null || "") {
      delete params[key];
    }
  });
  await api
    .get("v2/Admin/BusinessReport")
    .then((res) => {
      const { data } = res;
      store.dispatch(setTotalReports(data?.data));
      store.dispatch(setGettingTotalReports(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingTotalReports(false));
    });
};

export const getReportsById = async (reportId?: string) => {
  const params = {
    reportId,
  };
  store.dispatch(setGettingTotalReports(true));
  await api
    .get("v2/Admin/BusinessReport/GetById", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setTotalReports(data?.data));
      store.dispatch(setGettingTotalReports(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingTotalReports(false));
    });
};

export const generateReport = async (
  MerchantId?: string,
  StartTimeStamp?: number,
  EndTimeStamp?: number,
  TransactionType?: number | null
) => {
  const body: any = {
    StartTimeStamp,
    EndTimeStamp,
    MerchantId,
    TransactionType,
  };

  Object.keys(body)?.forEach((key) => {
    if (body[key] === null || "") {
      delete body[key];
    }
  });

  store.dispatch && store.dispatch(setGeneratingReports(true));
  let result = null;
  await api
    .post("v2/Admin/BusinessReport/generate", body, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => {
      const { data } = res;
      toast.success(data?.message);
      store.dispatch(setGeneratingReports(false));
      store.dispatch(handleModal(0));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGeneratingReports(false));
    });
  return result;
};

export const deleteReportById = async (reportId: string) => {
  const params = {
    reportId,
  };
  store.dispatch(setDeletingReport(true));
  await api
    .get("v2/Admin/BusinessReport/Delete", { params })
    .then((res) => {
      const { data } = res;
      toast.success("Successful");
      store.dispatch(setDeletingReport(false));
      window.location.reload();
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setDeletingReport(false));
    });
};
