import { useEffect } from "react";
import { getAllMerchants } from "../../Apis/PrivatesApis/overviewApis";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { accessControlCols } from "../../utils/tablecols";
import { getAccessControlRow } from "./../../utils/TableRows/thirdparty";
import MerchantsTables from "./MerchantsTable";
import { getAllKybStatus } from "./../../Apis/PrivatesApis/settings/KYBStatus";
import MerchantFilter from "./../Forms/MerchantFilter";

const AccessControlTable = () => {
  const { gettingAllMerchants, allMerchants } = useAppSelector(
    (state) => state.overview
  );
  const { allKybStatus, verifyingMerchant } = useAppSelector(
    (state) => state.reports
  );
  const { activatingUser, deActivatingUser, pausingUser } = useAppSelector(
    (state) => state.user
  );
  const { loading } = useAppSelector((state) => state.form);
  const { disabling2FA } = useAppSelector((state) => state.settings);
  const { Page, PageSize, merchantStatus } = useAppSelector(
    (state) => state.form
  );
  const merchantRows = getAccessControlRow(
    allMerchants?.records,
    allKybStatus,
    {
      activatingUser,
      deActivatingUser,
      pausingUser,
      verifyingMerchant,
      loading,
    },
    disabling2FA
  );

  useEffect(() => {
    getAllMerchants(Page, PageSize, merchantStatus);
    getAllKybStatus();
  }, [Page, PageSize, merchantStatus]);

  useEffect(() => {
    getAllKybStatus();
  }, [verifyingMerchant]);

  return (
    <>
      <MerchantsTables
        merchantCol={accessControlCols}
        merchantRows={merchantRows}
        title="Merchants"
        loading={gettingAllMerchants}
        pageCount={allMerchants?.totalPages}
        currentPage={Page}
        filter={<MerchantFilter />}
      />
    </>
  );
};

export default AccessControlTable;
