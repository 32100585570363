import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { getFormDetails } from "../../Redux/Reducers/formReducer";
import { colourStyles, Input, SelectBar } from "../Generals/LoginStyles";
import "antd/dist/antd";
import { getSupportedNetworks } from "../../Apis/AnalyticsApiCalls/walletApis";
import { getSupportedCurrencies } from './../../Apis/AnalyticsApiCalls/walletApis';

const SearchbarContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  //   justify-content: space-around;
  gap: 10px;
`;

const UnremittedFilter = () => {
  const {
    unRemittedCurrency,
    unRemittedNetworkChain,
    unRemittedWalletAddress,
  } = useAppSelector((state) => state.form);
  const dispatch = useAppDispatch();
  const [currencies, setCurrencies] = useState([{ label: "--", value: "--" }]);
  const [networks, setNetworks] = useState([{ label: "--", value: "--" }]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };
  const handleSelectChange = (option: any, name: string) => {
    if (option.value === "--") dispatch(getFormDetails({ name }));
    else dispatch(getFormDetails({ name, value: option.value }));
  };


   useEffect(() => {
     getSupportedCurrencies(setCurrencies);
     getSupportedNetworks(setNetworks);
   }, []);

  return (
    <SearchbarContainer>
      <Input
        type="text"
        name="unRemittedWalletAddress"
        onChange={handleInputChange}
        value={unRemittedWalletAddress}
        style={{ width: "250px" }}
        placeholder="Wallet Address"
      />
      <div style={{ width: "150px" }}>
        <SelectBar
          defaultValue={unRemittedCurrency}
          onChange={(data: any) =>
            handleSelectChange(data, "unRemittedCurrency")
          }
          options={currencies}
          styles={colourStyles}
          placeholder="Currency"
        />
      </div>
      <div style={{ width: "150px" }}>
        <SelectBar
          defaultValue={unRemittedNetworkChain}
          onChange={(data: any) =>
            handleSelectChange(data, "unRemittedNetworkChain")
          }
          options={networks}
          styles={colourStyles}
          placeholder="Network"
        />
      </div>
    </SearchbarContainer>
  );
};

export default UnremittedFilter;
