import { useEffect } from "react";
import MainNav from "../../../components/NavBars/MainNav";
import { RouteContainer } from "../../../components/Generals/RouteStyles";
import HealthStatus from "./HealthStatus";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import TransactionTracker from "./TransactionTracker";
import {
  handleMainnav,
  handleSubnav,
} from "../../../Redux/Reducers/navReducer";
import { useNavigate } from "react-router-dom";
import { LoggedInCheck2FAEnabled } from "../../../Apis/accountProcesing";

const workersSubnav = [
  {
    navId: 1,
    navTitle: "Report",
  },
  {
    navId: 2,
    navTitle: "Deposits",
  },
  {
    navId: 3,
    navTitle: "Withdrawals",
  },
];

const index = () => {
  const { currentSubnav } = useAppSelector((state) => state.nav);
  const dispatch = useAppDispatch();
    const currentOperator = JSON.parse(
      localStorage.getItem("currentOperator") || "{}"
    );
    const navigate = useNavigate
    ();

  useEffect(() => {
    LoggedInCheck2FAEnabled(currentOperator?.email, navigate);
  }, []);

  useEffect(() => {
    dispatch(handleSubnav(1));
    dispatch(handleMainnav(1));
  }, []);

  return (
    <>
      {currentSubnav === 2 && <MainNav listItem={workersSubnav} />}
      <RouteContainer>
        {currentSubnav === 1 ? <HealthStatus /> : <TransactionTracker />}
      </RouteContainer>
    </>
  );
};

export default index;
