import React from "react";
import { ErrorContainer } from "./styled";
import Stylishcard from "./../../../../components/Reusables/Cards/Stylishcard";

const WorkersError = () => {
  return (
    <ErrorContainer>
      <Stylishcard desc="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Facere perferendis perspiciatis sed itaque voluptatem dolorem. Alias reiciendis harum odit expedita. sit amet consectetur adipisicing elit. Facere perferendis perspiciatis sed itaque voluptatem dolorem. Alias reiciendis harum odit expedita" />
    </ErrorContainer>
  );
};

export default WorkersError;
