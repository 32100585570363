import React, { useEffect, useState } from "react";
import CustomModal from "./CustomModal";
import {
  Form,
  FormContainer,
  Input,
  InputContainer,
  TextArea,
  Label,
  SelectBar,
  colourStyles,
  DatePick,
} from "../Generals/LoginStyles";
import Filledbutton from "../Reusables/Buttons/Filledbutton";
import { useAppSelector } from "../../Redux/app/hooks";
import { useAppDispatch } from "./../../Redux/app/hooks";
import { getFormDetails } from "../../Redux/Reducers/formReducer";
import { retryPendingRemittance } from "../../Apis/AnalyticsApiCalls/remittanceApis";
import moment from "moment";
import { getAllMerchants } from "../../Apis/PrivatesApis/overviewApis";
import { getSupportedCurrencies } from "../../Apis/AnalyticsApiCalls/walletApis";

const RemitModal = () => {
  const { reportStartDate, reportEndDate } = useAppSelector(
    (state) => state.form
  );
  const dispatch = useAppDispatch();
  const [merchant, setMerchants] = useState([{ label: "--", value: "--" }]);
  const { allMerchants } = useAppSelector((state) => state.overview);
  const { MerchantId, currencyId, loading } = useAppSelector(
    (state) => state.form
  );
  const [currencies, setCurrencies] = useState([{ label: "--", value: "--" }]);
  const merchantDetails = JSON.parse(
    localStorage.getItem("selectedMerchant") || "{}"
  );

  const handleSelectChange = (option: any, name: string) => {
    if (option.value === "--") dispatch(getFormDetails({ name }));
    else dispatch(getFormDetails({ name, value: option.value }));
  };

  const handleDateChange = (date: any, name: string) => {
    dispatch(getFormDetails({ name, value: date }));
  };

  const handleRetry = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    retryPendingRemittance(
      merchantDetails?.id,
      currencyId,
      moment(reportStartDate).unix(),
      moment(reportEndDate).unix(),
    );
  };

  useEffect(() => {
    getAllMerchants(dispatch);
  }, []);

  useEffect(() => {
    const filter = allMerchants?.records?.map((merchant: any) => {
      return {
        label: merchant?.businessName,
        value: merchant?.id || "--",
      };
    });
    setMerchants(filter);
  }, [allMerchants]);

  useEffect(() => {
    getSupportedCurrencies(setCurrencies, false, "id");
  }, []);

  return (
    <CustomModal
      id={5}
      content={
        <Form customWidth="70%" style={{ marginTop: "20px" }}>
          <InputContainer>
            <Label>Merchant </Label>
            <Input value={merchantDetails?.name} readOnly />
          </InputContainer>
          <InputContainer>
            <Label>Currency</Label>
            <SelectBar
              onChange={(data: any) => handleSelectChange(data, "currencyId")}
              options={currencies}
              styles={colourStyles}
              placeholder="Currency"
            />
          </InputContainer>
          <InputContainer>
            <Label>Start Date</Label>
            <DatePick
              name="reportStartDate"
              dateFormat="dd/MM/yyyy"
              onChange={(date: Date) =>
                handleDateChange(date, "reportStartDate")
              }
              selected={reportStartDate}
            />
          </InputContainer>
          <InputContainer>
            <Label>End Date</Label>
            <DatePick
              name="reportEndDate"
              dateFormat="dd/MM/yyyy"
              onChange={(date: Date) => handleDateChange(date, "reportEndDate")}
              onSelect={(date: Date) => handleDateChange(date, "reportEndDate")}
              selected={reportEndDate}
            />
          </InputContainer>

          <Filledbutton
            text="Retry"
            handleClick={handleRetry}
            loading={loading}
          />
        </Form>
      }
    />
  );
};

export default RemitModal;
