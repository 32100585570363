import React from "react";
import HealthStatusCard from "../../../../components/Reusables/Cards/HealthStatusCard";
import { HealtStatusContainer } from "./styles";
import HealthStatusFilter from "../../../../components/Forms/HealthStatusFilter";

const HealthStatus = () => {
  return (
    <div style={{ padding: "20px" }}>
      <HealthStatusFilter />
      <HealtStatusContainer>
        <HealthStatusCard />
        <HealthStatusCard />
        <HealthStatusCard />
        <HealthStatusCard />
        <HealthStatusCard />
        <HealthStatusCard />
        <HealthStatusCard />
      </HealtStatusContainer>
    </div>
  );
};

export default HealthStatus;
