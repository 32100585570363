import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
/* eslint-disable no-param-reassign */

export interface overviewState {
  gettingTotalRecords: Boolean;
  totalRecords: any;
  gettingActiveMerchants: Boolean;
  activeMerchants: any;
  gettingInActiveMerchants: Boolean;
  inActiveMerchants: any;
  gettingAllMerchants: Boolean;
  allMerchants: any;
}

const initialState: overviewState = {
  gettingTotalRecords: false,
  totalRecords: null,
  gettingActiveMerchants: false,
  activeMerchants: null,
  gettingInActiveMerchants: false,
  inActiveMerchants: null,
  gettingAllMerchants: false,
  allMerchants: null,
};

export const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    setGettingTotalRecords: (state, action) => {
      state.gettingTotalRecords = action.payload;
    },
    setTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    setGettingActiveMerchants: (state, action) => {
      state.gettingActiveMerchants = action.payload;
    },
    setActiveMerchants: (state, action) => {
      state.activeMerchants = action.payload;
    },
    setGettingInActiveMerchants: (state, action) => {
      state.gettingInActiveMerchants = action.payload;
    },
    setInActiveMerchants: (state, action) => {
      state.inActiveMerchants = action.payload;
    },
    setGettingAllMerchants: (state, action) => {
      state.gettingAllMerchants = action.payload;
    },
    setAllMerchants: (state, action) => {
      state.allMerchants = action.payload;
    },
  },
});

export const {
  setGettingTotalRecords,
  setTotalRecords,
  setGettingActiveMerchants,
  setActiveMerchants,
  setGettingInActiveMerchants,
  setInActiveMerchants,
  setGettingAllMerchants,
  setAllMerchants,
} = overviewSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectToggle = (state: RootState) => state.overview;

export default overviewSlice.reducer;
