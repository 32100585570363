import { useEffect } from "react";
import { ErrorContainer, FilterContainer } from "./styled";
import Stylishcard from "./../../../../components/Reusables/Cards/Stylishcard";
import { getAllCommonErrors } from "./../../../../Apis/settings";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { useAppDispatch } from "./../../../../Redux/app/hooks";
import { InputContainer } from "../../../../components/Generals/LoginStyles";
import { Label, DatePick } from "./../../../../components/Generals/LoginStyles";
import { getFormDetails } from "../../../../Redux/Reducers/formReducer";

const TransactionError = () => {
  const { from, to } = useAppSelector((state) => state.form);
  const { gettingCommonErrors, allCommonErrors } = useAppSelector(
    (state) => state.settings
  );
  const dispatch = useAppDispatch();

  const handleDateChange = (date: any, name: string) => {
    dispatch(getFormDetails({ name, value: date }));
  };

  useEffect(() => {
    getAllCommonErrors(from, to);
  }, [from, to, dispatch]);

  return (
    <ErrorContainer>
      <FilterContainer>
        <InputContainer>
          <Label>Start Date</Label>
          <DatePick
            name="from"
            dateFormat="dd/MM/yyyy"
            onChange={(date: Date) => handleDateChange(date, "from")}
            selected={from}
          />
        </InputContainer>
        <InputContainer>
          <Label>End Date</Label>
          <DatePick
            name="to"
            dateFormat="dd/MM/yyyy"
            onChange={(date: Date) => handleDateChange(date, "to")}
            selected={to}
          />
        </InputContainer>
      </FilterContainer>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          width: "100%",
        }}
      >
        {allCommonErrors?.length ? (
          allCommonErrors?.map(
            (item: any) =>
              item !== null && <Stylishcard desc={item} textcolor="#fb0606" />
          )
        ) : (
          <Stylishcard desc="No record for the selected spec" />
        )}
      </div>
    </ErrorContainer>
  );
};

export default TransactionError;
