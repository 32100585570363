import React, { useState } from "react";
import { MerchantStatus } from "../../utils/filterSelect";
import styled from "styled-components";
import { colourStyles, SelectBar } from "../Generals/LoginStyles";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { getFormDetails } from "../../Redux/Reducers/formReducer";
import "react-datepicker/dist/react-datepicker.css";
import { media } from "./../../respnsive";

const SearchbarContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  // flex-wrap: wrap;
  // justify-content: space-around;
  gap: 10px;
  // overflow-x: auto;

  ${media.tablet} {
    flex-wrap: wrap;
  }
`;
const MerchantFilter = () => {
  const {} = useAppSelector((state) => state.form);
  const dispatch = useAppDispatch();
  const { merchantStatus } = useAppSelector((state) => state.form);
  const [currentStatus, setCurrentStatus] = useState({
    label: "--",
    value: merchantStatus,
  });

  const handleSelectChange = (option: any, name: string) => {
    if (option.value === "--") dispatch(getFormDetails({ name }));
    else {
      dispatch(getFormDetails({ name, value: option.value }));
      setCurrentStatus({ label: option?.label, value: option?.value });
    }
  };

  return (
    <SearchbarContainer>
      <SelectBar
        defaultValue={currentStatus}
        onChange={(data: any) => handleSelectChange(data, "merchantStatus")}
        options={MerchantStatus}
        styles={colourStyles}
        customWidth="300px"
        placeholder="Status"
        data-testid="merchant-filter"
      />
    </SearchbarContainer>
  );
};

export default MerchantFilter;
