import { CiGrid42, CiSettings } from "react-icons/ci";
import { AiOutlineLineChart } from "react-icons/ai";
import { GiWarPick } from "react-icons/gi";
import { VscReport } from "react-icons/vsc";
import { TbUsers } from "react-icons/tb";
import { store } from "./Redux/app/store";

export const sidebarNavs = [
  {
    id: 1,
    link: "/",
    name: "Overview",
    icon: <CiGrid42 />,
    visible: () => {
      return (
        store?.getState()?.user?.currentRoles?.includes("Admin") ||
        store?.getState()?.user?.currentRoles?.includes("SuperAdmin")
      );
    },
  },
  {
    id: 2,
    link: "/transactions",
    name: "Merchants Transactions",
    icon: <AiOutlineLineChart />,
    children: [
      { id: 1, name: "Deposits" },
      { id: 2, name: "withdrawals" },
      { id: 3, name: "Remittance" },
      { id: 4, name: "Unremitted" },
      { id: 5, name: "Rescan Block" },
    ],
    visible: () => {
      return (
        store?.getState()?.user?.currentRoles?.includes("Admin") ||
        store?.getState()?.user?.currentRoles?.includes("SuperAdmin")
      );
    },
  },
  // {
  //   id: 3,
  //   link: "/workers",
  //   name: "Workers",
  //   icon: <GiWarPick />,
  //   type: "",
  //   children: [
  //     { id: 1, name: "Health Status" },
  //     { id: 2, name: "Tracker" },
  //   ],
  //   visible: () => {
  //     return (
  //       store?.getState()?.user?.currentRoles?.includes("Admin") ||
  //       store?.getState()?.user?.currentRoles?.includes("SuperAdmin")
  //     );
  //   },
  // },
  {
    id: 3,
    link: "/workers",
    name: "Workers",
    icon: <GiWarPick />,
    type: "",
    children: [
      { id: 1, name: "Health Status" },
      { id: 2, name: "Tracker" },
    ],
    visible: () => {
      return (
        store
          ?.getState()
          ?.user?.currentRoles?.includes("Admin" || "SuperAdmin") ||
        store?.getState()?.user?.currentRoles?.includes("SuperAdmin")
      );
    },
  },
  // {
  //   id: 3,
  //   link: "https://tracker.switchwallet.io/",
  //   name: "Workers",
  //   icon: <GiWarPick />,
  //   type: "external",
  //   children: [
  //     { id: 1, name: "Health Status" },
  //     { id: 2, name: "Tracker" },
  //   ],
  //   visible: () => {
  //     return store
  //       ?.getState()
  //       ?.user?.currentRoles?.includes("Admin" || "SuperAdmin" || "Superadmin");
  //   },
  // },
  {
    id: 4,
    link: "/third-party",
    name: "Third Party",
    icon: <TbUsers />,
    visible: () => {
      return (
        store?.getState()?.user?.currentRoles?.includes("Admin") ||
        store?.getState()?.user?.currentRoles?.includes("SuperAdmin")
      );
    },
  },
  // {
  //   id: 5,
  //   link: "/remittances",
  //   name: "Remittance",
  //   icon: <HiOutlineCreditCard />,
  // },
  {
    id: 5,
    link: "/reports",
    name: "Reports",
    icon: <VscReport />,
    visible: () => {
      return (
        store?.getState()?.user?.currentRoles?.includes("Admin") ||
        store?.getState()?.user?.currentRoles?.includes("SuperAdmin")
      );
    },
  },
  // {
  //   id: 6,
  //   link: "/usage",
  //   name: "Usage",
  //   icon: <MdOutlineDataUsage />,
  // },
  {
    id: 6,
    link: "/settings",
    name: "Settings",
    icon: <CiSettings />,
    protectedChildren: [
      { id: 1, name: "Nodes" },
      { id: 2, name: "Account Settings" },
      { id: 3, name: "Error Status" },
    ],
    children: [{ id: 1, name: "Account Settings" }],
    visible: () => {
      return (
        store?.getState()?.user?.currentRoles?.includes("Admin") ||
        store?.getState()?.user?.currentRoles?.includes("SuperAdmin")
      );
    },
  },
];
