import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colourStyles, SelectBar } from "../Generals/LoginStyles";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { getFormDetails } from "../../Redux/Reducers/formReducer";
import "react-datepicker/dist/react-datepicker.css";
import { media } from "./../../respnsive";
import { getSupportedNetworks } from "../../Apis/AnalyticsApiCalls/walletApis";

const SearchbarContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  // flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
  // overflow-x: auto;
  padding-right: 20px;

  ${media.tablet} {
    flex-wrap: wrap;
  }
`;
const HealthStatusFilter = () => {
  const {} = useAppSelector((state) => state.form);
  const dispatch = useAppDispatch();
  const [networks, setNetworks] = useState([{ label: "--", value: "--" }]);

  const handleSelectChange = (option: any, name: string) => {
    if (option.value === "--") dispatch(getFormDetails({ name }));
    else {
      dispatch(getFormDetails({ name, value: option.value }));
    }
  };

  useEffect(() => {
    getSupportedNetworks(setNetworks, false);
  }, []);

  return (
    <SearchbarContainer>
      <SelectBar
        onChange={(data: any) => handleSelectChange(data, "merchantStatus")}
        options={networks}
        styles={colourStyles}
        customWidth="300px"
        placeholder="Status"
      />
    </SearchbarContainer>
  );
};

export default HealthStatusFilter;
