import React, { FC, useState } from "react";
import { DotLoader } from "react-spinners";
import { numberWithCommaswithoutdecimals } from "../../../Helpers/generals";
/* eslint-disable react/require-default-props */
import {
  Balance,
  BalanceCard,
  BalanceCardHeader,
  BalanceCardHeaderTitle,
  BalanceCardHeaderType,
  HideIcon,
} from "../../Overview/styles";
import { BiChevronsRight } from "react-icons/bi";

interface CardProps {
  title: string;
  balance: any;
  subTitle?: string;
  icon?: string;
  fullSize?: boolean;
  handleClick?: any;
  loading?: any;
  buttonId?: string;
}

const GradientCard: FC<CardProps> = ({
  title,
  subTitle,
  balance,
  icon,
  fullSize,
  handleClick,
  loading,
  buttonId,
}) => {
  const [viewBalance, setViewBalance] = useState(true);

  return (
    <BalanceCard width="250px" height="156px" data-testid="balance_card">
      <BalanceCardHeader>
        <BalanceCardHeaderTitle>
          <span style={{ display: "flex", fontSize: "100%" }}>{title}</span>
          {!viewBalance ? (
            <img
              src="/assets/icons/eye-outline.svg"
              alt=""
              onClick={() => setViewBalance(true)}
              className="hide"
            />
          ) : (
            <HideIcon onClick={() => setViewBalance(false)}>
              <img
                src="/assets/icons/eye-outline.svg"
                alt=""
                className="hide"
              />
              <span></span>
            </HideIcon>
          )}
        </BalanceCardHeaderTitle>
        <BalanceCardHeaderType>{subTitle}</BalanceCardHeaderType>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10%",
          }}
        >
          {loading ? (
            <DotLoader color="#ffffff" size={20} />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                {" "}
                {viewBalance ? (
                  <Balance data-testid={title}>
                    {numberWithCommaswithoutdecimals(balance)}
                  </Balance>
                ) : (
                  <Balance>*****</Balance>
                )}
              </div>
              <BiChevronsRight
                style={{ color: "#fff", fontSize: "30px", zIndex: "10000000" }}
                onClick={handleClick}
                data-testid={buttonId}
              />
            </div>
          )}
          {icon && (
            <img
              src={icon}
              alt="icon"
              style={{ width: "15%", height: "15%", borderRadius: "50%" }}
            />
          )}
        </div>
        <img
          src="/assets/images/Home/logo-light-s.svg"
          alt="bg-logo"
          className="bg-logo"
          style={{ opacity: "0.25" }}
        />
      </BalanceCardHeader>
    </BalanceCard>
  );
};

export default GradientCard;
