/* eslint-disable react/require-default-props */
import { FC } from "react";
import { DotLoader } from "react-spinners";
import { LinkFilledButton } from "./styles";

interface FilledButtonProps {
  text: string;
  link: string;
  handleClick?: any;
  bg?: string;
  download?: boolean;
}

const LinkButton: FC<FilledButtonProps> = ({
  text,
  handleClick,
  bg,
  link,
  download,
}) => (
  <LinkFilledButton
    to={link}
    bg={bg}
    onClick={handleClick && handleClick}
    download={download}
    data-testid="link_button"
  >
    {text}
  </LinkFilledButton>
);

export default LinkButton;
