import { toast } from "react-toastify";
import { Redirect } from "../Helpers/generals";
import { sending } from "../Redux/Reducers/formReducer";
import {
  handleMainnav,
  handleModal,
  handleSubnav,
} from "../Redux/Reducers/navReducer";
import {
  setKycStatusMessage,
  setToken,
  setUser,
} from "../Redux/Reducers/userReducer";
import { store } from "../Redux/app/store";
import api from "./baseURL";
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

export const sendCreateAcoountEmail = async (
  emailAddress: string,
  navigate: any
) => {
  store.dispatch(sending(true));
  const body = { emailAddress };
  await api
    .post(
      `${
        process.env.REACT_APP_API_URL ===
        "https://development.switchwallet.io/api/"
          ? "https://development.switchwallet.io/api/v2/auth/register/otp"
          : "https://testnet.switchwallet.io/api/v2/auth/register/otp"
      }`,
      body,
      {
        params: { "api-Version": 2 },
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      }
    )
    .then((res: any) => {
      const { data } = res;
      store.dispatch(sending(false));
      toast.success(data.message);
      navigate("/confirm-otp");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

// sending signup details

// https://stg-integrations.switchwallet.io/
export const signup = async (
  payload: {
    firstName: string;
    lastName: string;
    businessName: string;
    email: string;
    userOtp: string;
    password: string;
    confirmPassword: string;
  },
  navigate: any
) => {
  store.dispatch(sending(true));
  const loginPayload = {
    email: payload.email,
    password: payload.password,
  };
  const body = {
    firstName: payload.firstName,
    lastName: payload.lastName,
    emailAddress: payload.email,
    businessName: payload.businessName,
    otpCode: payload.userOtp,
    password: payload.password,
    confirmPassword: payload.confirmPassword,
  };
  const res = await api
    .post(
      `${
        process.env.REACT_APP_API_URL ===
        "https://development.switchwallet.io/api/"
          ? "https://development.switchwallet.io/api/v2/auth/register"
          : "https://testnet.switchwallet.io/api/v2/auth/register"
      }`,
      body,
      {
        params: { "api-Version": 2 },
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      }
    )
    .then((res: any) => {
      const { data } = res;
      toast.success(data?.message);
      localStorage.setItem("JWT", JSON.stringify(data?.data.token));
      store.dispatch(setToken(data?.data?.token));
    })
    .then(() => {
      login(loginPayload, navigate);
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const login = async (
  payload: {
    email: string;
    password: string;
  },
  navigate: any
) => {
  store.dispatch(sending(true));
  await api
    .post("v2/auth/admin/login", payload, {
      params: { "api-Version": 2 },
      headers: {
        "Content-Type": "application/json-patch+json; ver=2.0",
        "Access-Control-Allow-Origin": "*",
        mode: "no-cors",
      },
    })
    .then((res: any) => {
      const { data } = res;
      store.dispatch(sending(false));
      toast.success(res?.data?.message);
      store.dispatch(setUser(data));
      navigate("/");
    })
    .catch((err: any) => {
      if (err?.response.status === 400 && err?.response?.data.kyC_Status > 0) {
        // toast.error(err?.response?.data?.message);
        store.dispatch(setKycStatusMessage(err?.response?.data?.message));
        store.dispatch(handleModal(6));
        store.dispatch(sending(false));
      } else {
        if (err?.response) toast.error(err?.response?.data?.message);
        if (err?.message === "Network Error") toast.error(err?.message);
        store.dispatch(sending(false));
      }
    });
};

export const ResetUserPassword = async (
  email: string,
  navigate: any,
  page?: string
) => {
  store.dispatch(sending(true));
  const body = { email };
  await api
    .post("v2/auth/password-reset/otp", body, {
      params: { "api-Version": 2 },
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    })
    .then((res: any) => {
      localStorage.setItem("email", email);
      const { data } = res;
      store.dispatch(sending(false));
      toast.success(data?.message);
      page === "security"
        ? store.dispatch(handleModal(7))
        : navigate("/confirm-reset-otp");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const changePassword = async (
  payload: {
    email: any;
    userOtp: string;
    password: string;
    confirmPassword: string;
  },
  navigate: any,
  page?: string
) => {
  store.dispatch(sending(true));
  const body = {
    email: payload.email,
    otpCode: payload.userOtp,
    password: payload.password,
    confirmPassword: payload.confirmPassword,
  };
  const res = await api
    .post("v2/auth/password-reset", body, {
      params: { "api-Version": 2 },
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    })
    .then((res: any) => {
      const { data } = res;
      store.dispatch(sending(false));
      toast.success(data?.message);
      page === "security" ? store.dispatch(handleModal(0)) : navigate("/");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

// upload
export const onFileUpload = async (selectedFile: any, navigate: any) => {
  store.dispatch(sending(true));
  const formData = new FormData();
  // Update the formData object
  formData.append("Document", selectedFile);

  await api
    .post(`v1/merchant/Business-Document/Upload`, formData, {
      headers: {
        accept: "*/*",
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res: any) => {
      const { data } = res;
      store.dispatch(sending(false));
      toast.success(data.message);
      navigate("/settings");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};
export const onRegisterFileUpload = async (
  selectedFile: any,
  navigate: any,
  token: any
) => {
  store.dispatch(sending(true));
  const formData = new FormData();
  // Update the formData object
  formData.append("Document", selectedFile);

  await api
    .post(`v1/merchant/Business-Document/Upload`, formData, {
      headers: {
        accept: "*/*",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res: any) => {
      const { data } = res;
      store.dispatch(sending(false));
      toast.success(data.message);
      navigate("/success");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const uploadProfileImage = async (selectedFile: any, navigate: any) => {
  store.dispatch(sending(true));
  const formData = new FormData();
  // Update the formData object
  formData.append("Document", selectedFile);

  await api
    .post(`v1/merchant/Upload/BusinessLogo`, formData, {
      headers: {
        accept: "*/*",
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res: any) => {
      const { data } = res;
      store.dispatch(sending(false));
      toast.success(data.message);
      navigate("/settings");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const getApiDetails = async (setApiDetails: any) => {
  await api
    .get("v1/merchant/IntegrationDetails/Get", {})
    .then((res: any) => setApiDetails(res?.data?.data));
};

export const Check2FAEnabled = async (email: string, navigate: any) => {
  store.dispatch(sending(true));
  localStorage.setItem("email", email);
  const params = { email };
  await api
    .get("v2/auth/is2FAEnabled", { params })
    .then((res: any) => {
      store.dispatch(sending(false));
      if (res?.data?.data?.isEnabled) navigate("/confirm-2FA-otp");
      else navigate("/login");
    })
    .catch((err: any) => {
      store.dispatch(sending(false));
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const LoggedInCheck2FAEnabled = async (email: string, navigate: any) => {
  const params = { email };
  await api
    .get("v2/auth/is2FAEnabled", { params })
    .then((res: any) => {
      if (res?.data?.data?.isEnabled === true) {
        return res?.data?.data;
      }
      if (res?.data?.data?.isEnabled === false) {
        navigate("/settings");
        if (store?.getState()?.user?.currentRoles?.includes("SuperAdmin")) {
          store.dispatch(handleMainnav(2));
          store.dispatch(handleSubnav(2));
        } else {
          store.dispatch(handleMainnav(1));
          store.dispatch(handleSubnav(1));
        }
        toast.info("PLease Enable 2FA Authentication to Proceed");
      }
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};

export const Confirm2FAOTP = async (
  email: string,
  passCode: string,
  navigate: any
) => {
  store.dispatch(sending(true));
  const params = { passCode, email };
  await api
    .post("v2/auth/Verify/2FA", null, { params })
    .then((res: any) => {
      store.dispatch(sending(false));
      if (res?.data?.data) navigate("/login");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const initializeKYCVerification = async (email: string) => {
  const params = { email };
  store.dispatch(sending(true));
  await api
    .post("v2/merchant/InitializeKYCVerification", null, { params })
    .then((res) => {
      if (res?.data?.data) {
        Redirect(res?.data?.data);
      } else {
        store.dispatch(sending(false));
      }
    })
    .catch((err: any) => {
      store.dispatch(sending(false));
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
    });
};
