import { removeDuplicates } from "../Helpers/generals";
import { sortByDay, sortByMonths, sortByYear } from "./sorting";
// this function return the total balance chart for each month
export const getTotalBalanceSeries = (
  data: any,
  GroupByPeriod: any,
  currencies: any
) => {
  const removedDuplicated = removeDuplicates(currencies, "label");
  const myBalanaceChatSeries = removedDuplicated?.map((currency: any) => {
    return {
      name: currency?.label,
      data:
        GroupByPeriod && GroupByPeriod == 1
          ? sortByDay(data, currency?.label)
          : GroupByPeriod == 2
          ? sortByMonths(data, currency?.label)
          : GroupByPeriod == 3
          ? sortByYear(data, currency?.label)
          : sortByMonths(data, currency?.label),
    };
  });
  return myBalanaceChatSeries;
};

export const getTotalBalanceSeriesByNetwork = (
  data: any,
  GroupByPeriod: any,
  networks: any
) => {
  const removedDuplicated = removeDuplicates(networks, "label");
  const myBalanaceChatSeries = removedDuplicated?.map((networks: any) => {
    return {
      name: networks?.label,
      data:
        GroupByPeriod && GroupByPeriod == 1
          ? sortByDay(data, networks?.label)
          : GroupByPeriod == 2
          ? sortByMonths(data, networks?.label)
          : GroupByPeriod == 3
          ? sortByYear(data, networks?.label)
          : sortByMonths(data, networks?.label),
    };
  });
  return myBalanaceChatSeries;
};

// analytics
export const getWalletGeneratedSeries = (data: any, GroupByPeriod: any) => {
  const walletGeneratedChatSeries = [
    {
      data:
        GroupByPeriod && GroupByPeriod == 1
          ? sortByDay(data)
          : GroupByPeriod == 2
          ? sortByMonths(data)
          : GroupByPeriod == 3
          ? sortByYear(data)
          : sortByMonths(data),
    },
  ];
  return walletGeneratedChatSeries;
};

export const getDailyReportSeries = () => {
  const dailyReportSeries = [
    {
      data: [
        2, 42, 67, 34, 12, 56, 34, 67, 54, 13, 98, 324, 67, 34, 67, 23, 67, 43,
        14, 56, 23, 56, 878, 34, 67, 23, 67, 46, 12, 78, 24,
      ],
    },
  ];
  return dailyReportSeries;
};

export const getWeeklyReportSeries = () => {
  const weeklyReportSeries = [{ data: [42, 52, 67, 13] }];
  return weeklyReportSeries;
};

export const getMonthlyReportSeries = () => {
  const monthlyReportSeries = [
    {
      data: [12, 565, 24, 66, 22, 767, 324, 56, 67, 12, 67, 34],
    },
  ];
  return monthlyReportSeries;
};

export const getYearlyReportSeries = () => {
  const yearReportSeries = [{ data: [233, 215, 254, 654, 124, 564, 465, 254] }];
  return yearReportSeries;
};
