import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { unRemittedCols } from "../../utils/tablecols";
import { getUnRemittanceRows } from "../../utils/TableRows/RemittanceRows";
import UnremittedFilter from "../Forms/UnremittedFilter";
import CustomTable from "../Reusables/Tables/CustomTable";
import { useParams } from "react-router-dom";
import { getUnRemittanceTransactions } from "./../../Apis/AnalyticsApiCalls/walletApis";
import SqaureFilledbutton from "../Reusables/Buttons/SqaureFilledButton";
import { handleModal } from "../../Redux/Reducers/navReducer";
import RemitModal from "../Modals/RemitModal";

const UnRemittanceTable = () => {
  const {
    StartTimeStamp,
    EndTimeStamp,
    unRemittedCurrency,
    unRemittedNetworkChain,
    unRemittedStatus,
    unRemittedTransactionHash,
    unRemittedWalletAddress,
    unRemittedPage,
    unRemittedPageSize,
    unRemittedStartTimeStamp,
    unRemittedEndTimeStamp,
  } = useAppSelector((state) => state.form);
  const [showAddress, setShowAddress] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const { loading } = useAppSelector((state) => state.form);
  const { unRemittanceTransactions, gettingUnRemittanceTransactions } =
    useAppSelector((state) => state.wallet);
  const { gettingUnRemittanceDetails } = useAppSelector(
    (state) => state.analytics
  );
  const dispatch = useAppDispatch();
  const userIdParams = useParams();

  const unRemittancedata = {
    columns: unRemittedCols,
    rows: getUnRemittanceRows(
      unRemittanceTransactions,
      showAddress,
      setShowAddress,
      selectedRow,
      setSelectedRow,
      loading,
      gettingUnRemittanceDetails,
    ),
  };
  useEffect(() => {
    const params = {
      Currency: unRemittedCurrency,
      NetworkChain: unRemittedNetworkChain,
      WalletAddress: unRemittedWalletAddress,
      Page: unRemittedPage,
      PageSize: unRemittedPageSize,
      StartTimeStamp: StartTimeStamp && moment(unRemittedStartTimeStamp).unix(),
      EndTimeStamp: EndTimeStamp && moment(unRemittedEndTimeStamp).unix(),
      MerchanId: userIdParams?.id,
    };
    getUnRemittanceTransactions( params);
  }, [
    unRemittedCurrency,
    unRemittedNetworkChain,
    unRemittedStatus,
    unRemittedTransactionHash,
    unRemittedWalletAddress,
    unRemittedPage,
    unRemittedPageSize,
    unRemittedStartTimeStamp,
    unRemittedEndTimeStamp,
  ]);
  return (
    <>
      <CustomTable
        data={unRemittancedata}
        pageCount={unRemittanceTransactions?.totalPages}
        currentPage={unRemittanceTransactions?.currentPage}
        loading={gettingUnRemittanceTransactions}
        page="unRemittedPage"
        title="Unremitted Transactions"
        searchOptions={<UnremittedFilter />}
        noRecordText="Unremitted Records not available"
        filter={
          <SqaureFilledbutton
            text="Bulk Remit"
            handleClick={() => dispatch(handleModal(5))}
          />
        }
      />
      <RemitModal />
    </>
  );
};

export default UnRemittanceTable;
