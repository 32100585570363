import React from "react";
import { RouteContainer } from "../../../components/Generals/RouteStyles";
import { FlexContainer } from "./../../../components/Generals/RouteStyles";
import DailyReportChart from "./../../../components/Charts/DailyReportChart";
import WeeklyReportChart from "../../../components/Charts/WeeklyReportChart";
import MonthlyReportChart from "./../../../components/Charts/MonthlyReportChart";
import YearlyReportChart from "./../../../components/Charts/YearlyReportChart";
import { LeftContent, RightContent } from "../../../components/styles";

const index = () => {
  return (
    <RouteContainer>
      <FlexContainer>
        <LeftContent>
          <DailyReportChart />
        </LeftContent>
        <RightContent>
          <WeeklyReportChart />
        </RightContent>
      </FlexContainer>
      <FlexContainer>
        <LeftContent>
          <MonthlyReportChart />
        </LeftContent>
        <RightContent>
          <YearlyReportChart />
        </RightContent>
      </FlexContainer>
    </RouteContainer>
  );
};

export default index;
