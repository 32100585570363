import React, { useEffect } from "react";
import { RouteContainer } from "../../../components/Generals/RouteStyles";
import MainNav from "../../../components/NavBars/MainNav";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { handleMainnav } from "../../../Redux/Reducers/navReducer";
import AccessControl from "./AccessControl";
import CreateAccount from "./CreateAccount";
import Notifications from "./Notifications";
import { useNavigate } from "react-router-dom";
import { LoggedInCheck2FAEnabled } from "../../../Apis/accountProcesing";

const ThirdParty = () => {
  const { currentMainBodyNav } = useAppSelector((state) => state.nav);
  const dispatch = useAppDispatch();
  const currentOperator = JSON.parse(
    localStorage.getItem("currentOperator") || "{}"
  );
  const navigate = useNavigate();

  useEffect(() => {
    LoggedInCheck2FAEnabled(currentOperator?.email, navigate);
  }, []);

  const thirdpartySubnav = [
    {
      navId: 1,
      navTitle: "Access Control",
    },
    {
      navId: 2,
      navTitle: "Create Third Party",
    },
    {
      navId: 3,
      navTitle: "Notifications",
    },
  ];

  return (
    <>
      <MainNav listItem={thirdpartySubnav} />
      <RouteContainer>
        {currentMainBodyNav === 1 ? (
          <AccessControl />
        ) : currentMainBodyNav === 2 ? (
          <CreateAccount />
        ) : (
          <Notifications />
        )}
      </RouteContainer>
    </>
  );
};

export default ThirdParty;
