import React from "react";
import { DatePick, InputContainer, Label } from "../Generals/LoginStyles";
import { getFormDetails } from "../../Redux/Reducers/formReducer";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import styled from "styled-components";
import { media } from "../../respnsive";
import {
  getWorkerFormDetails,
  selectWorker,
} from "../../Redux/Reducers/WorkerReducer";

const SearchbarContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  // flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
  // overflow-x: auto;
  padding-right: 20px;

  ${media.tablet} {
    flex-wrap: wrap;
  }
`;
const Trackerfilter = () => {
  const dispatch = useAppDispatch();
  const { startDate, endDate } = useAppSelector(selectWorker);

  const handleDateChange = (date: any, name: string) => {
    dispatch(getWorkerFormDetails({ name, value: date }));
  };

  return (
    <SearchbarContainer>
      <InputContainer style={{ width: "auto" }}>
        <DatePick
          placeholderText="Start Date"
          name="startDate"
          dateFormat="dd/MM/yyyy"
          onChange={(date: Date) => handleDateChange(date, "startDate")}
          onSelect={(date: Date) => handleDateChange(date, "startDate")}
          selected={startDate}
        />
      </InputContainer>
      <InputContainer style={{ width: "auto" }}>
        <DatePick
          placeholderText="End Date"
          name="endDate"
          dateFormat="dd/MM/yyyy"
          onChange={(date: Date) => handleDateChange(date, "endDate")}
          onSelect={(date: Date) => handleDateChange(date, "endDate")}
          selected={endDate}
        />
      </InputContainer>
    </SearchbarContainer>
  );
};

export default Trackerfilter;
