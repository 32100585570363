import { store } from "../../Redux/app/store";
import {
  setGettingIssuesReport,
  setIssuesReport,
} from "../../Redux/Reducers/WorkerReducer";
import api from "../baseURL";

export const getIssuesReport = async (from?: any, to?: any) => {
  const params: any = {
    from,
    to,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null || "") {
      delete params[key];
    }
  });
  store.dispatch(setGettingIssuesReport(true));
  await api
    .get("v1/issueTracker/getIssueReport", {
      params,
    })
    .then((res: any) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setIssuesReport(data));
        store.dispatch(setGettingIssuesReport(false));
      }
    })
    .catch(() => {
      store.dispatch(setGettingIssuesReport(false));
    });
};
