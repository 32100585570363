/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export interface userState {
  currentUser: any;
  currentRoles: string[];
  token: string;
  is2FAEnabled: boolean;
  QrObject: any;
  depositAddress: string;
  isActive: boolean;
  isIdle: boolean;
  kycStatusMessage: string;
  activatingUser: boolean;
  deActivatingUser: boolean;
  pausingUser: boolean;
  currentMerchant: string | null;
  currentMerchantId: string | null;
}

const initialState: userState = {
  currentUser: null,
  currentRoles: [],
  token: "",
  is2FAEnabled: false,
  QrObject: null,
  depositAddress: "",
  isActive: false,
  isIdle: false,
  kycStatusMessage: "",
  activatingUser: false,
  deActivatingUser: false,
  pausingUser: false,
  currentMerchant: null,
  currentMerchantId: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      if (
        action?.payload?.roles?.includes("Admin") ||
        action?.payload?.roles?.includes("SuperAdmin")
      ) {
        localStorage.setItem(
          "currentOperator",
          JSON.stringify(action?.payload?.data)
        );
        localStorage.setItem(
          "operatorRoles",
          JSON.stringify(action?.payload?.roles)
        );
        state.currentUser = action?.payload?.data;
        state.currentRoles = action?.payload?.roles;
      } else {
        toast.error("This is not an admin account");
      }
    },
    restoreUser: (state, action) => {
      state.currentUser = action.payload.token ? action.payload : null;
    },
    restoreRoles: (state, action) => {
      state.currentRoles = action.payload;
    },

    Logout: (state) => {
      state.currentUser = null;
      localStorage.clear();
    },

    setToken: (state, action) => {
      state.token = action.payload.token;
    },
    setKycStatusMessage: (state, action) => {
      state.kycStatusMessage = action.payload;
    },
    setIs2FAEnabled: (state, action) => {
      state.is2FAEnabled = action.payload.isEnabled;
    },
    setQrObject: (state, action) => {
      state.QrObject = action.payload;
    },
    setDepositAddress: (state, action) => {
      state.depositAddress = action.payload;
    },
    setActivatingUser: (state, action) => {
      state.activatingUser = action.payload;
    },
    setDeactivatingUser: (state, action) => {
      state.deActivatingUser = action.payload;
    },
    setPausingUser: (state, action) => {
      state.pausingUser = action.payload;
    },
    setCurrentMerchant: (state, action) => {
      state.currentMerchant = action.payload;
    },
    setCurrentMerchantID: (state, action) => {
      state.currentMerchant = action.payload;
    },
  },
});

export const {
  setUser,
  Logout,
  restoreUser,
  restoreRoles,
  setToken,
  setIs2FAEnabled,
  setQrObject,
  setDepositAddress,
  setKycStatusMessage,
  setActivatingUser,
  setDeactivatingUser,
  setPausingUser,
  setCurrentMerchant,
  setCurrentMerchantID,
} = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default userSlice.reducer;
