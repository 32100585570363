import { RouteContainer } from "../../../../components/Generals/RouteStyles";
import { useAppSelector } from "../../../../Redux/app/hooks";
import NodesError from "./NodesError";
import TransactionError from "./TransactionsError";
import WorkersError from "./WorkersError";

const ErrorStatus = () => {
  const { currentMainBodyNav } = useAppSelector((state) => state.nav);

  return (
    <RouteContainer>
      {currentMainBodyNav === 1 ? (
        <TransactionError />
      ) : currentMainBodyNav === 2 ? (
        <WorkersError />
      ) : (
        <NodesError />
      )}
    </RouteContainer>
  );
};

export default ErrorStatus;
