/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from "../app/store";

export interface FormState {
  // create-account states
  email: string;

  // otp
  otp: any[];
  userOtp: string;

  // signup
  firstName: string;
  lastName: string;
  businessName: string;
  password: string;
  confirmPassword: string;
  emailAddress: string;
  error: string;
  loading: boolean;

  // login
  registeredEmail: string;
  registeredPassword: string;

  // transactions filteration
  Currency: number | null;
  NetworkChain: number | null;
  Status: number | null;
  TransactionHash: string | null;
  WalletAddress: string | null;
  Page: number | undefined;
  WalletPage: number | undefined;
  TransactionPage: number | undefined;
  RemittancePage: number | undefined;
  PageSize: number | undefined;
  StartTimeStamp: Date | null;
  EndTimeStamp: Date | null;
  GroupByPeriod: number | undefined;
  TransactionType: number | null;
  filterMode: Boolean;

  // unremitted filters
  unRemittedCurrency: number | null;
  unRemittedNetworkChain: number | null;
  unRemittedStatus: number | null;
  unRemittedTransactionHash: string | undefined;
  unRemittedWalletAddress: string | undefined;
  unRemittedPage: number | undefined;
  unRemittedPageSize: number | undefined;
  unRemittedStartTimeStamp: Date | null;
  unRemittedEndTimeStamp: Date | null;

  // apiwebhook states
  webHookUrl: string;
  publicKey: string;
  secretKey: string;

  // withdrawals state
  destinationAddress: string | undefined;
  withdrawAmount: number | undefined;
  withdrawCurrency: any;
  withdrawNetwork: any;

  // deposit state
  depositCurrency: any;
  depositNetwork: any;

  // node
  nodeName: string;
  nodeUrl: string;
  archiveNodeUrl: string;
  blockchainNetworkId: string;
  nodeProviderId: string;

  // notification
  MerchantId: string;
  MailBody: string;
  MailSubject: string;

  // remitance
  currencyId: string;

  // report
  transactionType: number | null | undefined;
  reportEndDate: any | null;
  reportStartDate: any | null;

  // errors
  from: any;
  to: any;

  // merchant status
  merchantStatus: string;

  // admin signup states
  adminFirstName: string;
  adminLastName: string;
  adminBusinessName: string;
  adminPassword: string;
  adminConfirmPassword: string;
  adminEmailAddress: string;
  adminRole: number;

  // rescan
  transactionHash: string;
  network: number | null;
  currency: number | null;
}

const initialState: FormState = {
  // create-account states
  email: "",

  // otp
  otp: new Array(6).fill(""),
  userOtp: "",

  // signup states
  firstName: "",
  lastName: "",
  businessName: "",
  password: "",
  confirmPassword: "",
  emailAddress: "",
  error: "",
  loading: false,

  // login states
  registeredEmail: "",
  registeredPassword: "",

  // transaction filteration
  Currency: null,
  NetworkChain: null,
  Status: null,
  TransactionType: null,
  TransactionHash: null,
  WalletAddress: null,
  Page: 1,
  WalletPage: 1,
  TransactionPage: 1,
  RemittancePage: 1,
  PageSize: 20,
  StartTimeStamp: null,
  EndTimeStamp: null,
  GroupByPeriod: undefined,
  filterMode: false,

  // unremitted filters
  unRemittedCurrency: null,
  unRemittedNetworkChain: null,
  unRemittedStatus: null,
  unRemittedTransactionHash: "",
  unRemittedWalletAddress: "",
  unRemittedPage: 1,
  unRemittedPageSize: 20,
  unRemittedStartTimeStamp: null,
  unRemittedEndTimeStamp: null,

  // apiwebhook states
  webHookUrl: "",
  publicKey: "",
  secretKey: "",

  // withdrawals state
  destinationAddress: "",
  withdrawAmount: undefined,
  withdrawCurrency: { label: "--", value: "" },
  withdrawNetwork: { label: "--", value: "" },

  // deposit state
  depositCurrency: { label: "--", value: "" },
  depositNetwork: { label: "--", value: "" },

  // node
  nodeName: "",
  nodeUrl: "",
  archiveNodeUrl: "",
  blockchainNetworkId: "",
  nodeProviderId: "",

  // notification
  MerchantId: "",
  MailSubject: "",
  MailBody: "",

  // remittance
  currencyId: "",

  // report
  transactionType: null,
  reportStartDate: new Date(),
  reportEndDate: new Date(),

  // errors
  from: new Date(),
  to: new Date(),

  // merchantStatus
  merchantStatus: "allmerchants",

  // admin signup states

  adminFirstName: "",
  adminLastName: "",
  adminBusinessName: "",
  adminPassword: "",
  adminConfirmPassword: "",
  adminEmailAddress: "",
  adminRole: 1,

  // rescanblock
  transactionHash: "",
  network: null,
  currency: null,
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    sending: (state, action) => {
      state.loading = action.payload;
      state.error = "";
    },
    filter: (state, action) => {
      state.filterMode = action.payload;
    },
    getFormDetails: (state, action) => ({
      ...state,
      [action.payload.name]: action.payload.value,
    }),

    setPage: (state, action) => ({
      ...state,
      [action.payload.page]: action.payload.value,
    }),

    errorMessage: (state, action) => {
      state.error = action.payload;
    },
    setOtp: (state, action) => {
      state.otp = action.payload;
    },
    compileOtp: (state, action) => {
      state.userOtp = action.payload;
    },
    clearForm: (state) => {
      state.email = "";
      state.otp = new Array(6).fill("");
      state.userOtp = "";
      state.firstName = "";
      state.lastName = "";
      state.businessName = "";
      state.password = "";
      state.confirmPassword = "";
      state.error = "";
      state.loading = false;
      state.registeredEmail = "";
      state.registeredPassword = "";
      state.nodeName = "";
      state.nodeUrl = "";
      state.archiveNodeUrl = "";
      state.blockchainNetworkId = "";
      state.nodeProviderId = "";
    },
    clearFilterForm: (state) => {
      state.StartTimeStamp = null;
      state.EndTimeStamp = null;
      state.GroupByPeriod = undefined;
    },
    clearWithdrawDeposit: (state) => {
      state.destinationAddress = "";
      state.withdrawAmount = undefined;
      state.withdrawCurrency = { label: "--", value: "--" };
      state.withdrawNetwork = { label: "--", value: "--" };
      state.depositCurrency = { label: "--", value: "--" };
      state.depositNetwork = { label: "--", value: "--" };
    },
    clearTableFilterForm: (state) => {
      state.Currency = null;
      state.NetworkChain = null;
      state.Status = null;
      state.TransactionHash = "";
      state.WalletAddress = "";
      state.StartTimeStamp = null;
      state.EndTimeStamp = null;
      state.TransactionType = null;
      state.unRemittedCurrency = null;
      state.unRemittedNetworkChain = null;
      state.unRemittedStatus = null;
      state.unRemittedTransactionHash = "";
      state.unRemittedWalletAddress = "";
      state.unRemittedPage = 1;
      state.unRemittedPageSize = 20;
      state.unRemittedStartTimeStamp = null;
      state.unRemittedEndTimeStamp = null;
    },
  },
});

export const {
  getFormDetails,
  errorMessage,
  sending,
  setOtp,
  compileOtp,
  clearForm,
  setPage,
  filter,
  clearFilterForm,
  clearTableFilterForm,
  clearWithdrawDeposit,
} = formSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectToggle = (state: RootState) => state.form;

export default formSlice.reducer;
