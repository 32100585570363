import React from "react";
import AreaChart from "./AreaChart";
import { getWeeklyReportSeries } from "./../../utils/chatSeries";
import { getWeeklyReportOptions } from "./../../utils/data";

const WeeklyReportChart = () => {
  return (
    <AreaChart
      series={getWeeklyReportSeries()}
      options={getWeeklyReportOptions()}
    />
  );
};

export default WeeklyReportChart;
