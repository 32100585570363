import styled from "styled-components";
import { media } from "../../../../respnsive";

export const HealtStatusContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 30px;
  width: 100%;

  ${media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${media.phone} {
    grid-template-columns: 1fr;
  }
`;
