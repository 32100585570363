import mem from "mem";
import api from "./baseURL";

const refreshTokenFn = async () => {
  const user = JSON.parse(localStorage.getItem("currentOpe") || "{}");
  const params = { refreshToken: user?.refreshToken };

  try {
    const response = await api.get("v2/auth/refreshToken", {
      params,
    });

    const { updatedUser } = response.data;

    if (!updatedUser?.token) {
      localStorage.clear();
    }
    localStorage.setItem("currentOperator", JSON.stringify(updatedUser));
    return updatedUser;
  } catch (error) {
    localStorage.clear();
  }
};

const maxAge = 3000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});
