export const recentTransactionsCols = [
  { label: "", field: "transactionType", sort: "asc" },
  { label: "From", field: "from", sort: "asc" },
  { label: "To", field: "to", sort: "asc" },
  { label: "Amount", field: "amount", sort: "asc" },
  { label: "Date", field: "date", sort: "asc" },
];

export const walletCols = [
  { label: "", field: "transactionType", sort: "asc" },
  { label: "Wallet Adreess", field: "address", sort: "asc", width: "100px" },
  { label: "Network", field: "network", sort: "asc", width: "100px" },
  { label: "Amount", field: "balance", sort: "asc" },
  { label: "Date", field: "date", sort: "asc", width: "100px" },
  { label: "Status", field: "status", sort: "asc", width: "100px" },
  {
    label: "TransactionHash",
    field: "transactionHash",
    sort: "asc",
    width: "100px",
  },
  // { label: "Callback", field: "callback", sort: "asc", width: "100px" },
];

export const unRemittedCols = [
  { label: "Wallet Adreess", field: "address", sort: "asc" },
  { label: "Network", field: "network", sort: "asc" },
  { label: "Amount", field: "balance", sort: "asc" },
  { label: "Action", field: "callback", sort: "asc" },
  { label: "", field: "view", sort: "asc" },
];

export const remittanceCols = [
  { label: "Wallet Adreess", field: "address", sort: "asc", width: "100px" },
  { label: "Network", field: "network", sort: "asc", width: "100px" },
  { label: "Amount", field: "balance", sort: "asc" },
  { label: "Date", field: "date", sort: "asc", width: "100px" },
  { label: "Status", field: "status", sort: "asc", width: "100px" },
  {
    label: "TransactionHash",
    field: "transactionHash",
    sort: "asc",
    width: "100px",
  },
  // { label: "Enquiry", field: "equiry", sort: "asc", width: "100px" },
];

export const TransactionHistoryCols = [
  { label: "Date", field: "date", sort: "asc" },
  { label: "Time", field: "time", sort: "asc" },
  { label: "Wallet Adreess", field: "address", sort: "asc" },
  { label: "Destination Address", field: "destintion", sort: "asc" },
  { label: "Amount", field: "amount", sort: "asc" },
  { label: "Network", field: "network", sort: "asc" },
  { label: "Status", field: "status", sort: "asc" },
];

export const UnRemittanceDetailsCol = [
  { label: "Wallet Adreess", field: "address", sort: "asc" },
  { label: "Network", field: "network", sort: "asc" },
  { label: "Currency", field: "currency", sort: "asc" },
  { label: "Transaction Type", field: "type", sort: "asc" },
  { label: "Amount", field: "amount", sort: "asc" },
  { label: "Gas Price", field: "gasPrice", sort: "asc" },
  { label: "Gas Limit", field: "gasLimit", sort: "asc" },
  { label: "Gas Used", field: "gasUsed", sort: "asc" },
  { label: "Date", field: "date", sort: "asc" },
  { label: "Time", field: "time", sort: "asc" },
  { label: "Transaction Hash", field: "transactionHash", sort: "asc" },
];

export const RemittanceAnalysisCols = [
  { label: "Network", field: "network", sort: "asc", width: "100px" },
  {
    label: "Highests Gas Fees",
    field: "highestGasFee",
    sort: "asc",
    width: "100px",
  },
  {
    label: "Lowests Gas Fees",
    field: "lowestGasFee",
    sort: "asc",
    width: "100px",
  },
  {
    label: "Average Gas Fees",
    field: "averageGasFee",
    sort: "asc",
    width: "100px",
  },
  { label: "Total Fees", field: "totalFees", sort: "asc", width: "100px" },
];

export const CurrencytrasactionAnalysis = [
  { label: "Network", field: "network", sort: "asc" },
  { label: "Currency", field: "currency", sort: "asc" },
  { label: "Volume", field: "volume", sort: "asc" },
  { label: "Count", field: "count", sort: "asc" },
];

export const walletBalancesCols = [
  { label: "Currency", field: "currency", sort: "asc" },
  { label: "Total", field: "total", sort: "asc" },
  { label: "Network", field: "network", sort: "asc" },
  { label: "Action", field: "deposit", sort: "asc" },
  { label: "", field: "withdraw", sort: "asc" },
];

export const merchantsDepositCols = [
  { label: "Merchant Name", field: "name", sort: "asc" },
  { label: "Email", field: "email", sort: "asc" },
  // { label: "Total Deposits", field: "total", sort: "asc" },
  { label: "", field: "view", sort: "asc" },
];
export const merchantsWithdrawalCols = [
  { label: "Merchant Name", field: "name", sort: "asc" },
  { label: "Email", field: "email", sort: "asc" },
  // { label: "Total Withdrawals", field: "total", sort: "asc" },
  { label: "", field: "view", sort: "asc" },
];
export const merchantsRemittanceCols = [
  { label: "Merchant Name", field: "name", sort: "asc" },
  { label: "Email", field: "email", sort: "asc" },
  // { label: "Total Remittances", field: "total", sort: "asc" },
  { label: "", field: "view", sort: "asc" },
];
export const merchantsUnremittedCols = [
  { label: "Merchant Name", field: "name", sort: "asc" },
  { label: "Email", field: "email", sort: "asc" },
  // { label: "Total Unremitted", field: "total", sort: "asc" },
  { label: "", field: "view", sort: "asc" },
];

export const accessControlCols = [
  { label: "Merchant Name", field: "name", sort: "asc" },
  { label: "Email", field: "email", sort: "asc" },
  { label: "KYB Status", field: "kybStatus", sort: "asc" },
  { label: "Access Status", field: "accessStatus", sort: "asc" },
  { label: "", field: "activate", sort: "asc" },
  { label: "", field: "deActivate", sort: "asc" },
  { label: "", field: "disable2FA", sort: "asc" },
  { label: "", field: "verify", sort: "asc" },
];
