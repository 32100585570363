import React, { useEffect } from "react";
import { RouteContainer } from "../../../components/Generals/RouteStyles";
import MainNav from "../../../components/NavBars/MainNav";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { handleMainnav } from "../../../Redux/Reducers/navReducer";
import TransactionReport from "./Transactions";
import MerchantReports from "./MerchantReport/index";
import GenerateReportModal from "../../../components/Modals/GenerateReportModal";
import { useNavigate } from "react-router-dom";
import { LoggedInCheck2FAEnabled } from "../../../Apis/accountProcesing";

const Report = () => {
  const { currentMainBodyNav } = useAppSelector((state) => state.nav);
  const currentOperator = JSON.parse(
    localStorage.getItem("currentOperator") || "{}"
  );
  const navigate = useNavigate();

  const ReportSubnav = [
    {
      navId: 1,
      navTitle: "All Reports",
    },
    {
      navId: 2,
      navTitle: "Generate reports",
    },
  ];

  useEffect(() => {
    LoggedInCheck2FAEnabled(currentOperator?.email, navigate);
  }, []);

  return (
    <>
      <MainNav listItem={ReportSubnav} />
      <RouteContainer>
        {currentMainBodyNav === 1 ? <TransactionReport /> : <MerchantReports />}
        <GenerateReportModal />
      </RouteContainer>
    </>
  );
};

export default Report;
