import {
  AddNodeProvider,
  EditNodeProvider,
} from "../../../../Apis/PrivatesApis/settings/Node";
import {
  Form,
  FormContainer,
  InputContainer,
  Label,
  Input,
} from "../../../../components/Generals/LoginStyles";
import { RouteContainer } from "../../../../components/Generals/RouteStyles";
import Filledbutton from "../../../../components/Reusables/Buttons/Filledbutton";
import {
  errorMessage,
  getFormDetails,
} from "../../../../Redux/Reducers/formReducer";
import { useAppDispatch, useAppSelector } from "./../../../../Redux/app/hooks";

const EditNode = () => {
  const dispatch = useAppDispatch();
  const {
    nodeName,
    nodeUrl,
    archiveNodeUrl,
    nodeProviderId,
    blockchainNetworkId,
    loading,
    error,
  } = useAppSelector((state) => state.form);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleEditNode = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (
      !nodeName &&
      !nodeUrl &&
      !archiveNodeUrl &&
      !blockchainNetworkId &&
      !nodeProviderId
    ) {
      dispatch(errorMessage("Fields cannot be empty"));
    } else {
      EditNodeProvider(
        nodeProviderId,
        blockchainNetworkId,
        nodeName,
        nodeUrl,
        archiveNodeUrl,
      );
    }
  };

  return (
    <RouteContainer>
      <FormContainer>
        <Form customWidth="80%" style={{ flexWrap: "wrap" }}>
          <InputContainer>
            <Label>Node Provider ID</Label>
            <Input
              placeholder="Id"
              type="text"
              value={nodeProviderId}
              name="nodeProviderId"
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Label>Node Name</Label>
            <Input
              placeholder="Name"
              type="text"
              value={nodeName}
              name="nodeName"
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Label>Node Url</Label>
            <Input
              placeholder="Url"
              type="text"
              value={nodeUrl}
              name="nodeUrl"
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Label>Archive Node Url</Label>
            <Input
              placeholder="Url"
              type="text"
              value={archiveNodeUrl}
              name="archiveNodeUrl"
              onChange={handleChange}
            />
          </InputContainer>
          <InputContainer>
            <Label>Blockchain Network Id</Label>
            <Input
              placeholder="Id"
              type="text"
              value={blockchainNetworkId}
              name="blockchainNetworkId"
              onChange={handleChange}
            />
          </InputContainer>
          <Filledbutton
            text="Edit Node"
            handleClick={handleEditNode}
            loading={loading}
          />
          <div className="text-red">{error}</div>
        </Form>
      </FormContainer>
    </RouteContainer>
  );
};

export default EditNode;
