import { useAppSelector } from "../../Redux/app/hooks";
import { UnRemittanceDetailsCol } from "../../utils/tablecols";
import CustomTable from "../Reusables/Tables/CustomTable";
import { useState, useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import SqaureFilledbutton from "../Reusables/Buttons/SqaureFilledButton";
import { getUnRemittanceDetailsRows } from "../../utils/TableRows/RemittanceRows";

const UnRemittedDetailsTable = () => {
  const { unRemittanceDetails, gettingUnRemittanceDetails } = useAppSelector(
    (state) => state.analytics
  );
  const Ref = useRef(null);
  const rows = getUnRemittanceDetailsRows(unRemittanceDetails);

  const data = {
    columns: UnRemittanceDetailsCol,
    rows,
  };

  return (
    <>
      <CustomTable
        data={data}
        loading={gettingUnRemittanceDetails}
        title="Unremitted Transaction Details"
        tableId="unremitted-transaction-details"
        tableRef={Ref}
        filter={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            <DownloadTableExcel
              filename="unremitted-transaction-details"
              sheet="unremitted-details"
              currentTableRef={Ref?.current}
            >
              <SqaureFilledbutton
                text="Export CSV"
                icon="/assets/icons/Table/generate.svg"
                handleClick={(e: any) => e.preventDefault()}
              />
            </DownloadTableExcel>
          </div>
        }
        noRecordText="No details for this unremitted transaction"
      />
    </>
  );
};

export default UnRemittedDetailsTable;
