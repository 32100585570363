import {
  setAllKybStatus,
  setGettingKYBStatus,
  setVerifyingMerchant,
} from "../../../Redux/Reducers/reportReducer";
import api from "../../baseURL";
import { toast } from "react-toastify";
import { sending } from "../../../Redux/Reducers/formReducer";
import { store } from "../../../Redux/app/store";

export const getAllKybStatus = async (merchantId?: string) => {
  const params: any = {
    merchantId,
  };
  store.dispatch(setGettingKYBStatus(true));
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null || "") {
      delete params[key];
    }
  });
  await api
    .get("v2/Admin/KycStatus", { params })
    .then((res) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setAllKybStatus(data?.records));
        store.dispatch(setGettingKYBStatus(false));
      }
    })
    .catch((err) => {
      store.dispatch(setGettingKYBStatus(false));
    });
};

export const verifyKybStatus = async (merchantId?: string, state?: boolean) => {
  const params: any = {
    merchantId,
  };
  store.dispatch(sending(true));
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null || "") {
      delete params[key];
    }
  });
  await api
    .get("v2/Admin/Activation-Kyc-Status", { params })
    .then((res) => {
      const { data } = res;
      toast.success(data?.message || "Successful");
      store.dispatch(sending(false));
      store.dispatch(setVerifyingMerchant(!state));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
      store.dispatch(setVerifyingMerchant(!state));
    });
};
