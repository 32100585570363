export const getDailyReportOptions = () => {
  const dailyReport = {
    legend: {
      horizontalAlign: "center",
      fontSize: "10px",
    },
    title: {
      text: "Daily Usage",
      align: "left",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#2042B8"],
    stroke: { width: 2, curve: "smooth" },
    xaxis: {
      title: {
        // text: 'commit in year',
        // style: { fontSize: 20, color: '#857666' },
      },
      labels: {
        style: {
          colors: [],
          fontSize: "12px",
        },
      },
      categories: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ],
    },
    yaxis: {
      // labels: {
      //   formatter: function (value: any) {
      //     let val: string | number = Math.abs(value);
      //     if (val >= 10 ** 3 && val < 10 ** 6) {
      //       val = (val / 1000).toFixed(0) + " K";
      //     } else if (val >= 10 ** 6) {
      //       val = (val / 1000000).toFixed(0) + " M";
      //     } else {
      //       val = val;
      //     }
      //     return val;
      //   },
      // },
    },
  };
  return dailyReport;
};

export const getMonthlyReportOptions = () => {
  const dailyReport = {
    legend: {
      horizontalAlign: "center",
      fontSize: "10px",
    },
    title: {
      text: "Monthly Usage",
      align: "left",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#7B61FF"],
    stroke: { width: 2, curve: "smooth" },
    xaxis: {
      title: {
        // text: 'commit in year',
        // style: { fontSize: 20, color: '#857666' },
      },
      labels: {
        style: {
          colors: [],
          fontSize: "12px",
        },
      },
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "may",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      // labels: {
      //   formatter: function (value: any) {
      //     let val: string | number = Math.abs(value);
      //     if (val >= 10 ** 3 && val < 10 ** 6) {
      //       val = (val / 1000).toFixed(0) + " K";
      //     } else if (val >= 10 ** 6) {
      //       val = (val / 1000000).toFixed(0) + " M";
      //     } else {
      //       val = val;
      //     }
      //     return val;
      //   },
      // },
    },
  };
  return dailyReport;
};

export const getWeeklyReportOptions = () => {
  const dailyReport = {
    legend: {
      horizontalAlign: "center",
      fontSize: "10px",
    },
    title: {
      text: "Weekly Usage",
      align: "left",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#038cfd"],
    stroke: { width: 2, curve: "smooth" },
    xaxis: {
      title: {
        // text: 'commit in year',
        // style: { fontSize: 20, color: '#857666' },
      },
      labels: {
        style: {
          colors: [],
          fontSize: "12px",
        },
      },
      categories: ["Week 1", "Week 2", "Week 3", "Week 4"],
    },
    yaxis: {
      // labels: {
      //   formatter: function (value: any) {
      //     let val: string | number = Math.abs(value);
      //     if (val >= 10 ** 3 && val < 10 ** 6) {
      //       val = (val / 1000).toFixed(0) + " K";
      //     } else if (val >= 10 ** 6) {
      //       val = (val / 1000000).toFixed(0) + " M";
      //     } else {
      //       val = val;
      //     }
      //     return val;
      //   },
      // },
    },
  };
  return dailyReport;
};
export const getYearlyReportOptions = () => {
  const dailyReport = {
    legend: {
      horizontalAlign: "center",
      fontSize: "10px",
    },
    title: {
      text: "Yearly Usage",
      align: "left",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#FF6600"],
    stroke: { width: 2, curve: "smooth" },
    xaxis: {
      title: {
        // text: 'commit in year',
        // style: { fontSize: 20, color: '#857666' },
      },
      labels: {
        style: {
          colors: [],
          fontSize: "12px",
        },
      },
      categories: [
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
      ],
    },
    yaxis: {
      // labels: {
      //   formatter: function (value: any) {
      //     let val: string | number = Math.abs(value);
      //     if (val >= 10 ** 3 && val < 10 ** 6) {
      //       val = (val / 1000).toFixed(0) + " K";
      //     } else if (val >= 10 ** 6) {
      //       val = (val / 1000000).toFixed(0) + " M";
      //     } else {
      //       val = val;
      //     }
      //     return val;
      //   },
      // },
    },
  };
  return dailyReport;
};
