/* eslint-disable react/require-default-props */
import React, { FC } from "react";
import { BsToggleOff } from "react-icons/bs";
import styled from "styled-components";
import { useAppDispatch } from "../../../Redux/app/hooks";
import { handleModal } from "../../../Redux/Reducers/navReducer";
import { setSelectedNodeDetails } from "../../../Redux/Reducers/settingsReducer";
import { media } from "../../../respnsive";
import { theme } from "../../../theme";
import SqaureFilledbutton from "../Buttons/SqaureFilledButton";
import Loader from "./../Tables/Loader";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500.59px;
  height: auto;
  background: ${theme.colors.bgWhite};
  box-shadow: 0px 21px 23px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  z-index: 50;
  padding: 20px;
  justify-content: center;

  ${media.phone} {
    width: 100%;
    padding: 10px;
  }
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const Title = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 33px;
  color: ${theme.colors.bgBlack};
  flex: 0.3;

  ${media.tablet} {
    font-size: 14px;
    line-height: 22px;
  }
`;
const Desc = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: -0.02em;
  flex: 0.7;

  ${media.tablet} {
    font-size: 10px;
    line-height: 19px;
    letter-spacing: -0.02em;
  }

  svg {
    font-size: 28px;
    cursor: pointer;
  }
`;

interface NodeCardProps {
  url: string;
  hash: string;
  status: boolean;
  network: number;
  chainId: number;
  handleClick?: any;
  handleAltClick?: any;
  loading?: boolean;
  allDetails?: any;
}

const NodeCard: FC<NodeCardProps> = ({
  url,
  hash,
  status,
  network,
  chainId,
  handleClick,
  handleAltClick,
  loading,
  allDetails,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Container data-testid="node_card">
      <Details>
        <Title>Node Url:</Title>
        <Desc> {url}</Desc>
      </Details>
      <Details>
        <Title>Node Name:</Title>
        <Desc> {hash}</Desc>
      </Details>
      <Details>
        <Title>Network:</Title>
        <Desc> {network}</Desc>
      </Details>
      <Details>
        <Title>Chain ID:</Title>
        <Desc> {chainId}</Desc>
      </Details>
      <Details>
        <Title>Status:</Title>
        <Desc className={status ? "text-green" : "text-red"}>
          <span>{status ? "Active" : "InActive"}</span>
          {!status && !loading && (
            <BsToggleOff
              title="Activate Node"
              onClick={handleClick}
              data-testid="node_button"
            />
          )}
          {loading && <Loader size={10} />}
        </Desc>
        <div
          style={{
            display: "flex",
            gap: "10px",
            // width: "20%",
            // alignSelf: "flex-end",
          }}
        >
          <SqaureFilledbutton
            text="Delete"
            loading={loading}
            handleClick={handleAltClick}
            data-testid="node_button"
          />
          <SqaureFilledbutton
            text="Edit"
            loading={loading}
            handleClick={() => {
              dispatch(handleModal(6));
              dispatch(setSelectedNodeDetails(allDetails));
            }}
            data-testid="node_button"
          />
        </div>
      </Details>
    </Container>
  );
};

export default NodeCard;
