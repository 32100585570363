import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { walletCols } from "../../utils/tablecols";
import { getRows } from "../../utils/TableRows/transactions";
import CustomTable from "../Reusables/Tables/CustomTable";
import { useParams } from "react-router-dom";
import { getWalletsTransactions } from "./../../Apis/PrivatesApis/TransactionsApis";
import Trackerfilter from "../Forms/Trackerfilter";

const WithdrawalTrackerTable = () => {
  const [showAddress, setShowAddress] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const { loading } = useAppSelector((state) => state.form);
  const dispatch = useAppDispatch();
  const userIdParams = useParams();
  const { walletsTransactions, gettingWalletsTransactions } = useAppSelector(
    (state) => state.wallet
  );
  const {
    Currency,
    NetworkChain,
    Status,
    TransactionHash,
    WalletAddress,
    TransactionPage,
    StartTimeStamp,
    EndTimeStamp,
    TransactionType,
  } = useAppSelector((state) => state.form);

  const tableRef = useRef();
  const data = {
    columns: walletCols,
    rows: getRows(
      walletsTransactions,
      showAddress,
      setShowAddress,
      selectedRow,
      setSelectedRow,
      loading,
      dispatch
    ),
  };

  useEffect(() => {
    const params = {
      Currency,
      NetworkChain,
      Status,
      TransactionHash,
      WalletAddress,
      Page: TransactionPage,
      PageSize: 30,
      StartTimeStamp: StartTimeStamp && moment(StartTimeStamp).unix(),
      EndTimeStamp: EndTimeStamp && moment(EndTimeStamp).unix(),
      TransactionType: 2,
      merchantId: userIdParams?.id,
    };
    getWalletsTransactions( params);
  }, [
    TransactionPage,
    Currency,
    NetworkChain,
    Status,
    TransactionHash,
    WalletAddress,
    TransactionPage,
    StartTimeStamp,
    EndTimeStamp,
    TransactionType,
  ]);

  return (
    <>
      <CustomTable
        data={data}
        pageCount={walletsTransactions?.totalPages}
        currentPage={walletsTransactions?.currentPage}
        loading={gettingWalletsTransactions}
        tableId="transactions-table"
        tableRef={tableRef}
        page={"TransactionPage"}
        searchOptions={
          <Trackerfilter />
        }
        title="Withdrawals Transactions"
        // filter={
        //   <div
        //     style={{
        //       display: "flex",
        //       justifyContent: "flex-start",
        //       gap: "10px",
        //     }}
        //   >
        //     <DownloadTableExcel
        //       filename="transaction-table"
        //       sheet="Transactions"
        //       currentTableRef={tableRef.current}
        //     >
        //       <SqaureFilledbutton
        //         text="Export CSV"
        //         icon="/assets/icons/Table/generate.svg"
        //       />
        //     </DownloadTableExcel>
        //   </div>
        // }
        noRecordText="Transaction history not available"
      />
    </>
  );
};

export default WithdrawalTrackerTable;
