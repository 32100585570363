import { useState, useEffect } from "react";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { useAppDispatch } from "./../../../../Redux/app/hooks";
import { getMerchantTransactionReportdRows } from "../../../../utils/TableRows/reports";
import MerchantsTables from "./../../../../components/Tables/MerchantsTable";
import { RouteContainer } from "../../../../components/Generals/RouteStyles";
import { getReportsById } from "./../../../../Apis/PrivatesApis/Reports";
import { merchantsReportTransactionCols } from "./../../../../utils/TableCols/reports";
import { DirectionButton } from "./../../../../components/Overview/styles";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { handleMainnav } from "../../../../Redux/Reducers/navReducer";

const SingleMerchant = () => {
  const [selectedRow, setSelectedRow] = useState(0);
  const { gettingAllReports, allReports, deletingReport } = useAppSelector(
    (state) => state.reports
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userParams = useParams();
  const merchantRows = getMerchantTransactionReportdRows(
    allReports,
    deletingReport,
    selectedRow,
    setSelectedRow,
  );

  useEffect(() => {
    getReportsById(userParams?.id);
  }, []);

  return (
    <RouteContainer>
      <MerchantsTables
        merchantRows={merchantRows}
        title={
          <DirectionButton
            onClick={() => {
              dispatch(handleMainnav(2));
              navigate("/reports");
            }}
          >
            <BsArrowLeft />
          </DirectionButton>
        }
        merchantCol={merchantsReportTransactionCols}
        loading={gettingAllReports}
        noRecordText="No record for selected merchant"
      />
    </RouteContainer>
  );
};

export default SingleMerchant;
