import moment from "moment";
import { toast } from "react-toastify";

export function shortenAddress(address: string) {
  return `${address.slice(0, 6)}...${address.slice(length - 1, 6)}`;
}

export async function copyHandle(value: string) {
  try {
    await navigator.clipboard.writeText(value);
    toast.success("Copied");
  } catch (error) {
    toast.error("Failed");
  }
}

export function shortenQrcode(address: string) {
  if (address) return `${address.slice(0, 20)}...`;
}

export const validateemail = (value: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );

export const validatePassword = (psw: string) => {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(psw);
};

export const numberWithCommas = (n: any, c: any, d: any, t: any) => {
  c = isNaN((c = Math.abs(c))) ? 2 : c;
  d = d === undefined ? "." : d;
  t = t === undefined ? "," : t;
  let s = n < 0 ? "-" : "";
  let i: any = String(Number((n = Math.abs(Number(n) || 0).toFixed(c))));
  let j;
  j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
};

export const toTimestamp = (strDate: any) => {
  const dt = Date.parse(strDate);
  return dt;
};

export function numberWithCommaswithoutdecimals(x: any) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return 0;
}

export function getUnique(arr: any, comp: any) {
  // store the comparison  values in array
  const unique = arr
    ?.map((e: any) => e[comp])

    // store the indexes of the unique objects
    ?.map((e: any, i: any, final: any) => final.indexOf(e) === i && i)

    // eliminate the false indexes & return unique objects
    ?.filter((e: any) => arr[e])
    ?.map((e: any) => arr[e]);

  return unique;
}

export const removeDuplicates = (array: any, key: any) => {
  return array?.reduce((acc: any, element: any) => {
    if (!acc?.find((temp: any) => temp[key] === element[key])) {
      acc.push(element);
    }
    return acc;
  }, []);
};
export function countdown(interval = 5000) {
  return new Promise(async (resolve, reject) => {
    let intervalLoop: any = null;

    function countTimer() {
      if (!localStorage.endTime) {
        localStorage.endTime = +new Date() + interval;
      }

      let newDate: any = new Date();
      let remaining = localStorage.endTime - newDate;
      if (remaining >= 0) {
        let currentTime = Math.floor(remaining / 1000);
      } else {
        clearInterval(intervalLoop);
        resolve(true);
      }
    }

    intervalLoop = setInterval(countTimer, 1000);
  });
}

export const Redirect = (url: string) => {
  window.location.href = url;
};

export function roundton(num: any, n?: any) {
  const result = Number(Number(num).toFixed(n)).toLocaleString("en-US", {
    maximumFractionDigits: n || 5,
  });

  return result !== (0 || "0") ? result : (0.0).toFixed(5);
}

export function removeDuplicates2(arr: any) {
  return arr.filter((item: any, index: any) => arr.indexOf(item) === index);
}
