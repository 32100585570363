import { FC } from "react";
import styled from "styled-components";
import { media } from "../../../respnsive";
import { theme } from "../../../theme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  min-height: 150px;
  background: ${theme.colors.bgWhite};
  box-shadow: 0px 21px 23px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  //   z-index: 50;
  padding: 20px;
  justify-content: center;

  ${media.phone} {
    width: 100%;
    padding: 10px;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 33px;
  color: ${theme.colors.bgBlack};

  ${media.tablet} {
    font-size: 14px;
    line-height: 22px;
  }
`;
const Desc = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.02em;

  ${media.tablet} {
    font-size: 10px;
    line-height: 19px;
    letter-spacing: -0.02em;
  }

  svg {
    font-size: 28px;
    cursor: pointer;
  }
`;

interface ReportI {
  title: string | number;
  desc: string | number;
}

const TrackerReportCard: FC<ReportI> = ({ title, desc }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Desc>{desc}</Desc>
    </Container>
  );
};

export default TrackerReportCard;
