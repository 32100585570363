import React from "react";
import { getMonthlyReportSeries } from "../../utils/chatSeries";
import { getMonthlyReportOptions } from "../../utils/data";
import AreaChart from "./AreaChart";

const MonthlyReportChart = () => {
  return (
    <AreaChart
      series={getMonthlyReportSeries()}
      options={getMonthlyReportOptions()}
    />
  );
};

export default MonthlyReportChart;
