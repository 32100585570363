/* eslint-disable no-unused-vars */
import React from "react";
import { Title } from "../../components/styles";
import Filledbutton from "../../components/Reusables/Buttons/Filledbutton";
import {
  AuthPageBanner,
  AuthPageContainer,
  ExtraInput,
  FormLink,
  Form,
  FormContainer,
  Input,
  InputContainer,
  Label,
  RememberMe,
  FormLeftContainer,
} from "../../components/Generals/LoginStyles";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { useNavigate } from "react-router-dom";
import { errorMessage, getFormDetails } from "../../Redux/Reducers/formReducer";
import { login } from "../../Apis/accountProcesing";

const Login = () => {
  const { error, loading, registeredPassword } = useAppSelector(
    (state) => state.form
  );
  const registeredEmail = localStorage.getItem("email") || "";
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!registeredPassword) {
      dispatch(errorMessage("Please fill in correct  password"));
    } else {
      dispatch(errorMessage(""));
      login({ email: registeredEmail, password: registeredPassword }, navigate);
    }
  };
  return (
    <AuthPageContainer>
      <FormLeftContainer href="https://switchwallet.io">
        <AuthPageBanner src="/assets/images/Forms/Login-banner.png" />
      </FormLeftContainer>
      <FormContainer>
        <Form>
          <div style={{ marginBottom: "30px" }}>
            <Title>Log in</Title>
          </div>
          {/* <InputContainer>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              placeholder="Enter Email"
              name="registeredEmail"
              onChange={handleChange}
              id="email"
            />
          </InputContainer> */}
          <InputContainer>
            <Label htmlFor="password">Password</Label>
            <Input
              type="password"
              placeholder="Enter password"
              name="registeredPassword"
              onChange={handleChange}
              id="password"
            />
          </InputContainer>
          {/* <ExtraInput>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <input type="checkbox" name="" id="" />
              <RememberMe>Remember me</RememberMe>
            </div>
            <FormLink to="/reset-password">Forgot password?</FormLink>
          </ExtraInput> */}
          <Filledbutton
            text="Log in"
            handleClick={handleClick}
            loading={loading}
          />
          {/* <FormFooter>
            <FooterText>Do not have an account? </FooterText>
            <FormLink to="/create-account">Sign up</FormLink>
          </FormFooter> */}
          <div className="text-red" style={{ marginTop: "10px" }}>
            {error}
          </div>
        </Form>
      </FormContainer>
    </AuthPageContainer>
  );
};

export default Login;
