import React, { useEffect } from "react";
import { getAllMerchants } from "../../../../Apis/PrivatesApis/overviewApis";
import { RouteContainer } from "../../../../components/Generals/RouteStyles";
import MerchantsTables from "../../../../components/Tables/MerchantsTable";
import { useAppDispatch, useAppSelector } from "../../../../Redux/app/hooks";
import { merchantsReportCols } from "../../../../utils/TableCols/reports";
import { getMerchantReportRows } from "./../../../../utils/TableRows/reports";
import SqaureFilledbutton from "../../../../components/Reusables/Buttons/SqaureFilledButton";
import { handleModal } from "../../../../Redux/Reducers/navReducer";
import { setSelectedMerchant } from "../../../../Redux/Reducers/reportReducer";
import MerchantFilter from "../../../../components/Forms/MerchantFilter";

const MerchantReports = () => {
  const dispatch = useAppDispatch();
  const { gettingAllMerchants, allMerchants } = useAppSelector(
    (state) => state.overview
  );
  const { Page, PageSize, merchantStatus } = useAppSelector(
    (state) => state.form
  );
  const merchantRows = getMerchantReportRows(allMerchants?.records);

  useEffect(() => {
    getAllMerchants(Page, PageSize, merchantStatus);
  }, [Page, PageSize, merchantStatus]);

  return (
    <RouteContainer>
      <MerchantsTables
        merchantRows={merchantRows}
        merchantCol={merchantsReportCols}
        title="Merchant Reports"
        loading={gettingAllMerchants}
        pageCount={allMerchants?.totalPages}
        currentPage={Page}
        filter={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            <MerchantFilter />
            <SqaureFilledbutton
              text="Generate All"
              // icon="/assets/icons/Table/generate.svg"
              handleClick={() => {
                dispatch(handleModal(2));
                dispatch(
                  setSelectedMerchant({
                    id: null,
                    name: "All Merchant",
                  })
                );
              }}
            />
          </div>
        }
      />
    </RouteContainer>
  );
};

export default MerchantReports;
