import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
/* eslint-disable no-param-reassign */

export interface reportState {
  gettingAllReports: boolean;
  allReports: any;
  generatingReport: boolean;
  deletingReport: boolean;
  selectedMerchantId: any;
  gettingAllKybStatus: boolean;
  allKybStatus: any;
  verifyingMerchant: boolean;
}

const initialState: reportState = {
  gettingAllReports: false,
  allReports: null,
  generatingReport: false,
  deletingReport: false,
  selectedMerchantId: null,
  gettingAllKybStatus: false,
  allKybStatus: null,
  verifyingMerchant: false,
};

export const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setGettingTotalReports: (state, action) => {
      state.gettingAllReports = action.payload;
    },
    setTotalReports: (state, action) => {
      state.allReports = action.payload;
    },
    setGeneratingReports: (state, action) => {
      state.generatingReport = action.payload;
    },
    setDeletingReport: (state, action) => {
      state.deletingReport = action.payload;
    },
    setSelectedMerchant: (state, action) => {
      state.selectedMerchantId = action.payload;
    },
    setGettingKYBStatus: (state, action) => {
      state.gettingAllKybStatus = action.payload;
    },
    setAllKybStatus: (state, action) => {
      state.allKybStatus = action.payload;
    },
    setVerifyingMerchant: (state, action) => {
      state.verifyingMerchant = action.payload;
    },
  },
});

export const {
  setGettingTotalReports,
  setTotalReports,
  setGeneratingReports,
  setDeletingReport,
  setSelectedMerchant,
  setGettingKYBStatus,
  setAllKybStatus,
  setVerifyingMerchant
} = reportSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectReports = (state: RootState) => state.reports;

export default reportSlice.reducer;
