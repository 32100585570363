import React from "react";
import { enableNode } from "../../../../Apis/PrivatesApis/settings/Node";
import {
  Form,
  Input,
  InputContainer,
  FormContainer,
} from "../../../../components/Generals/LoginStyles";
import { RouteContainer } from "../../../../components/Generals/RouteStyles";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { Label } from "../../../../components/Generals/LoginStyles";
import Filledbutton from "../../../../components/Reusables/Buttons/Filledbutton";
import { useAppDispatch } from "../../../../Redux/app/hooks";
import {
  errorMessage,
  getFormDetails,
} from "../../../../Redux/Reducers/formReducer";

const NodeSwitching = () => {
  const { nodeProviderId, blockchainNetworkId, error } = useAppSelector(
    (state) => state.form
  );
  const { switchingNode } = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (nodeProviderId && blockchainNetworkId) {
      dispatch(errorMessage(""));
      enableNode(nodeProviderId, blockchainNetworkId);
    } else {
      dispatch(errorMessage("Please fill all fields"));
    }
  };

  return (
    <RouteContainer>
      <FormContainer>
        <Form customWidth="70%" style={{ flexWrap: "wrap" }}>
          <InputContainer>
            <Label>Node Provider ID</Label>
            <Input
              value={nodeProviderId}
              type="text"
              onChange={handleChange}
              name="nodeProviderId"
            />
          </InputContainer>
          <InputContainer>
            <Label>Blockchain Network ID</Label>
            <Input
              value={blockchainNetworkId}
              type="text"
              onChange={handleChange}
              name="blockchainNetworkId"
            />
          </InputContainer>
          <Filledbutton
            text="Switch"
            handleClick={handleSwitch}
            loading={switchingNode}
          />
          <div className="text-red">{error}</div>
        </Form>
      </FormContainer>
    </RouteContainer>
  );
};

export default NodeSwitching;
