import { toast } from "react-toastify";
import { store } from "../../Redux/app/store";
import { filter } from "../../Redux/Reducers/formReducer";
import {
  setGettingWalletsTransactions,
  setReScanningBlock,
  setWalletsTransactions,
} from "../../Redux/Reducers/WalletReducer";
import api from "../baseURL";
export const getWalletsTransactions = async (parameters: any) => {
  const params = parameters;
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null || "") {
      delete params[key];
    }
  });
  store.dispatch(setGettingWalletsTransactions(true));
  await api
    .get("v2/Admin/merchant-transaction-record", {
      params,
    })
    .then((res: any) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setWalletsTransactions(data));
        store.dispatch(setGettingWalletsTransactions(false));
        store.dispatch(filter(false));
        // store.dispatch(clearForm());
      }
    })
    .catch(() => {
      store.dispatch(setGettingWalletsTransactions(false));
      store.dispatch(filter(false));
      // store.dispatch(clearForm());
    });
};

export const reScanBlock = async (
  transactionHash: string,
  networkChain: number | null,
  currency: number | null
) => {
  store.dispatch(setReScanningBlock(true));
  const params: any = {
    transactionHash,
    currency,
    networkChain,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null || "") {
      delete params[key];
    }
  });
  await api
    .get("v2/analytics/transaction/GetAndRescanBlockByTransactionHash", {
      params,
    })
    .then(() => {
      toast.success("Successfully");
      store.dispatch(setReScanningBlock(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setReScanningBlock(false));
    });
};
