/* eslint-disable react/require-default-props */
import { FC } from "react";
import Chart from "react-apexcharts";
import { useAppSelector } from "../../Redux/app/hooks";
import { ChartTitle } from "../Generals/RouteStyles";
import Loader from "../Reusables/Tables/Loader";

interface AreaChartProps {
  series: any;
  options: object;
  title?: string;
  loading?: any;
  filterOptions?: any;
}

const AreaChart: FC<AreaChartProps> = ({
  series,
  options,
  title,
  loading,
  filterOptions,
}) => {
  const { filterMode } = useAppSelector((state) => state.form);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "#fff",
        padding: "15px 5px",
        borderRadius: "5px",
        position: "relative",
      }}
    >
      {filterOptions && filterOptions}
      {title && <ChartTitle>{title}</ChartTitle>}
      {loading || filterMode ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {series && (
            <Chart
              type="area"
              width="100%"
              height="100%"
              series={series}
              options={options}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AreaChart;
