/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface WorkerState {
  gettingIssuesReport: Boolean;
  issuesReport: any;

  //   worker state
  startDate: Date;
  endDate: Date;
}

const initialState: WorkerState = {
  gettingIssuesReport: false,
  issuesReport: null,

  //   worker state
  startDate: new Date(),
  endDate: new Date(),
};

export const WorkerSlice = createSlice({
  name: "Worker",
  initialState,
  reducers: {
    setGettingIssuesReport: (state, action) => {
      state.gettingIssuesReport = action.payload;
    },
    setIssuesReport: (state, action) => {
      state.issuesReport = action?.payload;
    },
    getWorkerFormDetails: (state, action) => ({
      ...state,
      [action.payload.name]: action.payload.value,
    }),
  },
});

export const { setGettingIssuesReport, setIssuesReport, getWorkerFormDetails } =
  WorkerSlice.actions;

export const selectWorker = (state: RootState) => state.workers;

export default WorkerSlice.reducer;
