import React from "react";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { RouteContainer } from "../../../../components/Generals/RouteStyles";
import CreateAdmin from "./CreateAdmin";
import Security from "./Security";

const AccountSettings = () => {
  const { currentMainBodyNav } = useAppSelector((state) => state.nav);

  return (
    <RouteContainer>
      {currentMainBodyNav === 1 ? <CreateAdmin /> : <Security />}
    </RouteContainer>
  );
};

export default AccountSettings;
