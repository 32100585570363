export const merchantsReportRemittanceCols = [
  { label: "Merchant Name", field: "name", sort: "asc" },
  { label: "Email", field: "email", sort: "asc" },
  { label: "Wallet Address", field: "address", sort: "asc" },
  { label: "Total Remittance", field: "total", sort: "asc" },
  { label: "", field: "export", sort: "asc" },
];
export const merchantsReportCols = [
  { label: "Merchant Name", field: "name", sort: "asc" },
  { label: "Email", field: "email", sort: "asc" },
  // { label: "", field: "view", sort: "asc" },
  { label: "", field: "generate", sort: "asc" },
];

export const merchantsReportTransactionCols = [
  { label: "Merchant Name", field: "name", sort: "asc" },
  // { label: "Report Type", field: "type", sort: "asc" },
  { label: "Start Date", field: "startDate", sort: "asc" },
  { label: "End Date", field: "endDate", sort: "asc" },
  { label: "", field: "export", sort: "asc" },
  { label: "", field: "delete", sort: "asc" },
];

export const merchantsKYBStatusCols = [
  { label: "Merchant Name", field: "name", sort: "asc" },
  // { label: "Email", field: "email", sort: "asc" },
  { label: "Status", field: "status", sort: "asc" },
  // { label: "Last Updated", field: "lastUpdated", sort: "asc" },
  { label: "", field: "verify", sort: "asc" },
];
