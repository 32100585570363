/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface walletState {
  gettingWalletsTransactions: Boolean;
  walletsTransactions: any;
  gettingRemittanceTransactions: Boolean;
  remittanceTransactions: any;
  gettingUnRemittanceTransactions: Boolean;
  reScanningBlock: boolean;
  unRemittanceTransactions: any;
  depositCurrencyBalance: any;
  depositNetworkBalance: any;
}

const initialState: walletState = {
  gettingWalletsTransactions: false,
  walletsTransactions: null,
  gettingRemittanceTransactions: false,
  remittanceTransactions: null,
  gettingUnRemittanceTransactions: false,
  reScanningBlock: false,
  unRemittanceTransactions: null,
  depositCurrencyBalance: null,
  depositNetworkBalance: null,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setGettingWalletsTransactions: (state, action) => {
      state.gettingWalletsTransactions = action.payload;
    },
    setReScanningBlock: (state, action) => {
      state.reScanningBlock = action.payload;
    },
    setWalletsTransactions: (state, action) => {
      state.walletsTransactions = action?.payload;
    },
    setGettingRemittanceTransactions: (state, action) => {
      state.gettingRemittanceTransactions = action.payload;
    },
    setRemittnceTransactions: (state, action) => {
      state.remittanceTransactions = action?.payload;
    },
    setGettingUnRemittanceTransactions: (state, action) => {
      state.gettingUnRemittanceTransactions = action.payload;
    },
    setUnRemittnceTransactions: (state, action) => {
      state.unRemittanceTransactions = action?.payload;
    },
    setDepositCurrencyBalance: (state, action) => {
      state.depositCurrencyBalance = action?.payload;
    },
    setDepositNetworkBalance: (state, action) => {
      state.depositNetworkBalance = action?.payload;
    },
  },
});

export const {
  setGettingWalletsTransactions,
  setWalletsTransactions,
  setGettingRemittanceTransactions,
  setRemittnceTransactions,
  setGettingUnRemittanceTransactions,
  setUnRemittnceTransactions,
  setDepositCurrencyBalance,
  setDepositNetworkBalance,
  setReScanningBlock,
} = walletSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectToggle = (state: RootState) => state.wallet;

export default walletSlice.reducer;
