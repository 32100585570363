import moment from "moment";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { remittanceStatus } from "../../utils/filterSelect";
import { remittanceCols } from "../../utils/tablecols";
import SearchOptions from "../Forms/SearchOptions";
import CustomTable from "../Reusables/Tables/CustomTable";
import { getRemittanceRows } from "./../../utils/TableRows/RemittanceRows";
import { useParams } from "react-router-dom";
import { getRemittanceTransactions } from "../../Apis/AnalyticsApiCalls/walletApis";

const RemittanceTable = () => {
  const { remittanceTransactions, gettingRemittanceTransactions } =
    useAppSelector((state) => state.wallet);
  const {
    Currency,
    NetworkChain,
    Status,
    TransactionHash,
    WalletAddress,
    RemittancePage,
    StartTimeStamp,
    EndTimeStamp,
    loading,
  } = useAppSelector((state) => state.form);
  const [showAddress, setShowAddress] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const dispatch = useAppDispatch();
  const userIdParams = useParams();

  const reimittancedata = {
    columns: remittanceCols,
    rows: getRemittanceRows(
      remittanceTransactions,
      showAddress,
      setShowAddress,
      selectedRow,
      setSelectedRow,
      loading,
    ),
  };

  useEffect(() => {
    const params = {
      Currency,
      NetworkChain,
      Status,
      TransactionHash,
      WalletAddress,
      Page: RemittancePage,
      PageSize: 20,
      StartTimeStamp: StartTimeStamp && moment(StartTimeStamp).unix(),
      EndTimeStamp: EndTimeStamp && moment(EndTimeStamp).unix(),
      merchanId: userIdParams?.id,
    };
    getRemittanceTransactions( params);
  }, [
    RemittancePage,
    Currency,
    NetworkChain,
    Status,
    TransactionHash,
    WalletAddress,
    RemittancePage,
    StartTimeStamp,
    EndTimeStamp,
  ]);
  return (
    <>
      <CustomTable
        data={reimittancedata}
        pageCount={remittanceTransactions?.totalPages}
        currentPage={remittanceTransactions?.currentPage}
        loading={gettingRemittanceTransactions}
        page="RemittancePage"
        title="Remittance Transactions"
        searchOptions={
          <SearchOptions
            recordDateRange={remittanceTransactions?.records}
            statusFilter={remittanceStatus}
          />
        }
        noRecordText="Remittance records not available"
      />
    </>
  );
};

export default RemittanceTable;
