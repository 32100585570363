import React, { useEffect } from "react";
import {
  LatestContainer,
  LatestMerchants,
  LatestTitle,
  MerchantEmail,
  MerchantName,
  RegisteredDate,
  Row,
} from "./style";
import { getAllMerchants } from "../../../Apis/PrivatesApis/overviewApis";
import { useAppSelector } from "./../../../Redux/app/hooks";
import Loader from "./../../../components/Reusables/Tables/Loader";
import moment from "moment";

const Latest = () => {
  const { gettingAllMerchants, allMerchants } = useAppSelector(
    (state) => state.overview
  );
  const { merchantStatus } = useAppSelector((state) => state.form);

  useEffect(() => {
    getAllMerchants(1, 5, merchantStatus);
  }, []);

  return (
    <LatestContainer>
      <LatestMerchants>
        <LatestTitle>Latest Merchants</LatestTitle>
        {gettingAllMerchants ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <Row style={{ borderBottom: "2px solid " }}>
              <MerchantName>Merchant Name</MerchantName>
              <MerchantEmail
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Merchant Email
              </MerchantEmail>
              <RegisteredDate
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Registered Date
              </RegisteredDate>
            </Row>
            {allMerchants?.records
              ?.slice(0, 5)
              ?.map((merchant: any, index: number) => (
                <Row key={index}>
                  <MerchantName
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    {merchant?.businessName}
                  </MerchantName>
                  <MerchantEmail>{merchant?.businessEmail}</MerchantEmail>
                  <RegisteredDate>
                    {moment(merchant?.createdAt).format(`MMM Do YYYY`)}
                  </RegisteredDate>
                </Row>
              ))}
          </>
        )}
      </LatestMerchants>
    </LatestContainer>
  );
};

export default Latest;
