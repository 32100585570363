/* eslint-disable react/jsx-no-useless-fragment */
import { Routes, Route } from "react-router-dom";
import Header from "../components/NavBars/Header";
import Sidebar from "../components/NavBars/Sidebar";
import PagenotFound from "../components/PagenotFound";
import ScrollToTop from "../components/ScrollToTop";
import {
  Settings,
  Overview,
  Reports,
  ThirdParty,
  Transactions,
  Usage,
  Workers,
} from "../pages/Private";
import { AppBody, MainDashBoard } from "./styles";
import DepositsTransaction from "./../pages/Private/Transactions/Transc/DepositsTransaction";
import WithdrawalsTransaction from "./../pages/Private/Transactions/Transc/WithdrawalsTransc";
import RemittanceTransc from "./../pages/Private/Transactions/Transc/RemittanceTransc";
import UnremittedTransc from "../pages/Private/Transactions/Transc/UnremittedTrans";
import SingleMerchant from "../pages/Private/Reports/MerchantReport/SingleMerchant";
import Remittances from "../pages/Remittances";
import { useAppSelector } from "../Redux/app/hooks";
import OtherSettings from "../pages/Private/OthersSettings";

const PrivateRoutes = () => {
  const { currentRoles } = useAppSelector((state) => state.user);

  return (
    <>
      <ScrollToTop />
      <MainDashBoard>
        <Sidebar />
        {/* common route components header & sidebar, this will show for every dashboard route */}
        <AppBody>
          <Header />
          <Routes>
            {/* dashboard routes */}
            <Route path="/" element={<Overview />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route
              path="/transactions/deposits/:id"
              element={<DepositsTransaction />}
            />
            <Route
              path="/transactions/withdrawals/:id"
              element={<WithdrawalsTransaction />}
            />
            <Route
              path="/transactions/remittances/:id"
              element={<RemittanceTransc />}
            />
            <Route
              path="/transactions/unremitted/:id"
              element={<UnremittedTransc />}
            />
            <Route path="/workers" element={<Workers />} />
            <Route path="/third-party" element={<ThirdParty />} />
            {/* <Route path="/remittances" element={<Remittances />} /> */}
            <Route path="/reports" element={<Reports />} />
            <Route path="/reports/:id" element={<SingleMerchant />} />
            <Route path="/usage" element={<Usage />} />
            {currentRoles?.includes("SuperAdmin") ? (
              <Route path="/settings" element={<Settings />} />
            ) : (
              <Route path="/settings" element={<OtherSettings />} />
            )}
            <Route path="*" element={<PagenotFound />} />
          </Routes>
        </AppBody>
      </MainDashBoard>
    </>
  );
};

export default PrivateRoutes;
