import React from "react";
import AreaChart from "./AreaChart";
import { getYearlyReportSeries } from "./../../utils/chatSeries";
import { getYearlyReportOptions } from "../../utils/data";

const YearlyReportChart = () => {
  return (
    <AreaChart
      series={getYearlyReportSeries()}
      options={getYearlyReportOptions()}
    />
  );
};

export default YearlyReportChart;
