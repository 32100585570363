export const calTransSummary = (data: any) => {
  const summedDuplicates = data?.records?.reduce((acc: any, cur: any) => {
    const item =
      acc.length > 0 &&
      acc.find(
        (el: any) =>
          el?.network === cur?.network && el?.currency === cur?.currency
      );
    if (item) {
      item.amount = item?.amount && Number(item?.amount) + Number(cur?.amount);
      item.count = item?.count + 1;
    } else {
      acc.push({
        network: cur?.network,
        amount: cur?.amount,
        currency: cur?.currency,
        count: 1,
      });
    }
    return acc;
  }, []);
  return summedDuplicates;
};

export const calUnremittedSummary = (data: any) => {
  const summedDuplicates = data?.records?.reduce((acc: any, cur: any) => {
    const item =
      acc.length > 0 &&
      acc.find(
        (el: any) =>
          el?.currency?.networkChain === cur?.currency?.networkChain &&
          el?.currency?.symbol === cur?.currency?.symbol
      );
    if (item) {
      item.balance =
        item?.balance && Number(item?.balance) + Number(cur?.balance);
      item.count = item?.count + 1;
    } else {
      acc.push({
        balance: cur?.balance,
        currency: {
          symbol: cur?.currency?.symbol,
          networkChain: cur?.currency?.networkChain,
        },
        count: 1,
      });
    }
    return acc;
  }, []);
  return summedDuplicates;
};

export const calRemittanceSummary = (data: any) => {
  const remittancePayment = data?.records?.map((item: any) => {
    return item.remittancePayment;
  });
  const gasPayment = data?.records?.map((item: any) => {
    return item.gasPayment;
  });


  const summedRemittaDuplicates = remittancePayment?.reduce(
    (acc: any, cur: any) => {
      const item =
        acc.length > 0 &&
        acc.find(
          (el: any) =>
            el?.networkChain === cur?.networkChain &&
            el?.currency === cur?.currency
        );

      if (item) {
        item.amount = Number(item?.amount) + Number(cur?.amount);
        item.fee = Number(item?.fee) + Number(cur?.fee);
      } else {
        acc.push({
          network: cur?.networkChain,
          amount: cur?.amount,
          currency: cur?.currency,
          fee: cur?.fee,
        });
      }
      return acc;
    },
    []
  );
  const summedGasDuplicates = gasPayment?.reduce((acc: any, cur: any) => {
    const item =
      acc.length > 0 &&
      acc.find(
        (el: any) =>
          el?.networkChain === cur?.networkChain &&
          el?.currency === cur?.currency
      );

    if (item) {
      item.amount = Number(item?.amount) + Number(cur?.amount);
      item.fee = Number(item?.fee) + Number(cur?.fee);
    } else {
      acc.push({
        network: cur?.networkChain,
        amount: cur?.amount,
        currency: cur?.currency,
        fee: cur?.fee,
      });
    }
    return acc;
  }, []);

  const completeRemitaFilter = summedRemittaDuplicates?.reduce(
    (acc: any, cur: any) => {
      const item =
        acc.length > 0 &&
        acc.find(
          (el: any) =>
            el?.network === cur?.network && el?.currency === cur?.currency
        );
      if (item) {
        item.amount = item?.amount
          ? Number(item?.amount) + Number(cur?.amount)
          : 0;
        item.fee = item?.fee && Number(item?.fee) + Number(cur?.fee);
      } else {
        acc.push({
          network: cur?.network,
          amount: cur?.amount,
          currency: cur?.currency,
          fee: cur?.fee,
        });
      }
      return acc;
    },
    []
  );

  const completeGasFilter = summedGasDuplicates?.reduce(
    (acc: any, cur: any) => {
      const item =
        acc.length > 0 &&
        acc.find(
          (el: any) =>
            el?.network === cur?.network && el?.currency === cur?.currency
        );
      if (item) {
        item.amount = item?.amount
          ? Number(item?.amount) + Number(cur?.amount)
          : 0;
        item.fee = item?.fee && Number(item?.fee) + Number(cur?.fee);
      } else {
        acc.push({
          network: cur?.network,
          amount: cur?.amount,
          currency: cur?.currency,
          fee: cur?.fee,
        });
      }
      return acc;
    },
    []
  );

  return { completeRemitaFilter, completeGasFilter };
};
