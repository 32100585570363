import { RouteContainer } from "../../../components/Generals/RouteStyles";
import { useAppSelector } from "../../../Redux/app/hooks";
import MainNav from "../../../components/NavBars/MainNav";
import AccountSettings from "./AccountSetting";

const OtherSettings = () => {
  const { currentSubnav } = useAppSelector((state) => state.nav);

  const AccountsettingsSubnav = [
    {
      navId: 1,
      navTitle: "Security",
    },
  ];

  return (
    <>
      {currentSubnav === 1 && <MainNav listItem={AccountsettingsSubnav} />}
      <RouteContainer>
        {currentSubnav === 1 && <AccountSettings />}
      </RouteContainer>
    </>
  );
};

export default OtherSettings;
