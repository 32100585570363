import React, { useEffect, useState } from "react";
import { getAllMerchants } from "../../../../Apis/PrivatesApis/overviewApis";
import { sendNotification } from "../../../../Apis/PrivatesApis/ThirdParty";
import {
  colourStyles,
  Form,
  FormContainer,
  Input,
  InputContainer,
  SelectBar,
  TextArea,
} from "../../../../components/Generals/LoginStyles";
import { useAppDispatch, useAppSelector } from "../../../../Redux/app/hooks";
import {
  errorMessage,
  getFormDetails,
} from "../../../../Redux/Reducers/formReducer";
import { Label } from "./../../../../components/Generals/LoginStyles";
import Filledbutton from "./../../../../components/Reusables/Buttons/Filledbutton";
import { getOverallMerchants } from "./../../../../Apis/PrivatesApis/overviewApis";

const Notifications = () => {
  const [merchant, setMerchants] = useState([{ label: "--", value: "--" }]);
  const { allMerchants } = useAppSelector((state) => state.overview);
  const [overallMerchants, setOverallMerchants] = useState<any>();
  const { MerchantId, MailSubject, MailBody, loading, error } = useAppSelector(
    (state) => state.form
  );
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getAllMerchants();
  }, []);

  useEffect(() => {
    getOverallMerchants(allMerchants?.totalRecords, setOverallMerchants);
  }, []);

  useEffect(() => {
    const filter = overallMerchants?.records?.map((merchant: any) => {
      return {
        label: merchant?.businessName,
        value: merchant?.id || "--",
      };
    });
    setMerchants(filter);
  }, [allMerchants, selectAll, overallMerchants]);

  const handleSelectChange = (option: any, name: string) => {
    if (option.value === "--") dispatch(getFormDetails({ name }));
    else dispatch(getFormDetails({ name, value: option.value }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleSendNotification = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!MailSubject) {
      dispatch(errorMessage("Please fill the subject field "));
    } else if (!MailBody) {
      dispatch(errorMessage("Mail body cannot be empty"));
    } else {
      sendNotification(MerchantId, MailSubject, MailBody);
    }
  };

  return (
    <FormContainer>
      <Form customWidth="80%" style={{ flexWrap: "wrap" }}>
        <InputContainer>
          {" "}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            <Label>Send to all merchants</Label>
            <input
              placeholder="Enter subject"
              type="checkbox"
              style={{ width: "20px", height: "20px" }}
              onChange={(e: any) => {
                setSelectAll(e.target.checked);
                dispatch(getFormDetails({ name: "MerchantId", value: "" }));
              }}
            />
          </div>
        </InputContainer>

        <InputContainer>
          <Label>Merchant Email Address</Label>
          <SelectBar
            options={merchant}
            styles={colourStyles}
            placeholder={selectAll ? "To all merchant" : "Select merchant"}
            isDisabled={selectAll}
            onChange={(data: any) => handleSelectChange(data, "MerchantId")}
          />
        </InputContainer>
        <InputContainer>
          <Label>Subject</Label>
          <Input
            placeholder="Enter subject"
            type="text"
            value={MailSubject}
            name="MailSubject"
            onChange={handleChange}
          />
        </InputContainer>
        <InputContainer>
          <Label>Text</Label>
          <TextArea
            placeholder="Type your message here"
            value={MailBody}
            name="MailBody"
            onChange={handleTextAreaChange}
          />
        </InputContainer>
        <Filledbutton
          text="Send"
          handleClick={handleSendNotification}
          loading={loading}
        />
        <div className="text-red">{error}</div>
      </Form>
    </FormContainer>
  );
};

export default Notifications;
