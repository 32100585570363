import React from "react";
import DepositTrackerTable from "../../../../components/Tables/DepositTrackertable";

const Deposit = () => {
  return (
    <div>
      <DepositTrackerTable />
    </div>
  );
};

export default Deposit;
