import React, { useEffect } from "react";
import MerchantsTables from "../../../../components/Tables/MerchantsTable";
import { merchantsRemittanceCols } from "../../../../utils/tablecols";
import { getMerchantRemittanceRows } from "../../../../utils/tableRows";
import { SectionContainer, SectionTitle } from "./styles";
import { useAppDispatch, useAppSelector } from "../../../../Redux/app/hooks";
import { getAllMerchants } from "../../../../Apis/PrivatesApis/overviewApis";
import MerchantFilter from "./../../../../components/Forms/MerchantFilter";

const Remittances = () => {
  const dispatch = useAppDispatch();
  const { gettingAllMerchants, allMerchants } = useAppSelector(
    (state) => state.overview
  );
  const { Page, PageSize, merchantStatus } = useAppSelector(
    (state) => state.form
  );
  const merchantRows = getMerchantRemittanceRows(allMerchants);

  useEffect(() => {
    getAllMerchants(Page, PageSize, merchantStatus);
  }, [Page, PageSize, merchantStatus]);

  return (
    <SectionContainer>
      <SectionTitle>Remittance</SectionTitle>
      <MerchantsTables
        merchantCol={merchantsRemittanceCols}
        merchantRows={merchantRows}
        title="Merchants Remittances"
        loading={gettingAllMerchants}
        pageCount={allMerchants?.totalPages}
        currentPage={Page}
        page="Page"
        filter={<MerchantFilter />}
      />
    </SectionContainer>
  );
};

export default Remittances;
