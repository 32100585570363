import LinkButton from "../components/Reusables/Buttons/LinkButton";
import { store } from "../Redux/app/store";
import { setCurrentMerchant } from "../Redux/Reducers/userReducer";

export const getMerchantDepositRows = (
  merchant: any,
  totalRecords: any,
) => {
  const result = merchant
    ? merchant?.records?.map((item: any) => {
        // const fetchTotal = getTotalRecords(dispatch, item?.id);
        return {
          name: item?.businessName || "N/A",
          email: item?.businessEmail || item?.businessSupportEmail || "N/A",
          country: item?.country || "N/A",
          address: item?.businessAddress || "N/A",
          total: 0,
          view: (
            <LinkButton
              link={`/transactions/deposits/${item?.id}`}
              text="Explore"
              handleClick={() => {
                const merchantDetails = {
                  name: item?.businessName,
                  id: item?.id,
                };
                store.dispatch(setCurrentMerchant(item?.businessName));
                localStorage.setItem(
                  "selectedMerchant",
                  JSON.stringify(merchantDetails)
                );
              }}
            />
          ),
        };
      })
    : [];
  return result;
};
export const getMerchantWithdrawalRows = (merchant: any) => {
  const result = merchant
    ? merchant?.records?.map((item: any) => {
        return {
          name: item?.businessName || "N/A",
          email: item?.businessEmail || item?.businessSupportEmail || "N/A",
          country: item?.country || "N/A",
          address: item?.businessAddress || "N/A",
          total: 0,
          view: (
            <LinkButton
              link={`/transactions/withdrawals/${item?.id}`}
              text="Explore"
              handleClick={() => {
                const merchantDetails = {
                  name: item?.businessName,
                  id: item?.id,
                };
                store.dispatch(setCurrentMerchant(item?.businessName));
                localStorage.setItem(
                  "selectedMerchant",
                  JSON.stringify(merchantDetails)
                );
              }}
            />
          ),
        };
      })
    : [];
  return result;
};
export const getMerchantRemittanceRows = (merchant: any) => {
  const result = merchant
    ? merchant?.records?.map((item: any) => {
        return {
          name: item?.businessName || "N/A",
          email: item?.businessEmail || item?.businessSupportEmail || "N/A",
          country: item?.country || "N/A",
          address: item?.businessAddress || "N/A",
          total: 0,
          view: (
            <LinkButton
              link={`/transactions/remittances/${item?.id}`}
              text="Explore"
              handleClick={() => {
                const merchantDetails = {
                  name: item?.businessName,
                  id: item?.id,
                };
                store.dispatch(setCurrentMerchant(item?.businessName));
                localStorage.setItem(
                  "selectedMerchant",
                  JSON.stringify(merchantDetails)
                );
              }}
            />
          ),
        };
      })
    : [];
  return result;
};
export const getMerchantUnremittedRows = (merchant: any) => {
  const result = merchant
    ? merchant?.records?.map((item: any) => {
        return {
          name: item?.businessName || "N/A",
          email: item?.businessEmail || item?.businessSupportEmail || "N/A",
          country: item?.country || "N/A",
          address: item?.businessAddress || "N/A",
          total: 0,
          view: (
            <LinkButton
              link={`/transactions/unremitted/${item?.id}`}
              text="Explore"
              handleClick={() => {
                const merchantDetails = {
                  name: item?.businessName,
                  id: item?.id,
                };
                store.dispatch(setCurrentMerchant(item?.businessName));
                localStorage.setItem(
                  "selectedMerchant",
                  JSON.stringify(merchantDetails)
                );
              }}
            />
          ),
        };
      })
    : [];
  return result;
};
