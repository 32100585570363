import { verifyKybStatus } from "../../Apis/PrivatesApis/settings/KYBStatus";
import {
  activateMerchant,
  deActivateMerchant,
  Diable2FA,
} from "../../Apis/PrivatesApis/ThirdParty";
import SqaureFilledbutton from "../../components/Reusables/Buttons/SqaureFilledButton";
import { store } from "../../Redux/app/store";

export const getAccessControlRow = (
  merchant: any,
  allKybStatus: any,
  state: any,
  disabling2FA: boolean
) => {
  const result = merchant
    ? merchant.map((item: any) => {
        const kybStatus = allKybStatus?.map((kyb: any) => {
          if (item?.businessName === kyb?.merchantName) {
            return kyb;
          }
          return null;
        });

        const kyb = kybStatus?.filter(function (element: any) {
          return element !== (undefined || null);
        });
        return {
          name: item?.businessName || "N/A",
          email: item?.businessEmail || item?.businessSupportEmail || "N/A",
          kybStatus: kyb?.length ? (
            <span
              className={`${
                kyb[0]?.status === "Initiated"
                  ? "text-red"
                  : kyb[0]?.status === "InProgess"
                  ? "text-yellow"
                  : kyb[0]?.status === "Approved"
                  ? "text-green"
                  : "text-red"
              }`}
            >
              {kyb[0]?.status}
            </span>
          ) : (
            "N/A"
          ),
          accessStatus: (
            <span
              className={`${item?.status === 1 ? "text-green" : "text-red"}`}
            >
              {item?.status === 1 ? "Activated" : "Deactivated"}
            </span>
          ),
          activate: (
            <SqaureFilledbutton
              text="Activate"
              loading={state?.activatingUser}
              handleClick={() => {
                activateMerchant(item?.id, item?.businessEmail);
              }}
              disabled={item?.status === 1 && true}
            />
          ),
          deActivate: (
            <SqaureFilledbutton
              text="Deactivate"
              loading={state.deActivatingUser}
              handleClick={() => {
                deActivateMerchant(item?.id, item?.businessEmail);
              }}
              disabled={item?.status !== 1 && true}
            />
          ),
          verify:
            kyb?.length && kyb[0]?.status !== "Approved" ? (
              <SqaureFilledbutton
                text="Verify KYB"
                handleClick={() => {
                  verifyKybStatus(kyb[0]?.merchantId, state?.verifyingMerchant);
                }}
                loading={state?.loading}
              />
            ) : (
              ""
            ),
          disable2FA: (
            <SqaureFilledbutton
              text="Disable 2FA"
              handleClick={() => {
                Diable2FA(item?.id);
              }}
              loading={disabling2FA}
            />
          ),
        };
      })
    : [];
  return result;
};
