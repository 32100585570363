import { DotLoader } from "react-spinners";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { Desc } from "../Generals/LoginStyles";
import { LeftContent, RightContent } from "../Generals/RouteStyles";
import QRCodeScan from "../Reusables/Others/QRCode";
import CustomModal from "./CustomModal";
import { copyHandle, shortenQrcode } from "./../../Helpers/generals";
import { Logout } from "../../Redux/Reducers/userReducer";
import { useNavigate } from "react-router-dom";
import { FlexBetween } from "../../pages/Private/Settings/AccountSetting/styles";

const QRCodeSetupModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { QrObject } = useAppSelector((state) => state.user);
  const { loading } = useAppSelector((state) => state.form);

  let base64Img = QrObject?.barcodeImageUrl;
  //   const base64result = base64Img?.replace("data:image/png;base64,", "");

  return (
    <CustomModal
      id={2}
      handleClose={() => {
        dispatch(Logout());
        navigate("/");
      }}
      content={
        <>
          {loading ? (
            <DotLoader color="#ff6600" size={20} />
          ) : (
            <FlexBetween
              style={{
                background: "#fff",
                flexDirection: "column",
                width: "40%",
                padding: "20px",
              }}
            >
              <Desc
                style={{
                  fontSize: "14px",
                }}
              >
                Scan the QR code on your Google Authenticator app, close the
                modal after scanning
              </Desc>
              <LeftContent>
                <QRCodeScan url={base64Img} />
              </LeftContent>
              <Desc
                style={{
                  marginBottom: "0",
                  marginTop: "20px",
                  fontSize: "14px",
                }}
              >
                Or copy the code below to your Google Authenticator app
              </Desc>
              <RightContent
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p>{shortenQrcode(QrObject?.setupCode)}</p>
                <img
                  src="/assets/icons/Table/copy-icon-blue.svg"
                  alt="copy"
                  onClick={() => copyHandle(QrObject?.setupCode)}
                  style={{ cursor: "pointer", width: "20px" }}
                />
              </RightContent>
            </FlexBetween>
          )}
          |
        </>
      }
    />
  );
};

export default QRCodeSetupModal;
