import { useIdleTimer } from "react-idle-timer";
import { memoizedRefreshToken } from "./Apis/refreshToken";
import "./App.css";
import { toTimestamp } from "./Helpers/generals";
import { useAppDispatch, useAppSelector } from "./Redux/app/hooks";
import { Logout } from "./Redux/Reducers/userReducer";
import Router from "./Router";

const App = () => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.user);

  const handleOnIdle = () => {
    if (currentUser) {
      if (currentUser?.expiresAtTimestamp > toTimestamp(new Date())) {
      } else {
        dispatch(Logout());
      }
    }
  };

  const handleOnActive = async () => {
    if (currentUser) {
      if (currentUser?.expiresAtTimestamp < toTimestamp(new Date())) {
        if (isIdle() === false) {
          await memoizedRefreshToken();
        } else {
          dispatch(Logout());
        }
      }
    }
  };

  const { isIdle } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
  });
  return (
    <div className="App">
      <Router />
    </div>
  );
};

export default App;
