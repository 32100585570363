import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./Redux/app/hooks";
import { restoreRoles, restoreUser } from "./Redux/Reducers/userReducer";
import AuthRoutes from "./Routes/AuthRoutes";
import PrivateRoutes from "./Routes/PrivateRoutes";

const Router = () => {
  const { currentUser } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("currentOperator") || "{}");
    const roles = JSON.parse(localStorage.getItem("operatorRoles") || "{}");

    if (user) {
      dispatch(restoreUser(user));
      dispatch(restoreRoles(roles));
    }
  }, []);

  return (
    <Routes>
      <Route
        path="/*"
        element={!currentUser ? <AuthRoutes /> : <PrivateRoutes />}
      />
    </Routes>
  );
};

export default Router;
