import { useEffect, useState } from "react";
import NodeCard from "../../../../components/Reusables/Cards/NodeCard";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { NodeStatusContainer } from "./styles";
import {
  deleteNode,
  enableNode,
  getAllNodes,
} from "../../../../Apis/PrivatesApis/settings/Node";
import { useAppDispatch } from "../../../../Redux/app/hooks";
import DeleteNodeModal from "../../../../components/Modals/DeleteNodeModal";
import { handleModal } from "../../../../Redux/Reducers/navReducer";
import EditNodeMOdal from "../../../../components/Modals/EditNodeModal";
import { setSelectedNodeDetails } from "../../../../Redux/Reducers/settingsReducer";

const NodeStatus = () => {
  const { allNodes, switchingNode } = useAppSelector((state) => state.settings);
  const [selectedNode, setSelectedNode] = useState<string>("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    getAllNodes(null);
  }, []);

  return (
    <NodeStatusContainer>
      {allNodes?.map((item: any) => (
        <>
          {item?.nodeProviders?.map((node: any, index: number) => (
            <NodeCard
              allDetails={node}
              url={node?.nodeUrl}
              hash={node?.name}
              status={node?.enabled}
              key={index}
              network={item?.network}
              chainId={item?.chainId}
              handleClick={() =>
                enableNode(node?.id, node?.blockChainNetworkId)
              }
              handleAltClick={() => {
                dispatch(handleModal(4));
                setSelectedNode(node?.id);
              }}
            />
          ))}
        </>
      ))}
      <DeleteNodeModal
        handleClick={() => deleteNode(selectedNode)}
        loading={switchingNode}
      />
      <EditNodeMOdal />
    </NodeStatusContainer>
  );
};

export default NodeStatus;
