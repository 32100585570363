import React, { useEffect } from "react";
import { merchantsDepositCols } from "../../../../utils/tablecols";
import { getMerchantDepositRows } from "../../../../utils/tableRows";
import MerchantsTables from "../../../../components/Tables/MerchantsTable";
import { useAppDispatch, useAppSelector } from "./../../../../Redux/app/hooks";
import {
  getAllMerchants,
  getTotalRecords,
} from "../../../../Apis/PrivatesApis/overviewApis";
import { setPage } from "../../../../Redux/Reducers/formReducer";
import MerchantFilter from "../../../../components/Forms/MerchantFilter";
import { SectionContainer, SectionTitle } from "./styles";

const Deposits = () => {
  const dispatch = useAppDispatch();
  const { gettingAllMerchants, allMerchants, totalRecords } = useAppSelector(
    (state) => state.overview
  );
  const { Page, PageSize, merchantStatus } = useAppSelector(
    (state) => state.form
  );
  const merchantRows = getMerchantDepositRows(
    allMerchants,
    totalRecords,
  );

  useEffect(() => {
    getTotalRecords();
    getAllMerchants(Page, PageSize, merchantStatus);
  }, [Page, PageSize, merchantStatus]);

  return (
    <SectionContainer>
      <SectionTitle>Deposits</SectionTitle>
      <MerchantsTables
        merchantCol={merchantsDepositCols}
        merchantRows={merchantRows}
        title={"Merchants Deposits"}
        loading={gettingAllMerchants}
        pageCount={allMerchants?.totalPages}
        currentPage={Page}
        page="Page"
        filter={<MerchantFilter />}
      />
    </SectionContainer>
  );
};

export default Deposits;
