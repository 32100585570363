import { toast } from "react-toastify";
import { sending } from "../../../Redux/Reducers/formReducer";
import {
  setAllNodes,
  setGettingAllNodes,
  setSwitchingNode,
} from "../../../Redux/Reducers/settingsReducer";
import api from "../../baseURL";
import { store } from "../../../Redux/app/store";

export const AddNodeProvider = async (
  name: string,
  nodeUrl: string,
  archiveNodeUrl: string,
  blockchainNetworkId: string
) => {
  store.dispatch(sending(true));
  const body = {
    name,
    nodeUrl,
    archiveNodeUrl,
    blockchainNetworkId,
  };

  await api
    .post("v1/node-provider/add", body)
    .then((res) => {
      const { data } = res;
      toast.success("Successful");
      store.dispatch(sending(false));
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const EditNodeProvider = async (
  nodeProviderId: string,
  blockchainNetworkId: string,
  name: string,
  nodeUrl: string,
  archiveNodeUrl: string
) => {
  store.dispatch(sending(true));
  const body: any = {
    nodeProviderId: nodeProviderId.replace(
      /([0-z]{8})([0-z]{4})([0-z]{4})([0-z]{4})([0-z]{12})/,
      "$1-$2-$3-$4-$5"
    ),
    name,
    nodeUrl,
    archiveNodeUrl,
    blockchainNetworkId,
  };

  Object.keys(body)?.forEach((key) => {
    if (body[key] === (null || "")) {
      delete body[key];
    }
  });

  await api
    .post("v1/node-provider/edit", body, {
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
      },
    })
    .then((res) => {
      const { data } = res;
      toast.success("Successful");
      store.dispatch(sending(false));
      window.location.reload();
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const getNodesByNetworkChain = async (networkChain: string) => {
  const params = {
    networkChain,
  };
  await api
    .get("v1/node-provider/nodes/get-by-networkChain", { params })
    .then((res) => {
      const { data } = res?.data;
      if (data) {
        console.log(data);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllNodes = async (blockchainNetworkId: string | null) => {
  store.dispatch(setGettingAllNodes(true));
  const params = {
    blockchainNetworkId,
  };
  await api
    .get("v1/node-provider/nodes/get", { params })
    .then((res) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setGettingAllNodes(false));
        store.dispatch(setAllNodes(data));
      }
    })
    .catch((err) => {
      store.dispatch(setGettingAllNodes(false));
    });
};

export const getActiveNodes = async (blockchainNetworkId: string) => {
  const params = {
    blockchainNetworkId,
  };
  await api
    .get("v1/node-provider/get-active", { params })
    .then((res) => {
      const { data } = res?.data;
      if (data) {
        console.log(data);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteNode = async (nodeProviderId: string) => {
  store.dispatch(setSwitchingNode(true));
  const params = {
    nodeProviderId,
  };
  await api
    .delete("v1/node-provider/delete", { params })
    .then((res) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setSwitchingNode(false));
        toast.success("Successful");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setSwitchingNode(false));
    });
};

export const enableNode = async (
  nodeProviderId: string,
  blockchainNetworkId: string
) => {
  store.dispatch(setSwitchingNode(true));
  const body = {
    nodeProviderId,
    blockchainNetworkId,
  };
  await api
    .put("v1/node-provider/enable", body)
    .then((res) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setSwitchingNode(false));
        toast.success(data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    })
    .catch((err) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setSwitchingNode(false));
    });
};
