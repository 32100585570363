import React from "react";
import WithdrawalTrackerTable from "../../../../components/Tables/WithdrawalTrackerTable";

const Withdrawals = () => {
  return (
    <div>
      <WithdrawalTrackerTable />
    </div>
  );
};

export default Withdrawals;
