import React from "react";
import {
  FormContainer,
  Input,
  InputContainer,
  Label,
} from "../../../../components/Generals/LoginStyles";
import Filledbutton from "../../../../components/Reusables/Buttons/Filledbutton";
import { validateemail } from "../../../../Helpers/generals";
import { useAppSelector } from "../../../../Redux/app/hooks";
import {
  errorMessage,
  getFormDetails,
} from "../../../../Redux/Reducers/formReducer";
import { Form } from "./../../../../components/Generals/LoginStyles";
import { useAppDispatch } from "./../../../../Redux/app/hooks";
import { validatePassword } from "./../../../../Helpers/generals";
import { createMerchant } from "../../../../Apis/PrivatesApis/ThirdParty";

const CreateAccount = () => {
  const {
    firstName,
    lastName,
    emailAddress,
    password,
    confirmPassword,
    businessName,
    error,
    loading,
  } = useAppSelector((state) => state.form);
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(errorMessage(""));
    const { name, value } = e.target;
    dispatch(getFormDetails({ name, value }));
  };

  const handleCreatingMerchant = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (
      !emailAddress ||
      !firstName ||
      !lastName ||
      !businessName ||
      !password ||
      !confirmPassword
    ) {
      dispatch(errorMessage("Please fill all fields"));
    } else if (password !== confirmPassword) {
      dispatch(errorMessage("passwords doesn't match"));
    } else if (!validatePassword(password)) {
      dispatch(
        errorMessage(
          "* min 8 letter password, with at least a symbol, upper and lower case letters and a number"
        )
      );
    } else if (!validateemail(emailAddress)) {
      dispatch(
        errorMessage(
          "Please fill in a valid email address (e.g: example@example.com)"
        )
      );
    } else {
      createMerchant(
        firstName,
        lastName,
        businessName,
        emailAddress,
        password,
      );
    }
  };

  return (
    <FormContainer>
      <Form customWidth="80%" style={{ flexWrap: "wrap" }}>
        <InputContainer>
          <Label>Email Address</Label>
          <Input
            placeholder="example@gmail.com"
            type="email"
            value={emailAddress}
            name="emailAddress"
            onChange={handleChange}
          />
        </InputContainer>
        <InputContainer>
          <Label>Business Name</Label>
          <Input
            placeholder="Business Name"
            value={businessName}
            name="businessName"
            onChange={handleChange}
          />
        </InputContainer>
        <InputContainer>
          <Label>First Name</Label>
          <Input
            placeholder="First Name"
            value={firstName}
            name="firstName"
            onChange={handleChange}
          />
        </InputContainer>
        <InputContainer>
          <Label>Last Name</Label>
          <Input
            placeholder="Last Name"
            value={lastName}
            name="lastName"
            onChange={handleChange}
          />
        </InputContainer>
        <InputContainer>
          <Label>Password</Label>
          <Input
            type="password"
            value={password}
            name="password"
            onChange={handleChange}
          />
        </InputContainer>
        <InputContainer>
          <Label>Confirm Password</Label>
          <Input
            type="password"
            value={confirmPassword}
            name="confirmPassword"
            onChange={handleChange}
          />
        </InputContainer>
        <Filledbutton
          text="Create"
          handleClick={handleCreatingMerchant}
          loading={loading}
        />
        <div className="text-red">{error}</div>
      </Form>
    </FormContainer>
  );
};

export default CreateAccount;
