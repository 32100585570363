export const status = [
  { label: "--", value: "--" },
  { label: "Pending", value: 0 },
  { label: "Successful", value: 1 },
  { label: "Failed", value: 2 },
];

export const remittanceStatus = [
  { label: "--", value: "--" },
  { label: "Successful", value: 1 },
  { label: "Failed", value: 2 },
  { label: "Initiated", value: 3 },
];
export const currencies = [
  { label: "--", value: "--" },
  { label: "BTC", value: 1 },
  { label: "LTC", value: 2 },
  { label: "BNB", value: 3 },
  { label: "USDT", value: 4 },
  { label: "DAI", value: 5 },
  { label: "BUSD", value: 6 },
  { label: "XEND", value: 7 },
  { label: "ETH", value: 8 },
  { label: "MATIC", value: 9 },
  { label: "WNT", value: 10 },
  { label: "USDC", value: 11 },
  { label: "CUSD", value: 12 },
  { label: "CEDR", value: 13 },
  { label: "CELO", value: 14 },
  { label: "TRX", value: 15 },
  { label: "USDJ", value: 16 },
  { label: "TUSD", value: 17 },
];
export const networks = [
  { label: "--", value: "--" },
  { label: "BSC", value: 1 },
  { label: "ETH", value: 2 },
  { label: "POLYGON", value: 3 },
  { label: "CELO", value: 4 },
  { label: "ARBITRUM", value: 5 },
  { label: "AVALANCHE", value: 6 },
  { label: "HECOCHAIN", value: 7 },
  { label: "TRON", value: 8 },
];

export const groupByPeriod = [
  { label: "--", value: "--" },
  { label: "Day", value: 1 },
  { label: "Month", value: 2 },
  { label: "Year", value: 3 },
];

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

export const transactionTypes = [
  { label: "--", value: "--" },
  { label: "Deposit", value: 1 },
  { label: "Withdrawal", value: 2 },
  // { label: "Transfer", value: 3 },
];

export const MerchantStatus = [
  { label: "--", value: "allmerchants" },
  { label: "Active", value: "activemerchants" },
  { label: "Inactive", value: "inactivemerchants" },
];

export const adminRoles = [
  { label: "Support", value: 2 },
  { label: "Operations", value: 3 },
  { label: "Developer", value: 4 },
  { label: "Admin", value: 5 },
  { label: "SuperAdmin", value: 6 },
];
