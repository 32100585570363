import React from "react";
import { OverviewContianer } from "./style";
import GradientCard from "./../../../components/Reusables/Cards/GradientCard";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getActiveMerchants,
  getAllMerchants,
  getInActiveMerchants,
  getTotalRecords,
} from "../../../Apis/PrivatesApis/overviewApis";
import { useNavigate } from "react-router-dom";
import { handleSubnav } from "../../../Redux/Reducers/navReducer";
import { getFormDetails } from "../../../Redux/Reducers/formReducer";

const SummarySection = () => {
  const {
    gettingTotalRecords,
    totalRecords,
    gettingActiveMerchants,
    activeMerchants,
    gettingInActiveMerchants,
    inActiveMerchants,
    gettingAllMerchants,
    allMerchants,
  } = useAppSelector((state) => state.overview);
  const { merchantStatus } = useAppSelector((state) => state.form);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    getTotalRecords();
    getInActiveMerchants();
    getActiveMerchants();
    getAllMerchants(1, 30, merchantStatus);
  }, []);

  return (
    <OverviewContianer>
      <GradientCard
        title="Total Merchant"
        balance={allMerchants?.totalRecords}
        subTitle="total number of merchant"
        loading={gettingAllMerchants}
        handleClick={() => {
          navigate("/third-party");
          dispatch(
            getFormDetails({
              name: "merchantStatus",
              value: "allmerchants",
            })
          );
        }}
        buttonId="totalMerchant"
      />
      <GradientCard
        title="Total Active Merchants"
        balance={activeMerchants?.totalRecords}
        subTitle="total active merchant"
        loading={gettingActiveMerchants}
        handleClick={() => {
          navigate("/third-party");
          dispatch(
            getFormDetails({
              name: "merchantStatus",
              value: "activemerchants",
            })
          );
        }}
        buttonId="activeMerchants"
      />
      <GradientCard
        title="Total Inactive Merchants"
        balance={inActiveMerchants?.totalRecords}
        subTitle="total inactive merchant"
        loading={gettingInActiveMerchants}
        handleClick={() => {
          navigate("/third-party");
          dispatch(
            getFormDetails({
              name: "merchantStatus",
              value: "inactivemerchants",
            })
          );
        }}
        buttonId="inActiveMerchants"
      />
      <GradientCard
        title="Total Deposits"
        balance={totalRecords?.deposit?.count}
        subTitle="total number of deposit transactions"
        loading={gettingTotalRecords}
        handleClick={() => {
          navigate("/transactions");
          dispatch(handleSubnav(1));
        }}
        buttonId="deposits"
      />
      <GradientCard
        title="Total withdrawals"
        balance={totalRecords?.withdrawal?.count}
        subTitle="total number of withdrawal transactions"
        loading={gettingTotalRecords}
        handleClick={() => {
          navigate("/transactions");
          dispatch(handleSubnav(2));
        }}
        buttonId="withdrawals"
      />
      <GradientCard
        title="Total Remittances"
        balance={totalRecords?.remitance?.count}
        subTitle="total number of remittance transactions"
        loading={gettingTotalRecords}
        handleClick={() => {
          navigate("/transactions");
          dispatch(handleSubnav(3));
        }}
        buttonId="remittances"
      />
      <GradientCard
        title="Total Unremitted"
        balance={totalRecords?.unRemitance?.count}
        subTitle="total number of unremitted transactions"
        loading={gettingTotalRecords}
        handleClick={() => {
          navigate("/transactions");
          dispatch(handleSubnav(4));
        }}
        buttonId="unremitted"
      />
      {/* <GradientCard
        title="Total Unremitted"
        balance={totalRecords?.unRemitance?.count}
        subTitle="total number of unremitted transactions"
        loading={gettingTotalRecords}
      /> */}
    </OverviewContianer>
  );
};

export default SummarySection;
