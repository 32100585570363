import React from "react";
import AccessControlTable from "./../../../../components/Tables/AccessControlTable";

const AccessControl = () => {
  return (
    <>
      <AccessControlTable />
    </>
  );
};

export default AccessControl;
