import { DotLoader } from "react-spinners";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../../Redux/app/hooks";
import { calTransSummary } from "../../../../utils/calculateSummary";
import {
  FlexAutoContainer,
  InnerPageSideBar,
  LeftContent,
  RouteContainer,
  SeeMore,
} from "../../../../components/Generals/RouteStyles";
import { toggleInnerSidebar } from "../../../../Redux/Reducers/navReducer";
import DepositTranscTable from "../../../../components/Tables/DepositsTranscTable";
import { RightContent } from "../../../../components/styles";
import { CloseInner } from "../../../../components/Forms/style";
import { Date, DateRange } from "../../../../components/Reusables/Cards/styles";
import SmallSummaryCard from "../../../../components/Reusables/Cards/SmallSummaryCard";
import { numberWithCommaswithoutdecimals } from "../../../../Helpers/generals";
import { useEffect } from "react";
import { getAllReports } from "./../../../../Apis/PrivatesApis/Reports";
import { getTotalRecords } from "../../../../Apis/PrivatesApis/overviewApis";
import { useParams } from "react-router-dom";
const DepositsTransaction = () => {
  const { openInnerSidebar } = useAppSelector((state) => state.nav);
  const { totalRecords } = useAppSelector((state) => state.overview);
  const merchantId = useParams();
  const dispatch = useAppDispatch();
  const { walletsTransactions, gettingWalletsTransactions } = useAppSelector(
    (state) => state.wallet
  );
  const transactionSummary = calTransSummary(walletsTransactions);

  useEffect(() => {
    getTotalRecords(merchantId?.id);
  }, []);

  return (
    <RouteContainer>
      <SeeMore onClick={() => dispatch(toggleInnerSidebar(true))}>
        <img src="/assets/icons/right-icon.svg" alt="right" />
        <span>view summary</span>
      </SeeMore>
      <FlexAutoContainer>
        <LeftContent flex="0.8" padding="0 0" style={{ overflowX: "auto" }}>
          <DepositTranscTable />
        </LeftContent>
        <RightContent flex="0.2" padding="0 5px">
          <InnerPageSideBar toggle={openInnerSidebar}>
            <CloseInner onClick={() => dispatch(toggleInnerSidebar(false))}>
              x
            </CloseInner>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              {!gettingWalletsTransactions ? (
                <>
                  <SmallSummaryCard
                    title="Deposit"
                    singleCount={numberWithCommaswithoutdecimals(
                      totalRecords?.deposit?.count || "0"
                    )}
                  />
                  <DateRange className="text-red">
                    Date Range
                    <Date>
                      {moment(
                        walletsTransactions?.records[
                          walletsTransactions?.records?.length - 1
                        ]?.createdAt
                      ).format("MMM Do YYYY")}{" "}
                      -{" "}
                      {moment(
                        walletsTransactions?.records[0]?.createdAt
                      ).format("MMM Do YYYY")}
                    </Date>
                  </DateRange>
                  {transactionSummary?.map((item: any, index: any) => (
                    <SmallSummaryCard
                      title={item?.currency}
                      // vol={item?.amount}
                      singleCount={item?.count}
                      key={item + index}
                      network={item?.network}
                    />
                  ))}
                </>
              ) : (
                <div className="flex-center">
                  <DotLoader color="#ff6600" size={10} />
                </div>
              )}
            </div>
          </InnerPageSideBar>
        </RightContent>
      </FlexAutoContainer>
    </RouteContainer>
  );
};
export default DepositsTransaction;
