import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getTotalRecords } from "../../../../Apis/PrivatesApis/overviewApis";
import { CloseInner } from "../../../../components/Forms/style";
import {
  FlexAutoContainer,
  InnerPageSideBar,
  LeftContent,
  RightContent,
  RouteContainer,
} from "../../../../components/Generals/RouteStyles";
import SmallSummaryCard from "../../../../components/Reusables/Cards/SmallSummaryCard";
import SummaryCard from "../../../../components/Reusables/Cards/SummaryCard";
import RemittanceTable from "../../../../components/Tables/RemittanceTable";
import { numberWithCommaswithoutdecimals } from "../../../../Helpers/generals";
import { useAppDispatch, useAppSelector } from "../../../../Redux/app/hooks";
import { toggleInnerSidebar } from "../../../../Redux/Reducers/navReducer";
import { calRemittanceSummary } from "../../../../utils/calculateSummary";

const RemittanceTransc = () => {
  const dispatch = useAppDispatch();
  const { remittanceTransactions } = useAppSelector((state) => state.wallet);
  const { totalRecords } = useAppSelector((state) => state.overview);
  const merchantId = useParams();
  const remittanceSummary = calRemittanceSummary(remittanceTransactions);
  const { openInnerSidebar } = useAppSelector((state) => state.nav);
  const { gettingRemittanceTransactions } = useAppSelector(
    (state) => state.wallet
  );

  useEffect(() => {
    dispatch(toggleInnerSidebar(false));
    getTotalRecords(merchantId?.id);
  }, []);

  return (
    <RouteContainer>
      <FlexAutoContainer>
        <LeftContent flex="0.8" padding="0 0" style={{ overflowX: "auto" }}>
          <RemittanceTable />
        </LeftContent>
        <RightContent flex="0.2" padding="0 0">
          <InnerPageSideBar toggle={openInnerSidebar} style={{ padding: "0" }}>
            <CloseInner onClick={() => dispatch(toggleInnerSidebar(false))}>
              x
            </CloseInner>
            <SmallSummaryCard
              title="Remittance"
              singleCount={remittanceTransactions?.totalRecords || "0"}
            />
            <SummaryCard
              gasSummaryData={remittanceSummary?.completeGasFilter}
              remittanceSummaryData={remittanceSummary?.completeRemitaFilter}
              startdate={
                remittanceTransactions?.records[0]?.transactionTimeStamp
              }
              endDate={
                remittanceTransactions?.records[
                  remittanceTransactions?.records?.length - 1
                ]?.transactionTimeStamp
              }
              loading={gettingRemittanceTransactions}
            />
          </InnerPageSideBar>
        </RightContent>
      </FlexAutoContainer>
    </RouteContainer>
  );
};

export default RemittanceTransc;
