import { useEffect } from "react";
import { RouteContainer } from "../../../components/Generals/RouteStyles";
import { useAppSelector } from "../../../Redux/app/hooks";
import ErrorStatus from "./ErrorStatus";
// import KYB from "./KYB";
import Nodes from "./Nodes";
// import { useAppDispatch } from "./../../../Redux/app/hooks";
import MainNav from "../../../components/NavBars/MainNav";
// import { handleMainnav } from "../../../Redux/Reducers/navReducer";
import NotificationModal from "../../../components/Modals/NotificationModal";
import AccountSettings from "./AccountSetting";

const Settings = () => {
  const { currentSubnav } = useAppSelector((state) => state.nav);
  // const dispatch = useAppDispatch();

  useEffect(() => {
    // dispatch(handleMainnav(1));
  }, []);

  const ErrorSubnav = [
    // {
    //   navId: 1,
    //   navTitle: "Nodes",
    // },
    // {
    //   navId: 2,
    //   navTitle: "Workers",
    // },
    {
      navId: 1,
      navTitle: "Transactions",
    },
  ];

  const NodeSubnav = [
    {
      navId: 1,
      navTitle: "Node Status",
    },
    {
      navId: 2,
      navTitle: "Node Switching",
    },
    {
      navId: 3,
      navTitle: "Add Node",
    },
  ];

  const AccountsettingsSubnav = [
    {
      navId: 1,
      navTitle: "Create Account",
    },
    {
      navId: 2,
      navTitle: "Security",
    },
  ];

  return (
    <>
      {currentSubnav === 3 && <MainNav listItem={ErrorSubnav} />}
      {currentSubnav === 1 && <MainNav listItem={NodeSubnav} />}
      {currentSubnav === 2 && <MainNav listItem={AccountsettingsSubnav} />}
      <RouteContainer>
        {currentSubnav === 1 ? (
          <Nodes />
        ) : currentSubnav === 2 ? (
          <AccountSettings />
        ) : (
          <ErrorStatus />
        )}
      </RouteContainer>
      <NotificationModal />
    </>
  );
};

export default Settings;
