import React from "react";
import AreaChart from "./AreaChart";
import { getDailyReportOptions } from "./../../utils/data";
import { getDailyReportSeries } from "../../utils/chatSeries";

const DailyReportChart = () => {
  const dailyReportSeries = getDailyReportSeries();
  const dailyReportOptions = getDailyReportOptions();

  return <AreaChart series={dailyReportSeries} options={dailyReportOptions} />;
};

export default DailyReportChart;
