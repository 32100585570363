import {
  setGettingTransactionMetrics,
  setGettingUnRemittanceDetails,
  setTransactionMetrics,
  setUnRemittanceDetails,
} from "../../Redux/Reducers/analyticsReducer";
import { filter, sending } from "../../Redux/Reducers/formReducer";
import api from "../baseURL";
import { toast } from "react-toastify";
import { handleModal } from "../../Redux/Reducers/navReducer";
import { store } from "../../Redux/app/store";

export const getWalletsGenerated = async () => {
  await api
    .get("v1/walletBalance/merchant-wallet-balances", {})
    .then((res: any) => {
      const { data } = res?.data;
      if (data) {
        // store.dispatch(setMerchantBalances(data));
      }
    });
};

export const getTransactionMetrics = async (
  parameters?: any
) => {
  store.dispatch(setGettingTransactionMetrics(true));

  const params = parameters;
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`v2/analytics/transaction-metrics`, {
      params,
    })
    .then((res: any) => {
      const { data } = res?.data;
      if (data) {
        store.dispatch(setTransactionMetrics(data));
        store.dispatch(setGettingTransactionMetrics(false));
      }
      store.dispatch(filter(false));
    })
    .catch(() => {
      store.dispatch(filter(false));
      store.dispatch(setGettingTransactionMetrics(false));
    });
};

export const checkReferenceAndRetry = async (
  transactionHash: string,
  transactionReference: string,
) => {
  store.dispatch(sending(true));
  const body = { transactionHash, transactionReference, retry: true };
  await api
    .post("v2/merchant/webhook/Check-Reference-AndRetry", body, {
      params: {
        "api-version": 2,
      },
      headers: {},
    })
    .then((res: any) => {
      toast.success("Successful");
      store.dispatch(sending(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};


export const checkHashAndRetry = async (
  transactionHash: string,
  transactionReference: string,
) => {
  store.dispatch(sending(true));
  const body = { transactionHash, transactionReference, retry: true };
  await api
    .post("v2/merchant/webhook/Check-Hash-AndRetry", body, {
      params: {
        "api-version": 2,
      },
    })
    .then((res: any) => {
      toast.success("Successful");
      store.dispatch(sending(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data?.message);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(sending(false));
    });
};

export const RetryRemittance = async (
  walletAddress: string,
  uniqueReference: string,
  currencySymbol: any,
  networkChain: any,
) => {
  store.dispatch(sending(true));
  await api.get("v1/merchant/IntegrationDetails/Get", {}).then((res: any) => {
    const body = {
      walletAddress,
      uniqueReference,
      publicKey: res?.data?.data?.publicKey,
      currencySymbol,
      networkChain,
    };
    api
      .post("v1/remittanceRequest", body, {})
      .then((res: any) => {
        toast.success("Successful");
        store.dispatch(sending(false));
      })
      .catch((err: any) => {
        if (err?.response) toast.error(err?.response?.data?.message);
        if (err?.message === "Network Error") toast.error(err?.message);
        store.dispatch(sending(false));
      });
  });
};

export const getUnRemittanceDetails = async (
  walletAddress: string,
  currencyId: string
) => {
  store.dispatch(setGettingUnRemittanceDetails(true));

  const params: any = { currencyId, walletAddress };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get(`v2/analytics/deposit-remittance-detail`, {
      params,
    })
    .then((res) => {
      if (res?.data?.remitanceDetail?.records.length > 0) {
        store.dispatch(setUnRemittanceDetails(res?.data?.remitanceDetail));
        store.dispatch(setGettingUnRemittanceDetails(false));
        store.dispatch(handleModal(5));
      } else {
        toast.error("No Records");
        store.dispatch(setGettingUnRemittanceDetails(false));
      }
      store.dispatch(filter(false));
    })
    .catch(() => {
      store.dispatch(filter(false));
      store.dispatch(setGettingUnRemittanceDetails(false));
    });
};