export const privatePathname = [
  "/",
  "/transactions/",
  "/transactions/withdrawals/:id",
  "/transactions/deposits/:id",
  "/transactions/remittances/:id",
  "/transactions/unremitted/:id",
  "/usage",
  "/reports",
  "/third-party",
  "/settings",
  "/workers",
];

export const authPathname = ["/"];
