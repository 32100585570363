import React from "react";
import CustomModal from "./CustomModal";
import {
  Form,
  FormContainer,
  Input,
  InputContainer,
  TextArea,
  Label,
} from "../Generals/LoginStyles";
import Filledbutton from "../Reusables/Buttons/Filledbutton";
import { useAppSelector } from "../../Redux/app/hooks";

const NotificationModal = () => {
  // const { selectedMerchantId } = useAppSelector((state) => state.reports);

  return (
    <CustomModal
      id={1}
      content={
        <Form customWidth="80%" style={{ flexWrap: "wrap" }}>
          <InputContainer>
            <Label>Merchant Email Address</Label>
            <Input placeholder="example@gmail.com" type="email" />
          </InputContainer>
          <InputContainer>
            <Label>Text</Label>
            <TextArea placeholder="Type your message here" />
          </InputContainer>
          <Filledbutton text="Send" />
        </Form>
      }
    />
  );
};

export default NotificationModal;
