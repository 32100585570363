import { getTotalRecords } from "../../../../Apis/PrivatesApis/overviewApis";
import { CloseInner } from "../../../../components/Forms/style";
import {
  FlexAutoContainer,
  InnerPageSideBar,
  LeftContent,
  RightContent,
  RouteContainer,
} from "../../../../components/Generals/RouteStyles";
import UnremittedDetailsModal from "../../../../components/Modals/UnremittedDetailsModal";
import SummaryCard from "../../../../components/Reusables/Cards/SummaryCard";
import UnRemittanceTable from "../../../../components/Tables/UnRemittanceTable";
import { useAppDispatch, useAppSelector } from "../../../../Redux/app/hooks";
import { toggleInnerSidebar } from "../../../../Redux/Reducers/navReducer";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import SmallSummaryCard from "../../../../components/Reusables/Cards/SmallSummaryCard";
import { numberWithCommaswithoutdecimals } from "../../../../Helpers/generals";

const UnremittedTransc = () => {
  const { openInnerSidebar } = useAppSelector((state) => state.nav);
  const { totalRecords } = useAppSelector((state) => state.overview);
  const merchantId = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    getTotalRecords(merchantId?.id);
  }, []);

  return (
    <RouteContainer>
      <FlexAutoContainer>
        <LeftContent flex="0.8" padding="0 0" style={{ overflowX: "auto" }}>
          {" "}
          <UnRemittanceTable />
        </LeftContent>
        <RightContent flex="0.2" padding="0 0">
          <InnerPageSideBar toggle={openInnerSidebar} style={{ padding: "0" }}>
            <CloseInner onClick={() => dispatch(toggleInnerSidebar(false))}>
              x
            </CloseInner>
            {/* <SummaryCard
            unremittanceData={merchantUnremitedBal?.balances}
            titleOne="Unremitted"
            loading={gettingMerchantUnremitedBal}
            /> */}
            <SmallSummaryCard
              title="Unremitted"
              singleCount={numberWithCommaswithoutdecimals(
                totalRecords?.unRemitance?.count || "0"
              )}
            />
          </InnerPageSideBar>
        </RightContent>
      </FlexAutoContainer>
      <UnremittedDetailsModal />
    </RouteContainer>
  );
};

export default UnremittedTransc;
