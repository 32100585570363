import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  clearForm,
  clearTableFilterForm,
  getFormDetails,
  setPage,
} from "../../Redux/Reducers/formReducer";
import {
  handleMainnav,
  handleSubnav,
  toggleMobileNav,
} from "../../Redux/Reducers/navReducer";
import { Logout } from "../../Redux/Reducers/userReducer";
import {
  ExternalLink,
  NavItem,
  SideBarContainer,
  SidebarFooter,
  SidebarHeader,
  SidebarNav,
  SubNavContainer,
  SubNavItem,
} from "./styles";
import { HiOutlineLogout } from "react-icons/hi";
import { sidebarNavs } from "./../../config";

const Sidebar = () => {
  const { pathname } = useLocation();
  const { toggle, currentSubnav } = useAppSelector((state) => state.nav);
  const { currentRoles } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <SideBarContainer toggle={toggle}>
      <SidebarHeader>
        <img src="/assets/images/Brands/logo.svg" alt="logo" />
        <p>SuperAdmin Dashboard</p>
      </SidebarHeader>
      <SidebarNav>
        {sidebarNavs?.map((item: any) => (
          <>
            {item.type !== "external" ? (
              <>
                {item?.visible() && (
                  <>
                    <NavItem
                      key={item?.id}
                      to={item?.link}
                      className={`${pathname === item?.link && "active"}`}
                      onClick={() => {
                        dispatch(toggleMobileNav());
                        dispatch(clearTableFilterForm());
                        dispatch(handleSubnav(1));
                        dispatch(handleMainnav(1));
                        dispatch(setPage({ page: "Page", value: 1 }));
                        dispatch(clearForm());
                      }}
                      data-testid={item?.link}
                    >
                      {item?.icon}
                      <span>{item.name}</span>
                    </NavItem>
                    {(pathname === item?.link ||
                      pathname.includes(item?.link)) && (
                      <>
                        {currentRoles?.includes("SuperAdmin") ? (
                          <>
                            {item?.protectedChildren ? (
                              <SubNavContainer>
                                {item?.protectedChildren.map((child: any) => (
                                  <SubNavItem
                                    key={child?.id}
                                    className={`${
                                      currentSubnav === child?.id && "active"
                                    }`}
                                    onClick={() => {
                                      dispatch(handleSubnav(child?.id));
                                      dispatch(toggleMobileNav());
                                      navigate(item?.link);
                                      dispatch(handleMainnav(1));
                                      dispatch(
                                        setPage({ page: "Page", value: 1 })
                                      );
                                      dispatch(clearForm());
                                      dispatch(
                                        getFormDetails({
                                          name: "merchantStatus",
                                          value: "allmerchants",
                                        })
                                      );
                                    }}
                                    data-testid={child?.name}
                                  >
                                    {child?.name}
                                  </SubNavItem>
                                ))}
                              </SubNavContainer>
                            ) : (
                              <SubNavContainer>
                                {item?.children?.map((child: any) => (
                                  <SubNavItem
                                    key={child?.id}
                                    className={`${
                                      currentSubnav === child?.id && "active"
                                    }`}
                                    onClick={() => {
                                      dispatch(handleSubnav(child?.id));
                                      dispatch(toggleMobileNav());
                                      navigate(item?.link);
                                      dispatch(handleMainnav(1));
                                      dispatch(
                                        setPage({ page: "Page", value: 1 })
                                      );
                                      dispatch(clearForm());
                                      dispatch(
                                        getFormDetails({
                                          name: "merchantStatus",
                                          value: "allmerchants",
                                        })
                                      );
                                    }}
                                    data-testid={child?.name}
                                  >
                                    {child?.name}
                                  </SubNavItem>
                                ))}
                              </SubNavContainer>
                            )}
                          </>
                        ) : (
                          <>
                            {item?.children && (
                              <SubNavContainer>
                                {item?.children.map((child: any) => (
                                  <SubNavItem
                                    key={child?.id}
                                    className={`${
                                      currentSubnav === child?.id && "active"
                                    }`}
                                    onClick={() => {
                                      dispatch(handleSubnav(child?.id));
                                      dispatch(toggleMobileNav());
                                      navigate(item?.link);
                                      dispatch(handleMainnav(1));
                                      dispatch(
                                        setPage({ page: "Page", value: 1 })
                                      );
                                      dispatch(clearForm());
                                      dispatch(
                                        getFormDetails({
                                          name: "merchantStatus",
                                          value: "allmerchants",
                                        })
                                      );
                                    }}
                                    data-testid={child?.name}
                                  >
                                    {child?.name}
                                  </SubNavItem>
                                ))}
                              </SubNavContainer>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <ExternalLink
                key={item?.id}
                href={item?.link}
                className={`${pathname === item?.link && "active"}`}
                onClick={() => {
                  dispatch(toggleMobileNav());
                  dispatch(clearTableFilterForm());
                }}
                target="_blank"
              >
                {item?.icon}
                <span>{item.name}</span>
              </ExternalLink>
            )}
          </>
        ))}
      </SidebarNav>
      <SidebarFooter
        onClick={() => {
          dispatch(Logout());
          dispatch(toggleMobileNav());
          navigate("/");
        }}
      >
        <HiOutlineLogout /> <span>Logout</span>
      </SidebarFooter>
      {/* <SwitchEnv /> */}
    </SideBarContainer>
  );
};

export default Sidebar;
