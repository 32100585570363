import { useEffect } from "react";
import { RouteContainer } from "../../../components/Generals/RouteStyles";
import Latest from "./Latest";
import SummarySection from "./SummarySection";
import { LoggedInCheck2FAEnabled } from "../../../Apis/accountProcesing";
import { useNavigate } from "react-router-dom";

const index = () => {
  const currentOperator = JSON.parse(
    localStorage.getItem("currentOperator") || "{}"
  );
  const navigate = useNavigate();

  useEffect(() => {
    LoggedInCheck2FAEnabled(currentOperator?.email, navigate);
  }, []);

  return (
    <RouteContainer>
      <SummarySection />
      <Latest />
    </RouteContainer>
  );
};

export default index;
