import moment from "moment";
import { copyHandle, roundton, shortenAddress } from "../../Helpers/generals";
import { numberWithCommaswithoutdecimals } from "../../Helpers/generals";
import SqaureFilledbutton from "../../components/Reusables/Buttons/SqaureFilledButton";
import { currencyList } from "../currencyList";
import {
  Coin,
  CurrencyAndNetwork,
  CurrencyContainer,
  CurrencyText,
  NetworkText,
} from "../../components/Tables/styles";

import {
  checkHashAndRetry,
  checkReferenceAndRetry,
} from "../../Apis/AnalyticsApiCalls/transactionsApis";

export const getRows = (
  walletsTransactions: any,
  showAddress?: boolean,
  setShowAddress?: any,
  selectedRow?: number,
  setSelectedRow?: any,
  loading?: boolean,
  dispatch?: any
) => {
  let rows: any = [];

  walletsTransactions?.records?.map((row: any, index: number) => {
    rows.push({
      transactionHash: row?.transactionHashUrl ? (
        <div style={{ display: "flex" }}>
          {" "}
          <a
            href={row?.transactionHashUrl}
            style={{ color: "blue", textDecoration: "none" }}
            target="_blank"
          >
            {shortenAddress(row?.transactionHash)}
          </a>
          <img
            src="/assets/icons/Table/copy-icon-blue.svg"
            alt="copy"
            onClick={() => copyHandle(row?.transactionHash)}
            style={{ cursor: "pointer", width: "16px" }}
          />
        </div>
      ) : (
        "N/A"
      ),
      address: row?.toAddress ? (
        <div style={{ display: "flex", gap: "5px" }}>
          {/* <input
            type="text"
            value={
              showAddress && selectedRow === index
                ? row?.toAddress
                : shortenAddress(row?.toAddress)
            }
            style={{
              border: "none",
              outline: "none",
              width: "100px",
              color: "#52575C",
            }}
            onFocus={() => {
              setShowAddress(true);
              setSelectedRow(index);
            }}
            onBlur={() => setShowAddress(false)}
          />
           */}
          <span>{shortenAddress(row?.toAddress)}</span>
          <img
            src="/assets/icons/Table/copy-icon-blue.svg"
            alt="copy"
            onClick={() => copyHandle(row?.toAddress)}
            style={{ cursor: "pointer", width: "16px" }}
          />
        </div>
      ) : (
        "N/A"
      ),
      balance: row?.amount ? (
        <CurrencyContainer style={{ gap: "10px" }}>
          {currencyList?.map((item, index) => (
            <>
              {row?.currency === item?.name && (
                <Coin
                  key={index}
                  src={item?.Symbol}
                  alt={item?.name}
                  style={{ width: "20px" }}
                />
              )}
            </>
          ))}
          <CurrencyAndNetwork style={{ gap: "5px" }}>
            <CurrencyText style={{ fontSize: "14px" }}>
              {row?.currency}
            </CurrencyText>
            <NetworkText>{roundton(row?.amount, 5)}</NetworkText>
          </CurrencyAndNetwork>
        </CurrencyContainer>
      ) : (
        "N/A"
      ),
      network: row?.network ? <>{row?.network}</> : "N/A",
      reference: row?.reference ? <>{row?.reference}</> : "N/A",
      date: row?.createdAt ? (
        <CurrencyAndNetwork style={{ gap: "5px" }}>
          <CurrencyText style={{ fontSize: "14px" }}>
            {moment(row?.createdAt).format("MMM Do YYYY")}
          </CurrencyText>
          <NetworkText>{moment(row?.createdAt).format("LT")}</NetworkText>
        </CurrencyAndNetwork>
      ) : (
        "N/A"
      ),
      status: row?.transactionStatus ? (
        <p
          className={`${
            row?.transactionStatus === "Successful"
              ? "text-green"
              : row?.transactionStatus === "CONFIRMED"
              ? "text-green"
              : row?.transactionStatus === "Pending"
              ? "text-yellow"
              : "text-red"
          }`}
        >
          {row?.transactionStatus}
        </p>
      ) : (
        "N/A"
      ),
      callback: (
        <SqaureFilledbutton
          text="Resend"
          // icon="/assets/icons/Table/filter.svg"
          handleClick={() => {
            if (row?.transactionHash)
              checkHashAndRetry(row?.transactionHash, row?.reference);
            else
              checkReferenceAndRetry(
                row?.transactionHash,
                row?.reference,
              );
            setSelectedRow(index);
          }}
          loading={selectedRow === index && loading}
        />
      ),
      transactionType:
        row?.transactionType === "Deposit" ? (
          <img src="/assets/icons/Table/credit.svg" width="20px" />
        ) : (
          <img src="/assets/icons/Table/debit.svg" width="20px" />
        ),
    });
  });
  return rows;
};
