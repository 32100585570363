import { useEffect } from "react";
import { HealtStatusContainer } from "../HealthStatus/styles";
import TrackerReportCard from "../../../../components/Reusables/Cards/TrackerReportCard";
import Trackerfilter from "../../../../components/Forms/Trackerfilter";
import { getIssuesReport } from "../../../../Apis/PrivatesApis/workers";
import { useAppSelector } from "../../../../Redux/app/hooks";
import { selectWorker } from "../../../../Redux/Reducers/WorkerReducer";
import Loader from "../../../../components/Reusables/Tables/Loader";

const Report = () => {
  const { gettingIssuesReport, issuesReport, startDate, endDate } =
    useAppSelector(selectWorker);

  useEffect(() => {
    getIssuesReport(startDate, endDate);
  }, [startDate, endDate]);

  return (
    <div style={{ padding: "20px" }}>
      <Trackerfilter />
      <HealtStatusContainer>
        {gettingIssuesReport ? (
          <Loader />
        ) : (
          <>
            <TrackerReportCard
              title="Common Failure Reason"
              desc={issuesReport?.commonFailureReason}
            />
            <TrackerReportCard
              title="Total Deposits"
              desc={issuesReport?.totalDeposits}
            />
            <TrackerReportCard
              title="Deposit Average Time"
              desc={issuesReport?.depositAverageTime}
            />
            <TrackerReportCard
              title="Deposit Not Within Thirty Min"
              desc={issuesReport?.depositsNotWithinThirthyMin}
            />
            <TrackerReportCard
              title="Deposits Within Thirty Min"
              desc={issuesReport?.depositsWithinThirthyMin}
            />
            <TrackerReportCard
              title="Total Withdrawals"
              desc={issuesReport?.totalWithdrawals}
            />
            <TrackerReportCard
              title="Withdrawal Average Time"
              desc={issuesReport?.withdrawalAverageTime}
            />
            <TrackerReportCard
              title="Withdrawal Not Within Thirty Min"
              desc={issuesReport?.withdrawalsNotWithinThirthyMin}
            />
            <TrackerReportCard
              title="Withdrawals Within Thirty Min"
              desc={issuesReport?.withdrawalWithinThirthyMin}
            />
          </>
        )}
      </HealtStatusContainer>
    </div>
  );
};

export default Report;
