import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { clearForm } from "../../Redux/Reducers/formReducer";
import { handleMainnav } from "../../Redux/Reducers/navReducer";
import { MainNavContainer, MainNavItem } from "./styles";

interface MainNavI {
  listItem?: object[] | null;
}
const MainNav: FC<MainNavI> = ({ listItem }) => {
  const { currentMainBodyNav } = useAppSelector((state) => state.nav);
  const dispatch = useAppDispatch();
  return (
    <MainNavContainer>
      {listItem?.map((item: any) => (
        <MainNavItem
          className={`${currentMainBodyNav === item?.navId && "active"}`}
          key={item?.navId}
          onClick={() => {
            dispatch(handleMainnav(item?.navId));
            dispatch(clearForm());
          }}
        >
          {item?.navTitle}
        </MainNavItem>
      ))}
    </MainNavContainer>
  );
};

export default MainNav;
