import React from "react";
import CustomModal from "./CustomModal";
import {
  Form,
  FormContainer,
  Input,
  InputContainer,
  TextArea,
  Label,
  SelectBar,
  colourStyles,
} from "../Generals/LoginStyles";
import Filledbutton from "../Reusables/Buttons/Filledbutton";
import { useDispatch } from "react-redux";
import { getFormDetails } from "../../Redux/Reducers/formReducer";
import { DateRangePicker } from "../Forms/style";
import { DatePick } from "./../Generals/LoginStyles";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { generateReport } from "../../Apis/PrivatesApis/Reports";
import moment from "moment";

const TransactionTypes = [
  {
    label: "--",
    value: null,
  },
  {
    label: "Deposit",
    value: 1,
  },
  {
    label: "Withdrawal",
    value: 2,
  },
  {
    label: "Remittance",
    value: 3,
  },
  {
    label: "Unremitted",
    value: 4,
  },
];

const GenerateReportModal = () => {
  const { selectedMerchantId, generatingReport } = useAppSelector(
    (state) => state.reports
  );
  const { transactionType, reportStartDate, reportEndDate } = useAppSelector(
    (state) => state.form
  );
  const dispatch = useAppDispatch();
  const handleSelectChange = (option: any, name: string) => {
    if (option.value === "--") dispatch(getFormDetails({ name }));
    else dispatch(getFormDetails({ name, value: option.value }));
  };

  const handleDateChange = (date: any, name: string) => {
    dispatch(getFormDetails({ name, value: date }));
  };

  return (
    <CustomModal
      id={2}
      content={
        <Form customWidth="60%" style={{ flexWrap: "wrap" }}>
          <InputContainer>
            <Label>Merchant Name</Label>
            <Input type="text" value={selectedMerchantId?.name} />
          </InputContainer>
          <InputContainer>
            <Label>Transaction Type</Label>
            <SelectBar
              defaultValue={transactionType}
              onChange={(data: any) =>
                handleSelectChange(data, "transactionType")
              }
              options={TransactionTypes}
              styles={colourStyles}
            />
          </InputContainer>
          <InputContainer>
            <Label>Start Date</Label>
            <DatePick
              name="reportStartDate"
              dateFormat="dd/MM/yyyy"
              onChange={(date: Date) =>
                handleDateChange(date, "reportStartDate")
              }
              selected={reportStartDate}
            />
          </InputContainer>
          <InputContainer>
            <Label>End Date</Label>
            <DatePick
              name="reportEndDate"
              dateFormat="dd/MM/yyyy"
              onChange={(date: Date) => handleDateChange(date, "reportEndDate")}
              onSelect={(date: Date) => handleDateChange(date, "reportEndDate")}
              selected={reportEndDate}
            />
          </InputContainer>

          <Filledbutton
            text="Generate"
            handleClick={() =>
              generateReport(
                selectedMerchantId?.id,
                moment(reportStartDate).unix(),
                moment(reportEndDate).unix(),
                transactionType
              )
            }
            loading={generatingReport}
          />
        </Form>
      }
    />
  );
};

export default GenerateReportModal;
